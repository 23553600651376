import {useState} from "react";
import Button from "@mui/material/Button";
import {hasPermission} from "../../utils/permissions";
import OrderItemFormDialog from "./OrderItemFormDialog";

const CreateOrderItemButton = function ({sx = {}, products}) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button
                variant={'outlined'}
                sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
                onClick={() => setOpen(true)}
            >Ürün Ekle</Button>
            <OrderItemFormDialog
                title="Ürün oluştur"
                open={open}
                handleClose={() => setOpen(false)}
                products={products}
            />
        </>
    )
}

export default hasPermission(CreateOrderItemButton, {permissions: ['add_orderitem']});
