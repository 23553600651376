import {useState, useEffect, useCallback, useContext} from "react";
import BaseDataTable from "../datatable";
import {transformDateToLocalString, transformMinutesToTimeFormat} from "../../utils/transform";
import {ListPageContext} from "../list-page-hoc/context";
import {listPageActionTypes} from "../list-page-hoc/constants";
import OperationTimeTrackingApi from '../../api/operation-time-tracking';
import {userLabelFormat} from "../../helpers/user/format";
import TimeTrackingDataUpdateDialog from "./TimeTrackingDataUpdateDialog";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import CopyToClipboardButton from "../CopyToClipboardButton";
import {useNavigate} from "react-router-dom";
import {useListApi} from "../list-page-hoc/hooks";

function DataTable() {
    let navigate = useNavigate();
    const {state, dispatch} = useContext(ListPageContext);
    const {data: operationTimeTrackingList} = useListApi(OperationTimeTrackingApi);

    const handleSortModelChange = sortModel => {
        dispatch({type: listPageActionTypes.SET_SORT_MODEL, sortModel})
    };

    const handleFilterModelChange = useCallback(filterModel => {
        console.log(filterModel);
    }, []);

    const onPageChange = (page) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                page: page,
            }
        })
    }

    const onPageSizeChange = (pageSize) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                pageSize,
            }
        })
    }

    return (
        <BaseDataTable
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            sortModel={state.sortModel}
            loading={state.loading}
            rowCount={operationTimeTrackingList.count}
            page={state.pagination.page}
            pageSize={state.pagination.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={operationTimeTrackingList.results}
            columns={[
                {
                    field: 'workOrderId',
                    headerName: 'İş Emri No',
                    sortable: false,
                    filterable: false,
                    renderCell: ({row}) => {
                        const workOrder = row.workOrder;
                        return (
                            <Box>
                                <Link
                                    component={'button'}
                                    variant={'body2'}
                                    onClick={() => {
                                        navigate(`/is-emirleri/${workOrder.id}`, {replace: false})
                                    }}
                                >{workOrder.workOrderId}</Link>
                                <CopyToClipboardButton
                                    sx={{
                                        px: 1,
                                        opacity: 0.1,
                                        transition: 'opacity ease-out 300ms',
                                        '&:hover': {
                                            opacity: 1
                                        }
                                    }}
                                    copyText={workOrder.workOrderId}
                                    message={'İş emri numarası kopyalandı'}
                                />
                            </Box>
                        )
                    }
                },
                {
                    field: 'companyProductCode',
                    headerName: 'Kestaş Ürün Kodu',
                    sortable: false,
                    filterable: false,
                    valueFormatter: (params) => {
                        const {workOrder} = params.api.getRow(params.id)
                        return workOrder.companyProductCode;
                    },
                },
                {
                    field: 'operator',
                    filterable: false,
                    headerName: 'Operatör',
                    sortable: false,
                    width: 150,
                    valueFormatter: ({value: user}) => {
                        return userLabelFormat({user});
                    },
                },
                {
                    field: 'category',
                    filterable: false,
                    headerName: 'İşlem',
                    sortable: false,
                    width: 150,
                    valueFormatter: ({value}) => {
                        return `${value.name} (${value.shortCode})`;
                    },
                },
                {
                    field: 'equipment',
                    filterable: false,
                    headerName: 'Tezgah',
                    sortable: false,
                    width: 150,
                    valueFormatter: ({value}) => {
                        return value.code;
                    },
                },
                {
                    field: 'createdAt',
                    filterable: false,
                    headerName: 'Oluşturma Tarihi',
                    width: 135,
                    type: 'date',
                    valueFormatter: (params) => {
                        return transformDateToLocalString(params.value);
                    }
                },
                {
                    field: 'workingDate',
                    filterable: false,
                    headerName: 'Operasyon Tarihi',
                    width: 135,
                    type: 'date',
                    valueFormatter: (params) => {
                        return transformDateToLocalString(params.value);
                    }
                },
                {
                    field: 'totalMinutes',
                    filterable: false,
                    headerName: 'Toplam Süre',
                    sortable: false,
                    width: 120,
                    valueFormatter: (params) => {
                        return transformMinutesToTimeFormat(params.value);
                    }
                },
                {
                    field: 'actions',
                    type: 'actions',
                    pinnable: true,
                    getActions: (params) => [
                        <TimeTrackingDataUpdateDialog timeTrackingData={params.row}/>
                    ]
                }
            ]}
        />
    )
}

export default DataTable;
