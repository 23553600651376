import {productStatusFormat} from "../../helpers/customer/format";
import {ProductStatus} from "../../helpers/customer/enums";
import Chip from "@mui/material/Chip";

const ProductStatusChip = function ({status, sx = {}}) {
    const label = productStatusFormat(status);
    const isStatusNotFound = status === ProductStatus.NOT_FOUND;
    const isStatusCanceled = status === ProductStatus.CANCELED;
    const isStatusUpdated = status === ProductStatus.VERSION_UPDATED;
    return (
        <Chip
            sx={sx}
            label={label}
            color={
                isStatusNotFound
                    ? `statusCompleted`
                    : isStatusCanceled
                        ? 'error'
                        : isStatusUpdated
                            ? 'success'
                            : 'primary'
            }
        />
    )
}

export default ProductStatusChip;
