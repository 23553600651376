import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import TableHead from "@mui/material/TableHead";
import RemoveBroachingOilPriceButton from "./RemoveBroachingOilPriceButton";
import PriceTextFormat from "../PriceTextFormat";
import BroachingOilPriceCreateDialog from "./BroachingOilPriceCreateDialog";
import {hasPermission} from "../../utils/permissions";
import {transformDateToLocalString} from "../../utils/transform";
import {priceTypeTranslate, unitTranslate} from "../../helpers/customer/translate";

const BroachingOilPriceTable = function () {
    const {state} = useContext(DetailPageContext);
    const customer = state.data;

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Broşlama Yağı Fiyatları</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                            <BroachingOilPriceCreateDialog/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Yetkili İsim</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Fiyat Tipi</TableCell>
                        <TableCell>Yağ Miktarı</TableCell>
                        <TableCell>Birim Fiyat</TableCell>
                        <TableCell>Fiyat Anlaşma Tarihi</TableCell>
                        <TableCell>Geçerlilik Başlangıç Tarihi</TableCell>
                        <TableCell>Geçerlilik Bitiş Tarihi</TableCell>
                        <TableCell>Açıklama-1</TableCell>
                        <TableCell>Açıklama-2</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customer.broachingOilPrices.map(
                        ({
                             id,
                             authorizedPerson,
                             email,
                             priceType,
                             amount,
                             unit,
                             unitPrice,
                             currency,
                             confirmationDate,
                             validityPeriodStart,
                             validityPeriodEnd,
                             descriptionOne,
                             descriptionTwo
                         }) => {
                            return (
                                <TableRow key={id}>
                                    <TableCell>{authorizedPerson}</TableCell>
                                    <TableCell>{email}</TableCell>
                                    <TableCell>{priceTypeTranslate[priceType]}</TableCell>
                                    <TableCell>{amount}</TableCell>
                                    <TableCell>
                                        <PriceTextFormat
                                            prefix={`${currency}/${unitTranslate[unit]}`}
                                            value={unitPrice}
                                        />
                                    </TableCell>
                                    <TableCell>{
                                        confirmationDate
                                            ? transformDateToLocalString(confirmationDate)
                                            : ''
                                    }</TableCell>
                                    <TableCell>{
                                        validityPeriodStart
                                            ? transformDateToLocalString(validityPeriodStart)
                                            : ''
                                    }</TableCell>
                                    <TableCell>{
                                        validityPeriodEnd
                                            ? transformDateToLocalString(validityPeriodEnd)
                                            : ''
                                    }</TableCell>
                                    <TableCell>{descriptionOne}</TableCell>
                                    <TableCell>{descriptionTwo}</TableCell>
                                    <TableCell>
                                        <RemoveBroachingOilPriceButton
                                            customer={customer}
                                            contactId={id}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default hasPermission(BroachingOilPriceTable, {permissions: ['can_view_customer_broaching_oil_price']});
