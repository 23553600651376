import Chip from "@mui/material/Chip";
import {statusTranslate} from "../../helpers/work-order/status";

const StatusChip = function ({status, sx={}}) {
    const label = statusTranslate[status].chipLabel;
    const isStatusCompleted = status === statusTranslate.COMPLETED.enum;
    const isStatusDamaged = status === statusTranslate.DAMAGED.enum;
    const isStatusCanceled = status === statusTranslate.CANCELED.enum;
    return (
        <Chip
            sx={sx}
            label={label}
            color={
                isStatusCompleted
                    ? `statusCompleted`
                    : isStatusCanceled
                        ? 'warning'
                        : isStatusDamaged
                            ? 'error' : 'primary'
            }
        />
    )
}

export default StatusChip;
