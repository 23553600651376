export const analyzeContainerReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_VERSION':
            return {
                ...state,
                version: state.version + 1,
                loading: true,
            }
        case 'REGISTER':
            state.analysis[action.analyze] = action.loading
            return {
                ...state,
                loading: Object.values(state.analysis).every(loading => loading)
            }
        default:
            return state;
    }
}
