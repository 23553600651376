import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Formik} from "formik";
import * as yup from "yup";
import {useState} from "react";
import TextField from "../forms/TextField";
import SelectField from "../forms/SelectField";
import PriceFieldFormat from "../PriceFieldFormat";
import {currencies} from "../../utils/currency";

const BroachingPriceFormDialog = function (
    {
        initialValues = {
            authorizedPerson: '',
            partName: '',
            unitPrice: '',
            calibrationPrice: '',
            currency: 'TL',
            descriptionOne: '',
            descriptionTwo: '',
        },
        onSubmit = Promise.resolve(true),
        title,
        label,
        sx={}
    }
) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => !loading && setOpen(false);

    return (
        <>
            <Button
                variant={'outlined'}
                sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
                onClick={() => setOpen(true)}
            >{label}</Button>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    // actions.setSubmitting(false);
                    setLoading(true);
                    onSubmit(values).finally(() => {
                        setLoading(false);
                        setOpen(false);
                    });
                }}
            >
                {formik => (
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContent>
                            <Stack sx={{py: 1}} spacing={1}>
                                <TextField name={'authorizedPerson'} label={'Yetkili İsim'}/>
                                <TextField name={'partName'} label={'Parça Adı'}/>
                                <TextField
                                    name={'unitPrice'}
                                    label={'Birim Fiyat'}
                                    inputMode={'decimal'}
                                    InputProps={{
                                        inputComponent: PriceFieldFormat
                                    }}
                                    size={'small'}
                                />
                                <TextField
                                    name={'calibrationPrice'}
                                    label={'Ayar Ücreti'}
                                    inputMode={'decimal'}
                                    InputProps={{
                                        inputComponent: PriceFieldFormat
                                    }}
                                    size={'small'}
                                />
                                <SelectField
                                    name={'currency'}
                                    label={'Para Birimi'}
                                    values={currencies}
                                    nullable={false}
                                    valueField={'key'}
                                    size={'small'}
                                />
                                <TextField name={'descriptionOne'} multiline label={'Açıklama-1'}/>
                                <TextField name={'descriptionTwo'} multiline label={'Açıklama-2'}/>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                onClick={() => formik.submitForm()}
                            >Kaydet</Button>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                color={"error"}
                                onClick={handleClose}
                            >İptal</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        </>
    )
}

const validationSchema = yup.object({
    authorizedPerson: yup
        .string()
        .required('Gerekli'),
    partName: yup
        .string()
        .required('Gerekli'),
    unitPrice: yup
        .number()
        .moreThan(0, 'Değer sıfırdan büyük olmalı')
        .required('Gerekli'),
    calibrationPrice: yup
        .number()
        .moreThan(0, 'Değer sıfırdan büyük olmalı'),
    currency: yup
        .string()
        .oneOf(currencies.map(currency => currency.value)),
    descriptionOne: yup.string(),
    descriptionTwo: yup.string(),
});

export default BroachingPriceFormDialog;
