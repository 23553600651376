import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Formik} from "formik";
import * as yup from "yup";
import {useState} from "react";
import TextField from "../forms/TextField";
import SelectField from "../forms/SelectField";
import PriceFieldFormat from "../PriceFieldFormat";
import {currencies} from "../../utils/currency";

const BroochSharpeningPriceFormDialog = function (
    {
        initialValues = {
            broochType: 'LAMA',
            cuttingEdgeCount: null,
            averageDiameter: null,
            price: '',
            currency: 'TL',
            companyProductCode: '',
            customerProductCode: '',
            descriptionOne: '',
            descriptionTwo: '',
        },
        onSubmit = Promise.resolve(true),
        title,
        label,
        sx = {}
    }
) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => !loading && setOpen(false);

    return (
        <>
            <Button
                variant={'outlined'}
                sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
                onClick={() => setOpen(true)}
            >{label}</Button>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    // actions.setSubmitting(false);
                    setLoading(true);
                    onSubmit(values).finally(() => {
                        setLoading(false);
                        setOpen(false);
                    });
                }}
            >
                {formik => (
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContent>
                            <Stack sx={{py: 1}} spacing={1}>
                                <SelectField
                                    name={'broochType'}
                                    label={'Broş Tipi'}
                                    values={[
                                        {key: 'LAMA', value: 'LAMA'},
                                        {key: 'YUVARLAK', value: 'YUVARLAK'},
                                    ]}
                                    nullable={false}
                                    valueField={'key'}
                                    size={'small'}
                                />
                                <TextField name={'cuttingEdgeCount'} label={'KDS'} inputMode={'numeric'}/>
                                <TextField
                                    name={'averageDiameter'}
                                    label={'Ø / b'}
                                    inputMode={"decimal"}
                                    InputProps={{
                                        inputComponent: PriceFieldFormat
                                    }}
                                />
                                <Stack spacing={1} direction={"row"}>
                                    <TextField
                                        name={'price'}
                                        label={'Fiyat'}
                                        inputMode={'decimal'}
                                        InputProps={{
                                            inputComponent: PriceFieldFormat
                                        }}
                                        size={'small'}
                                    />
                                    <SelectField
                                        name={'currency'}
                                        label={'Para Birimi'}
                                        values={currencies}
                                        nullable={false}
                                        valueField={'key'}
                                        size={'small'}
                                    />
                                </Stack>
                                <TextField name={'companyProductCode'} multiline label={'Kestaş Ürün Kodu'}/>
                                <TextField name={'customerProductCode'} multiline label={'Müşteri Ürün Kodu'}/>
                                <TextField name={'descriptionOne'} multiline label={'Açıklama-1'}/>
                                <TextField name={'descriptionTwo'} multiline label={'Açıklama-2'}/>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                onClick={() => formik.submitForm()}
                            >Kaydet</Button>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                color={"error"}
                                onClick={handleClose}
                            >İptal</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        </>
    )
}

const validationSchema = yup.object({
    broochType: yup
        .string()
        .oneOf(['LAMA', 'YUVARLAK']),
    cuttingEdgeCount: yup
        .number()
        .nullable()
        .moreThan(0, 'Değer sıfırdan büyük olmalı'),
    averageDiameter: yup
        .number()
        .nullable()
        .moreThan(0, 'Değer sıfırdan büyük olmalı'),
    price: yup
        .number()
        .moreThan(0, 'Değer sıfırdan büyük olmalı'),
    currency: yup
        .string()
        .oneOf(currencies.map(currency => currency.value)),
    companyProductCode: yup.string(),
    customerProductCode: yup.string(),
    descriptionOne: yup.string(),
    descriptionTwo: yup.string(),
});

export default BroochSharpeningPriceFormDialog;
