import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Formik} from "formik";
import React, {useContext, useState} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import WorkOrderApi from '../../api/work-order';
import CheckboxField from "../forms/CheckboxField";
import EditIcon from "@mui/icons-material/Edit";
import {GridActionsCellItem} from "@mui/x-data-grid-pro";
import {hasPermission} from "../../utils/permissions";

const ProcessFormDialog = function (
    {
        row,
        updateRows,
        processFields,
    }
) {
    const authorization = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    let operations = row.operations.filter(operation => operation.category.qualityControlEnabled);
    if (operations.length === 0) {
        operations = processFields.reduce((operations, field) => {
            operations.push({
                id: field.name,
                isCompleted: false,
                category: {name: field.label}
            })
            return operations;
        }, [])
    }
    const handleClose = () => !loading && setOpen(false);

    return (
        <React.Fragment>
            <GridActionsCellItem
                icon={<EditIcon/>}
                onClick={() => setOpen(true)}
                label="Güncelle"
            />
            <Formik
                initialValues={operations.reduce((result, operation) => {
                    result[operation.id] = operation.isCompleted;
                    return result;
                }, {})}
                onSubmit={(values, actions) => {
                    // actions.setSubmitting(false);
                    setLoading(true);
                    WorkOrderApi.updateProcesses({authorization}, row.id, values).then(workOrder => {
                        setLoading(false);
                        row.operations = workOrder.operations;
                        updateRows([row]);
                        setOpen(false);
                    });
                }}
            >
                {formik => (
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
                        <DialogTitle>Proses Takip</DialogTitle>
                        <DialogContent>
                            <Stack sx={{py: 1}} spacing={1}>
                                {operations.map((operation) => {
                                    return (
                                        <CheckboxField
                                            key={operation.id}
                                            name={operation.id}
                                            size="small"
                                            label={operation.category.name}
                                        />
                                    )
                                })}
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                onClick={() => formik.submitForm()}
                            >Kaydet</Button>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                color={"error"}
                                onClick={handleClose}
                            >İptal</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        </React.Fragment>
    )
}

export default hasPermission(ProcessFormDialog, {permissions: ['can_update_processes']});
