import {useContext} from "react";
import {AnalyzeContext} from "./context";
import Count from "../Count";
import LineChart from "../LineChart";
import AnalysisApi from "../../../api/analysis";
import {analyzeActionTypes, analyzeChartTypes} from "./constants";
import {AuthContext} from "../../../contexts/AuthContext";
import {Skeleton} from "@mui/lab";

const analysis = [
    {
        chartType: analyzeChartTypes.COUNT,
        Chart: Count
    },
    {
        chartType: analyzeChartTypes.LINE_CHART,
        Chart: LineChart
    },
]

const Analyze = function () {
    const authorization = useContext(AuthContext);
    const {state, dispatch} = useContext(AnalyzeContext);

    if (!state.initiated) {
        AnalysisApi.getAnalyze({authorization}, state.slug).then(result => {
            dispatch({type: analyzeActionTypes.SET_DATA, ...result});
            dispatch({type: analyzeActionTypes.SET_LOADING, loading: false});
        });
    }
    if (state.loading && state.chartType === analyzeChartTypes.LINE_CHART) {
        return (
            <Skeleton variant="rectangular" width={'100%'} height={450}/>
        )
    }
    const {Chart} = analysis.find(({chartType}) => chartType === state.chartType)
    return (<Chart/>)
}

export default Analyze;
