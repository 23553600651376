import {useState, useEffect} from "react";
import NotificationsApi from '../../api/notifications';

import {
    isPushNotificationSupported,
    askUserPermission,
    registerServiceWorker,
    createNotificationSubscription,
    getUserSubscription
} from "./push-notifications";

const pushNotificationSupported = isPushNotificationSupported();

export default function usePushNotifications(authorization) {
    const [userConsent, setUserConsent] = useState(Notification.permission);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userSubscription, setUserSubscription] = useState(null);

    useEffect(() => {
        if (pushNotificationSupported) {
            setLoading(true);
            setError(false);
            registerServiceWorker().then(() => {
                setLoading(false);
            });
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        setError(false);
        const getExistingSubscription = async () => {
            const existingSubscription = await getUserSubscription();
            setUserSubscription(existingSubscription);
            setLoading(false);
        };
        getExistingSubscription();
    }, []);

    const subscribeToPushNotification = () => {
        setLoading(true);
        setError(false);
        askUserPermission().then(consent => {
            setUserConsent(consent);
            if (consent !== "granted") {
                setError({
                    name: "Consent denied",
                    message: "You denied the consent to receive notifications",
                    code: 0
                });
            } else {
                createNotificationSubscription()
                    .then(function (subscription) {
                        sendSubscriptionToPushServer(subscription);
                    })
                    .catch(err => {
                        console.error("Couldn't create the notification subscription", err, "name:", err.name, "message:", err.message, "code:", err.code);
                        setError(err);
                        setLoading(false);
                    });
            }
        });
    };

    const sendSubscriptionToPushServer = (subscription) => {
        NotificationsApi.savePushNotificationDevice({authorization}, {'subscription_info': subscription})
            .then(function (response) {
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setError(err);
            });
    };

    return {
        subscribeToPushNotification,
        userConsent,
        pushNotificationSupported,
        error,
        loading,
        userSubscription,
    };
}
