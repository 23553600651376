import React, {useContext, useState, useEffect} from "react";
import {AuthContext} from "../contexts/AuthContext";
import {useParams} from "react-router-dom";


export const hasPermission = (Component, {roles, permissions, checkFromServer, APIClient}) => {
     const WrapWithHasPermission = (props) => {
        const authorization = useContext(AuthContext);
        const {id} = useParams();
        const [hasPermission, setHasPermission] = useState(false);

        useEffect(() => {
            if (checkFromServer) {
                authorization.hasPermissionAsync({
                    roles,
                    permissions,
                    APIClient,
                    objectid: id,
                }).then(result => setHasPermission(result));
            } else {
                setHasPermission(authorization.hasPermission({
                    roles,
                    permissions,
                }));
            }
        }, [authorization, id])

        if (hasPermission) {
            return <Component {...props}/>
        }
        return <React.Fragment/>
    }
    return WrapWithHasPermission;
}
