import {useCallback, useContext} from "react";
import BaseDataTable from "../datatable";
import {ListPageContext} from "../list-page-hoc/context";
import {listPageActionTypes} from "../list-page-hoc/constants";
import BroachingPricesApi from '../../api/broaching-prices';
import PriceTextFormat from "../PriceTextFormat";
import {useListApi} from "../list-page-hoc/hooks";

function BroachingDataTable() {
    const {state, dispatch} = useContext(ListPageContext);
    const {data: operationTimeTrackingList} = useListApi(BroachingPricesApi);

    const handleSortModelChange = sortModel => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({type: listPageActionTypes.SET_SORT_MODEL, sortModel})
    };

    const handleFilterModelChange = useCallback(filterModel => {
        console.log(filterModel);
    }, []);

    const onPageChange = (page) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                page: page,
            }
        })
    }

    const onPageSizeChange = (pageSize) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                pageSize,
            }
        })
    }

    return (
        <BaseDataTable
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            sortModel={state.sortModel}
            loading={state.loading}
            rowCount={operationTimeTrackingList.count}
            page={state.pagination.page}
            pageSize={state.pagination.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={operationTimeTrackingList.results}
            columns={[
                {
                    field: 'customer',
                    filterable: false,
                    headerName: 'Müşteri',
                    sortable: true,
                },
                {
                    field: 'authorizedPerson',
                    filterable: false,
                    headerName: 'Yetkili İsmi',
                    sortable: false,
                },
                {
                    field: 'partName',
                    filterable: false,
                    headerName: 'Parça Adı',
                    sortable: false,
                },
                {
                    field: 'unitPrice',
                    filterable: false,
                    headerName: 'Birim Fiyat',
                    sortable: false,
                    renderCell: (params) => <PriceTextFormat
                        prefix={params.row.currency}
                        value={params.value}
                    />
                },
                {
                    field: 'calibrationPrice',
                    filterable: false,
                    headerName: 'Ayar Ücreti',
                    sortable: false,
                    renderCell: (params) => <PriceTextFormat
                        prefix={params.row.currency}
                        value={params.value}
                    />
                },
                {
                    field: 'descriptionOne',
                    filterable: false,
                    headerName: 'Açıklama-1',
                    sortable: false,
                    flex: 1,
                },
                {
                    field: 'descriptionTwo',
                    filterable: false,
                    headerName: 'Açıklama-2',
                    sortable: false,
                    flex: 1,
                },
                // {
                //     field: 'actions',
                //     type: 'actions',
                //     pinnable: true,
                //     getActions: (params) => [
                //         <TimeTrackingDataUpdateDialog timeTrackingData={params.row}/>
                //     ]
                // }
            ]}
        />
    )
}

export default BroachingDataTable;
