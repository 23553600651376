import {useContext, useEffect} from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import {formatDistance} from "date-fns";
import trLocale from 'date-fns/locale/tr'
import {AnalyzeContext} from "./context";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import {analyzeActionTypes, analyzeChartTypes} from "./constants";
import {CardHeader} from "@mui/material";
import AnalysisApi from "../../../api/analysis";
import {AuthContext} from "../../../contexts/AuthContext";
import {Skeleton} from "@mui/lab";

const AnalyzeLayout = function ({sx, children}) {
    const authorization = useContext(AuthContext);
    const {state, dispatch} = useContext(AnalyzeContext);

    useEffect(() => {
        if (state.version !== state.data?.version) {
            dispatch({type: analyzeActionTypes.SET_LOADING, loading: true});
            AnalysisApi.getAnalyze({authorization}, state.slug).then(result => {
                dispatch({type: analyzeActionTypes.SET_DATA, ...result});
                dispatch({type: analyzeActionTypes.SET_LOADING, loading: false});
            });
        }
    }, [authorization, dispatch, state.data?.version, state.slug, state.version]);

    const isDangerCard = state.chartType === analyzeChartTypes.COUNT &&
        state.options?.threshold < state.data?.data?.count;

    return (
        <Card sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            ...(isDangerCard ? {backgroundColor: '#ff4747', color: '#ffffff'} : {}),
            ...sx
        }}>
            <CardHeader
                title={
                    <Typography component="div" variant={"subtitle2"}>
                        {state.loading ? <Skeleton/> : state.options?.label}
                    </Typography>
                }
                sx={{py: 1, px: 2}}
            />
            <CardContent sx={{flex: 1, display: 'flex', alignItems: 'end', p: 0, px: 2}}>
                {children}
            </CardContent>
            <CardActions sx={{py: 1, px: 2}}>
                <IconButton
                    aria-label="refresh-button"
                    size={"small"}
                    onClick={() => dispatch({type: analyzeActionTypes.UPDATE_VERSION})}>
                    <RefreshRoundedIcon fontSize={"small"} sx={{...(isDangerCard ? {color: '#ffffff'} : {})}}/>
                </IconButton>
                <Typography variant={'caption'}>
                    {formatDistance(state.lastUpdate ? new Date(state.lastUpdate) : new Date(),
                        new Date(),
                        {
                            locale: trLocale
                        }
                    )}
                </Typography>
            </CardActions>
        </Card>
    )
}

export default AnalyzeLayout;
