import {useState, useContext} from 'react';
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {ActionStatusTypes} from "../../helpers/action/enums";
import {actionStatusFormat} from "../../helpers/action/format";
import {DetailPageContext} from "../detail-page-hoc/context";
import {AuthContext} from "../../contexts/AuthContext";
import LoadingButton from "@mui/lab/LoadingButton";
import ActionApi from '../../api/action';
import {detailPageActionTypes} from "../detail-page-hoc/constants";
import {hasPermission} from "../../utils/permissions";

const actionStatuses = Object.keys(ActionStatusTypes).map(value => ({
    label: actionStatusFormat(value),
    value
}));

const StatusChangeButton = function ({sx = {}}) {
    const {state, dispatch} = useContext(DetailPageContext);
    const authorization = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeStatus = (status) => {
        setLoading(true);
        setAnchorEl(null);
        ActionApi.update({authorization}, state.data.id, {
            assignee: state.data.assignee.id,
            category: state.data.category.id,
            status
        }).then(() => {
            setLoading(false);
            dispatch({type: detailPageActionTypes.UPDATE_VERSION})
        })
    }

    return (
        <Box sx={sx}>
            <LoadingButton
                loading={loading}
                variant={'contained'}
                sx={{textTransform: 'none'}}
                id="status-button"
                aria-controls={open ? 'status-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon/>}
            >
                Durumu Güncelle
            </LoadingButton>
            <Menu
                id="status-menu"
                aria-labelledby="status-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{mt: 1}}
            >
                {
                    actionStatuses
                        .filter(({value}) => value !== state.data.status)
                        .map(status => {
                            return (
                                <MenuItem
                                    key={status.value}
                                    onClick={() => {
                                        changeStatus(status.value)
                                    }}
                                >{status.label}</MenuItem>
                            )
                        })
                }
            </Menu>
        </Box>
    )
}

export default hasPermission(StatusChangeButton, {
    permissions: ['can_update_action_status'],
    checkFromServer: true,
    APIClient: ActionApi,
});
