import {departmentTypeTranslate} from "./translate";

/**
 * user label from user object
 * @param user {object}
 * @param type {'fullName' | 'title' | undefined} -
 * @returns {string}
 */
export const userLabelFormat = ({user, type}) => {
    if (!user) return '';
    const {firstName, lastName, employee} = user;
    const department = departmentTypeTranslate[employee.department];
    const title = employee.title;
    switch (type) {
        case "fullName":
            return `${firstName} ${lastName}`
        case "title":
            return `${department}/${title}`
        default:
            return `${firstName} ${lastName} - ${department}/${title}`
    }
}
