import ListPageHOC from "../components/list-page-hoc";
import ListActionButtons from "../components/actions/ListActionButtons";
import DataTable from "../components/actions/DataTable";

const Actions = function () {
    return (
        <ListPageHOC storageKey={'actions-page'} sortModel={[{field: "createdAt", sort: "desc"}]} filters={{status: []}}>
            <ListActionButtons/>
            <DataTable/>
        </ListPageHOC>
    )
}

export default Actions;
