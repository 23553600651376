import Api from "./index";
import {transformObjectKeysToCamelCase} from "../utils/transform";

class ActionApi extends Api {
    constructor() {
        super({baseUrl: 'actions'});
    }

    getCategories(context) {
        return this.errorMiddleware(
            context,
            this.client.get(`${this.baseUrl}/get_categories/`, {
                headers: {
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            })
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }
}

export default new ActionApi();
