import {useCallback, useContext} from "react";
import ListActionButtons from "../components/operation-time-tracking/ListActionButtons";
import DataTable from "../components/operation-time-tracking/DataTable";
import ListPageHOC from "../components/list-page-hoc";
import UserApi from "../api/user";
import EquipmentApi from "../api/equipment";
import OperationTimeTrackingApi from "../api/operation-time-tracking";
import {AuthContext} from "../contexts/AuthContext";
import {DepartmentType} from "../helpers/user/enums";

const OperationTimeTracking = function () {
    const authorization = useContext(AuthContext);
    const dataCallback = useCallback(() => {
        return Promise.all([
            UserApi.getAll({authorization}, [], {department: DepartmentType.PRODUCTION}),
            EquipmentApi.getAll({authorization}),
            OperationTimeTrackingApi.getCategories({authorization})
        ]).then(([users, equipments, categories]) => ({
            users, equipments, categories
        }))
    }, [authorization])

    return (
        <ListPageHOC
            storageKey={'operation-time-tracking-page'}
            sortModel={[{field: "createdAt", sort: "desc"}]}
            initialData={{
                users: [], equipments: [], categories: []
            }}
            dataCallback={dataCallback}
        >
            <ListActionButtons/>
            <DataTable/>
        </ListPageHOC>
    )
}

export default OperationTimeTracking;
