import Box from "@mui/material/Box";
import ListPageProvider from "../../list-page-hoc/context";
import GenericRevenueTable from "./GenericRevenueTable";
import GenericRevenueTableFilters from "./GenericRevenueTableFilters";
import {getCurrentMonthDateRange} from "./utils";
import TotalRevenue from "./TotalRevenue";
import TopRevenue from "./TopRevenue";

const GenericRevenue = function () {
    const dateRange = getCurrentMonthDateRange()
    return (
        <Box sx={{width: '100%'}}>
            <ListPageProvider
                storageKey="generic-revenue-table"
                sortModel={[]}
                filters={{
                    'date_after': dateRange.firstDay,
                    'date_before': dateRange.currentDay,
                }}
            >
                <GenericRevenueTableFilters/>
                <TotalRevenue/>
                <GenericRevenueTableFilters/>
                <GenericRevenueTable/>
                <TopRevenue/>
            </ListPageProvider>
        </Box>
    )
}

export default GenericRevenue;
