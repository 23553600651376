import {useContext, useState} from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {MessageContext} from "./context";

const MessageInput = function () {
    const [message, setMessage] = useState('');
    const {send} = useContext(MessageContext);

    const handleSend = () => {
        send(message).then(() => setMessage(''))
    }

    return (
        <Stack direction={"row"} spacing={1}>
            <TextField
                fullWidth
                size={"small"}
                value={message}
                onChange={event => setMessage(event.target.value)}
            />
            <Button
                disabled={message === ''}
                onClick={handleSend}
            >Gönder</Button>
        </Stack>
    )
}

export default MessageInput;
