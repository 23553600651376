import {forwardRef} from "react";
import NumberFormat from "react-number-format";

const PriceFieldFormat = forwardRef(function (
    props,
    ref
) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator={"."}
            isNumericString
            decimalSeparator={","}
        />
    );
});

export default PriceFieldFormat;
