import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import {userLabelFormat} from "../../helpers/user/format";
import TextField from "../forms/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Formik} from "formik";
import * as yup from "yup";
import {useContext, useEffect, useState} from "react";
import SelectField from "../forms/SelectField";
import DatePicker from "../forms/DatePicker";
import format from "date-fns/format";
import EditIcon from "@mui/icons-material/Edit";
import {GridActionsCellItem} from "@mui/x-data-grid-pro";
import TimeFieldFormat from "../TimeFieldFormat";
import {transformTimeFormatToMinutes} from "../../utils/transform";
import {ListPageContext} from "../list-page-hoc/context";

const TimeTrackingFormDialog = function (
    {
        dataTableAction = false,
        initialValues = {
            totalMinutes: '',
            workingDate: new Date(),
            operator: '',
            category: '',
            workOrder: '',
            equipment: '',
        },
        onSubmit = Promise.resolve(true),
        title,
        label,
        sx = {}
    }
) {
    const {state} = useContext(ListPageContext);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => !loading && setOpen(false);

    return (
        <>
            {
                dataTableAction
                    ? (
                        <GridActionsCellItem
                            icon={<EditIcon/>}
                            onClick={() => setOpen(true)}
                            label={label}
                        />
                    )
                    : (
                        <Button
                            variant={'outlined'}
                            sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
                            onClick={() => setOpen(true)}
                        >{label}</Button>
                    )
            }
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    // actions.setSubmitting(false);
                    setLoading(true);
                    if (typeof (values.workingDate) === 'object') {
                        values.workingDate = format(values.workingDate, 'yyyy-MM-dd');
                    }
                    const {error, minutes} = transformTimeFormatToMinutes(values.totalMinutes);
                    if (error) {
                        actions.setErrors({totalMinutes: error});
                        setLoading(false);
                        return;
                    }
                    const payload = {...values};
                    payload.totalMinutes = minutes;
                    Object.entries(payload).forEach(([key, value]) => {
                        if (!value) delete payload[key]
                    });
                    onSubmit(payload).then(({status, errors}) => {
                        setLoading(false);
                        if (!status && errors) {
                            if (errors.workOrder) {
                                errors.workOrder = ['İş emri bulunamadı']
                            }
                            actions.setErrors(errors);
                        }
                        setOpen(!status);
                    });
                }}
            >
                {formik => (
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContent>
                            <Stack sx={{py: 1}} spacing={1}>
                                <TextField name="workOrder" label="İş Emri No"/>
                                <SelectField
                                    label={"Operatör"}
                                    values={state.data.users}
                                    itemLabelFormat={user => userLabelFormat({user})}
                                    name="operator"
                                />
                                <SelectField
                                    label={"Kategori"}
                                    values={state.data.categories}
                                    itemLabelFormat={category => `${category.shortCode} - ${category.name}`}
                                    name="category"
                                />
                                <SelectField
                                    label={"Tezgah"}
                                    values={state.data.equipments}
                                    itemLabelField={'code'}
                                    name="equipment"
                                />
                                <DatePicker
                                    label={"Operasyon Tarihi"}
                                    name={'workingDate'}
                                />
                                <TextField
                                    name="totalMinutes"
                                    label="Toplam Süre"
                                    size={'small'}
                                    InputProps={{
                                        inputComponent: TimeFieldFormat
                                    }}
                                />
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                onClick={() => formik.submitForm()}
                            >Kaydet</Button>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                color={"error"}
                                onClick={handleClose}
                            >İptal</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        </>
    )
}

const validationSchema = yup.object({
    operator: yup.string().required('Lütfen bir sorumlu seçiniz'),
    equipment: yup.string().required('Lütfen bir tezgah seçiniz'),
    category: yup.string().required('Lütfen bir kategori seçiniz'),
    workOrder: yup.string().required('Lütfen bir iş emri numarası giriniz'),
    totalMinutes: yup.string()
        .required('İşlem süresi giriniz'),
    workingDate: yup.date()
});

export default TimeTrackingFormDialog;
