import {statusTranslate} from "../../helpers/work-order/status";
import {hasPermission} from "../../utils/permissions";
import WorkOrderApi from "../../api/work-order";
import StatusUpdateButton from "./StatusUpdateButton";


const ProductionCompleteButton = function ({workOrder, setWorkOrder}) {
    return (
        <StatusUpdateButton
            workOrder={workOrder}
            setWorkOrder={setWorkOrder}
            api={WorkOrderApi}
            process={'internal-order'}
            label={statusTranslate.ACTIVE.statusUpdateButtonLabel}
        />
    )
}

export default hasPermission(ProductionCompleteButton, {permissions: ['can_complete_work_order']});
