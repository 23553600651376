export const analyzeReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_VERSION':
            return {
                ...state,
                version: state.version + 1,
                loading: true,
            }
        case 'SET_DATA':
            return {
                ...state,
                data: {
                    version: state.version,
                    data: action.data,
                },
                chartType: action.chartType,
                options: action.options,
                initiated: true,
                lastUpdate: (new Date()).toISOString(),
            }
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.loading
            }
        default:
            return state;
    }
}
