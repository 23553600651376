import {createContext, useReducer, useEffect, useContext} from 'react';
import {analyzeReducer} from "./reducer";
import {AnalyzeContainerContext} from "../analyze-container-hoc/context";
import {analyzeActionTypes} from "./constants";

const AnalyzeContext = createContext(null);

function AnalyzeProvider(
    {
        children,
        storageKey = 'analyze-xxx',
        slug,
        chartType,
    }
) {
    const [state, dispatch] = useReducer(analyzeReducer, {
        version: 1,
        loading: true,
        lastUpdate: new Date(),
        initiated: false,
        chartType,
        slug,
    }, params => {
        const storedState = JSON.parse(localStorage.getItem(storageKey) || '{}');
        if (storedState.data && !storedState.data.version) {
            storedState.data = {
                data: storedState.data,
                version: storedState.version || params.version,
            };
        }
        if (storedState.slug !== params.slug) return params;
        return {...params, ...storedState};
    })

    const {state: containerState, dispatch: containerDispatch} = useContext(AnalyzeContainerContext);
    useEffect(() => {
        containerDispatch({type: 'REGISTER', analyze: state.storageKey, loading: state.loading});
    }, [containerDispatch, state.loading, state.storageKey])

    useEffect(() => {
        if (containerState.version > 1) {
            dispatch({type: analyzeActionTypes.UPDATE_VERSION});
        }
    }, [containerState.version])

    useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify({
            data: state.data,
            chartType: state.chartType,
            options: state.options,
            lastUpdate: state.lastUpdate,
            slug: state.slug,
            initiated: state.initiated,
            loading: state.loading,
            version: state.version,
        }));
    }, [state, storageKey])

    return <AnalyzeContext.Provider value={{state, dispatch}}>{children}</AnalyzeContext.Provider>;
}

export {AnalyzeContext};
export default AnalyzeProvider;
