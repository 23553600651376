import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import {hasPermission} from "../../utils/permissions";
import Contacts from "./Contacts";
import Discount from "./Discount";
import FinancialNotes from "./FinancialNotes";

const CustomerTable = function ({customer, product, isFinancialNotesVisible = true}) {
    const discountForProduct = customer.discounts?.find(discount => {
        return discount.product.id === product?.id
    })
    return (
        <Paper sx={{padding: 2}}>
            <Typography variant={"subtitle1"}>
                Müşteri
            </Typography>
            <Divider/>
            <Typography
                variant={"subtitle2"}
                sx={{marginTop: 2}}
            >{customer.name}
            </Typography>
            <Typography
                variant={"body2"}
                sx={{marginTop: 2}}
            >{customer.phone}
            </Typography>
            <Typography
                variant={"body2"}
                sx={{marginTop: 2}}
            >{customer.address}
            </Typography>
            <Contacts contacts={customer.contacts}/>
            {product && <Discount discountForProduct={discountForProduct}/>}
            {isFinancialNotesVisible && <FinancialNotes financialNotes={customer.financialNotes}/>}
        </Paper>
    )
}

export default hasPermission(CustomerTable, {permissions: ['can_view_customer_card_info']});
