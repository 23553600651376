import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {transformDateToLocalString} from "../../utils/transform";
import {userLabelFormat} from "../../helpers/user/format";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";

const DetailTable = function () {
    const {state} = useContext(DetailPageContext);
    const action = state.data;

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Oluşturan</TableCell>
                        <TableCell>{userLabelFormat({user: action.createdBy})}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Sorumlu</TableCell>
                        <TableCell>{userLabelFormat({user: action.assignee})}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Kategori</TableCell>
                        <TableCell>{action.category.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Oluşturma Tarihi</TableCell>
                        <TableCell>
                            {transformDateToLocalString(action.createdAt)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Termin Tarihi</TableCell>
                        <TableCell>
                            {transformDateToLocalString(action.deadline)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Açıklama</TableCell>
                        <TableCell>{action.description}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DetailTable;
