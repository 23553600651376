import Box from "@mui/material/Box";
import ListSearch from "../ListSearch";
import ListClearFiltersButton from "../ListClearFiltersButton";

const ListActionButtons = function () {
    return (
        <Box sx={{width: '100%', display: 'flex'}}>
            <Box sx={{flexGrow: 1}}/>
            <Box sx={{display: 'flex'}}>
                <ListClearFiltersButton />
                <ListSearch />
            </Box>
        </Box>
    )
}

export default ListActionButtons;
