import {useState, useMemo} from "react";
import {useField} from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

const AutocompleteField = function (
    {
        label,
        fullWidth = true,
        nullable = true,
        selected,
        valueField = 'id',
        itemLabelField,
        itemLabelFormat,
        values = [],
        size = 'medium',
        onChange,
        ...props
    }
) {
    const [field, meta, helpers] = useField(props);
    const [inputValue, setInputValue] = useState('');

    const options = useMemo(() => {
        return values.map(value => {
            const label = itemLabelField
                ? value[itemLabelField]
                : itemLabelFormat
                    ? itemLabelFormat(value)
                    : value[valueField]
            return {label, id: value[valueField]}
        });
    }, [itemLabelField, itemLabelFormat, valueField, values]);

    return (
        <FormControl
            fullWidth={fullWidth}
            error={Boolean(meta.error) && meta.touched}
            size={size}
        >
            <Autocomplete
                size={size}
                onChange={(_, newValue) => {
                    if (onChange) onChange(...arguments);
                    helpers.setValue(newValue?.id);
                }}
                clearOnBlur={false}
                clearOnEscape={false}
                defaultChecked
                defaultValue=""
                value={field.value}
                inputValue={inputValue}
                onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
                isOptionEqualToValue={(option, value) => {
                    return option.id === value
                }}
                getOptionLabel={(option, test) => {
                    if (typeof option === 'object') return option.label
                    return options.find(item => item.id === option)?.label || ''
                }}
                options={[
                    {label: 'Seçiniz', id: ''},
                    ...options,
                ]}
                renderInput={(params) => <TextField {...params} label={label}/>}
            />
            {Boolean(meta.error) && meta.touched && (
                <FormHelperText>{meta.error}</FormHelperText>
            )}
        </FormControl>
    )
}

export default AutocompleteField;
