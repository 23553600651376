import Api from "./index";
import {
    transformFiltersToQueryString,
    transformObjectKeysToCamelCase,
} from "../utils/transform";

class GenericRevenueApi extends Api {
    constructor() {
        super({baseUrl: 'generic-revenues'});
    }

    async sum(context, filters = {}) {
        let url = `${this.baseUrl}/sum/?${transformFiltersToQueryString(filters)}`
        return this.errorMiddleware(
            context,
            this.client.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            })
        ).then(response => {
            return response.data.map(item => transformObjectKeysToCamelCase(item))
        })
    }
}

export default new GenericRevenueApi();
