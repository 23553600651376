import React, {useContext} from "react";
import Typography from "@mui/material/Typography";
import {AnalyzeContext} from "./analyze-hoc/context";
import {Skeleton} from "@mui/lab";
import {analyzeChartTypes} from "./analyze-hoc/constants";

const Count = function () {
    const {state} = useContext(AnalyzeContext);
    const hasThreshold = state.chartType === analyzeChartTypes.COUNT &&
        state.options?.threshold;
    return (
        <React.Fragment>
            <Typography component="div" variant={"h2"}>
                {state.loading ? <Skeleton/> : state.data.data.count}
            </Typography>
            {hasThreshold && (
                <Typography component="span" variant={"body1"} sx={{pb: 1, ml: 'auto'}}>
                    {`Hedef: Max ${state.options?.threshold}`}
                </Typography>
            )}
        </React.Fragment>
    )
}

export default Count;
