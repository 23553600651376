import Api from "./index";
import {transformObjectKeysToCamelCase} from "../utils/transform";

class NotificationsApi extends Api {
    constructor() {
        super({baseUrl: 'notifications'});
    }

    updateRead(context, id) {
        return this.errorMiddleware(
            context,
            this.client.put(`${this.baseUrl}/${id}/update_read/`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            })
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    updateReads(context, pageSize) {
        return this.errorMiddleware(
            context,
            this.client.put(`${this.baseUrl}/update_reads/?page=1&page_size=${pageSize}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            })
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    savePushNotificationDevice(context, data) {
        return this.errorMiddleware(
            context,
            this.client.post(`${this.baseUrl}/save_push_notification_device/`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            })
        ).then(response => response.data)
    }
}

export default new NotificationsApi();
