export const statusTranslate = {
    ACTIVE: {
        chipLabel: 'Aktif',
        statusUpdateButtonLabel: 'Üretimi Tamamla',
        status: 'İş emri açıldı',
        enum: 'ACTIVE'
    },
    CREATED_INVOICE_ORDER: {
        status: 'Fatura emri oluşturuldu',
        enum: 'CREATED_INVOICE_ORDER'
    },
    WAITING_FOR_INVOICE: {
        chipLabel: 'Muhasebe',
        statusUpdateButtonLabel: 'Fatura Kesildi',
        status: 'Üretim tamamlandı',
        enum: 'WAITING_FOR_INVOICE'
    },
    WAITING_GENERAL_DIRECTOR_APPROVE: {
        chipLabel: 'Genel Müdür',
        statusUpdateButtonLabel: 'İşlemleri Onayla',
        status: 'Fatura kesildi',
        enum: 'WAITING_GENERAL_DIRECTOR_APPROVE'
    },
    COMPLETED: {
        chipLabel: 'Tamamlandı',
        status: 'Genel Müdür onayı',
        enum: 'COMPLETED'
    },
    CANCELED: {
        chipLabel: 'İptal Edildi',
        statusUpdateButtonLabel: 'İptal Et',
        status: 'İptal edildi',
        enum: 'CANCELED',
    },
    DAMAGED: {
        chipLabel: 'Bozuldu',
        statusUpdateButtonLabel: 'Bozuldu Olarak İşaretle',
        status: 'Bozuk olarak işaretlendi',
        enum: 'DAMAGED',
    },
    REOPEN: {
        statusUpdateButtonLabel: 'Geri Aç',
        enum: 'REOPEN'
    },
}
