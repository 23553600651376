import format from "date-fns/format";
import {startOfMonth, startOfYear} from "date-fns/fp";

export const getCurrentMonthDateRange = () => ({
    firstDay: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    currentDay: format(new Date(), 'yyyy-MM-dd'),
})

export const getCurrentYearDateRange = () => ({
    firstDay: format(startOfYear(new Date()), 'yyyy-MM-dd'),
    currentDay: format(new Date(), 'yyyy-MM-dd'),
})