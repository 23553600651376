import {useContext, useState, useEffect} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import Button from "@mui/material/Button";
import WorkOrderFormDialog from "./WorkOrderFormDialog";
import {AuthContext} from "../../contexts/AuthContext";
import ProductApi from "../../api/product";
import CustomerApi from "../../api/customer";
import {transformMinutesToTimeFormat} from "../../utils/transform";
import {hasPermission} from "../../utils/permissions";
import differenceInWeeks from 'date-fns/differenceInWeeks';

const RenewWorkOrderButton = function ({sx = {}}) {
    const authorization = useContext(AuthContext);
    const {state} = useContext(DetailPageContext);
    const [open, setOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        Promise.all([
            ProductApi.getAll({authorization}),
            CustomerApi.dropdown({authorization}),
        ]).then(
            ([products, customers]) => {
                setProducts(products);
                setCustomers(customers);
            }
        )
    }, [authorization]);

    const operations = products.find(product => product.id === state.data.product.id)?.operations || [];
    const operationsInitialValues = operations
        .filter(operation => operation.timeTrackingEnabled)
        .reduce((result, operation) => {
        const savedOperation = state.data.operations.find(
            ({category}) => category.id === operation.id
        );
        return {
            ...result,
            [operation.shortCode]: transformMinutesToTimeFormat(savedOperation?.estimatedOperationTime || ''),
        }
    }, {});
    return (
        <>
            <Button
                variant={'outlined'}
                sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
                onClick={() => setOpen(true)}
            >Siparişi Tekrarla</Button>
            <WorkOrderFormDialog
                open={open}
                handleClose={() => setOpen(false)}
                products={products}
                customers={customers}
                initialValues={{
                    product: state.data.product.id,
                    customer: state.data.customer.id,
                    companyProductCode: state.data.companyProductCode || '',
                    companyOrderId: state.data.companyOrderId || '',
                    quantity: '',
                    createAsMultiple: false,
                    offerDate: state.data.offerDate || '',
                    confirmationDate: state.data.confirmationDate || '',
                    deadline: state.data.deadline ? differenceInWeeks(new Date(state.data.deadline), new Date()) : '',
                    customerProductCode: state.data.customerProductCode || '',
                    customerOrderId: state.data.customerOrderId || '',
                    description: state.data.description || '',
                    notes: state.data.notes || '',
                    unitPrice: state.data.unitPrice || '',
                    currency: state.data.currency,
                    dispatchType: state.data.dispatchType,
                    ...operationsInitialValues,
                }}
            />
        </>
    )
}

export default hasPermission(RenewWorkOrderButton, {permissions: ['add_workorder']});
