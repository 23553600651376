import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {transformDateToLocalString} from "../../utils/transform";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import PriceTextFormat from "../PriceTextFormat";
import Link from "@mui/material/Link";
import {useNavigate} from "react-router-dom";

const DetailTable = function ({tableSize = 'medium', showFinancialPart = true}) {
    const {state} = useContext(DetailPageContext);
    const navigate = useNavigate();

    return (
        <TableContainer component={Paper}>
            <Table size={tableSize}>
                <TableBody>
                    <TableRow>
                        <TableCell>İş Emri No</TableCell>
                        <TableCell>
                            <Link
                                component={'button'}
                                variant={'body2'}
                                onClick={() => {
                                    navigate(`/is-emirleri/${state.data.workOrder.id}`, {replace: false})
                                }}
                            >{state.data.workOrder.workOrderId}</Link>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Ürün/İşlem</TableCell>
                        <TableCell>{state.data.workOrder.product.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Kestaş Ürün Kodu</TableCell>
                        <TableCell>{state.data.workOrder.companyProductCode || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Kestaş Sipariş No</TableCell>
                        <TableCell>{state.data.workOrder.companyOrderId || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Müşteri Ürün Kodu</TableCell>
                        <TableCell>{state.data.workOrder.customerProductCode || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Müşteri Sipariş No</TableCell>
                        <TableCell>{state.data.workOrder.customerOrderId || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Ürün Ebat/Cins Açıklaması</TableCell>
                        <TableCell>{state.data.workOrder.description || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Adet veya Kg</TableCell>
                        <TableCell>{state.data.quantity || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Fatura Tarihi</TableCell>
                        <TableCell>
                            {state.data.companyInvoiceDate ? transformDateToLocalString(state.data.companyInvoiceDate) : '-'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Birim Fiyat</TableCell>
                        <TableCell>
                            <PriceTextFormat
                                prefix={state.data.currency}
                                value={state.data.unitPrice || '-'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Kur</TableCell>
                        <TableCell>
                            <PriceTextFormat
                                value={state.data.rate || '-'}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Sevkiyat Şekli</TableCell>
                        <TableCell>{state.data.dispatchType || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Etiket Sayısı</TableCell>
                        <TableCell>{state.data.tagCount || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Notlar</TableCell>
                        <TableCell>{state.data.notes || '-'}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DetailTable;
