import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import {useLocation, useNavigate} from 'react-router-dom';
import {useContext} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import {pages} from "../../pages";

function MenuList({drawer}) {
    let navigate = useNavigate();
    let location = useLocation();
    const authorization = useContext(AuthContext);

    return (
        <List>
            {pages.filter(page => !page.isDetailPage && authorization.hasPermission(page)).map(({pathname, label, icon: Icon}) => (
                <ListItem
                    button
                    key={label}
                    selected={location.pathname === pathname}
                    onClick={() => navigate(pathname, {replace: false})}
                >
                    {drawer === 'temporary'
                        ? (
                            <>
                                <ListItemIcon>
                                    <Icon/>
                                </ListItemIcon>
                                <ListItemText primary={label}/>
                            </>
                        )
                        : (
                            <Tooltip
                                title={label}
                                leaveDelay={50}
                                arrow
                                placement={"right"}
                            >
                                <ListItemIcon sx={{minWidth: 36}}>
                                    <Icon/>
                                </ListItemIcon>
                            </Tooltip>
                        )
                    }
                </ListItem>
            ))}
        </List>
    );
}

export default MenuList;
