import Api from "./index";
import {transformObjectKeysToCamelCase} from "../utils/transform";

class AnalysisApi extends Api {
    constructor() {
        super({baseUrl: 'analysis'});
    }

    getAnalyze(context, slug) {
        return this.errorMiddleware(
            context,
            this.client.get(`${this.baseUrl}/${slug}/`, {
                headers: {
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            })
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }
}

export default new AnalysisApi();
