import {hasPermission} from "../../utils/permissions";
import StatusUpdateButton from "./StatusUpdateButton";
import WorkOrderApi from "../../api/work-order";
import {statusTranslate} from "../../helpers/work-order/status";

const MarkAsDamagedButton = function ({workOrder, setWorkOrder}) {
    return (
        <StatusUpdateButton
            workOrder={workOrder}
            setWorkOrder={setWorkOrder}
            api={WorkOrderApi}
            process={'damaged'}
            label={statusTranslate.DAMAGED.statusUpdateButtonLabel}
            color={'error'}
        />
    )
}

export default hasPermission(MarkAsDamagedButton, {permissions: ['can_mark_work_order_as_damaged']});
