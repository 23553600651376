import {useContext} from "react";
import BaseDataTable from "../datatable/ClientDataTable";
import {transformDateToLocalString} from "../../utils/transform";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import {DetailPageContext} from "../detail-page-hoc/context";
import CopyToClipboardButton from "../CopyToClipboardButton";
import StatusChip from "../invoice-order/StatusChip";
import PriceTextFormat from "../PriceTextFormat";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

const PAGE_SIZE = 10

function InvoiceOrderTable() {
    const {state} = useContext(DetailPageContext);
    let navigate = useNavigate();
    const invoices = state.data.invoices;

    return (
        <Stack spacing={1}>
            <Typography variant="h6">Faturalar</Typography>
            <Divider/>
            <BaseDataTable
                sx={{minHeight: 200}}
                rowCount={invoices.length}
                rows={invoices}
                columns={[
                    {
                        field: 'companyInvoiceNo',
                        headerName: 'Kestaş Fatura No',
                        sortable: false,
                        filterable: false,
                        flex: 1,
                        renderCell: (params) => {
                            return (
                                <Box>
                                    <Link
                                        component={'button'}
                                        variant={'body2'}
                                        onClick={() => {
                                            navigate(`/fatura-emirleri/${params.id}`, {replace: false})
                                        }}
                                    >{params.value || 'Bekleniyor'}</Link>
                                    <CopyToClipboardButton
                                        sx={{
                                            px: 1,
                                            opacity: 0.1,
                                            transition: 'opacity ease-out 300ms',
                                            '&:hover': {
                                                opacity: 1
                                            }
                                        }}
                                        copyText={params.value}
                                        message={'Fatura numarası kopyalandı'}
                                    />
                                </Box>
                            )
                        }
                    },
                    {
                        field: 'companyInvoiceDate',
                        filterable: false,
                        headerName: 'Fatura Tarihi',
                        width: 135,
                        type: 'date',
                        valueFormatter: (params) => {
                            return transformDateToLocalString(params.value);
                        }
                    },
                    {
                        field: 'quantity',
                        headerName: 'Adet veya Kg',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'unitPrice',
                        filterable: false,
                        headerName: 'Birim Fiyat',
                        sortable: false,
                        renderCell: (params) => <PriceTextFormat
                            prefix={params.row.currency}
                            value={params.value}
                        />
                    },
                    {
                        field: 'status',
                        headerName: 'Durum',
                        filterable: false,
                        sortable: false,
                        width: 120,
                        renderCell: (params) => <StatusChip status={params.value}/>
                    },
                ]}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: PAGE_SIZE,
                        },
                    }
                }}
                pageSizeOptions={[PAGE_SIZE]}
            />
        </Stack>
    )
}

export default InvoiceOrderTable;
