import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useContext, useEffect, useState} from "react";
import TCMBExchangeRateApi from "../api/tcmb-exchange-rate"
import {AuthContext} from "../contexts/AuthContext";

const RateList = function () {
    const authorization = useContext(AuthContext);
    const [rates, setRates] = useState([])
    useEffect(() => {
        let abort
        TCMBExchangeRateApi.getAll({authorization})
            .then((rates) => {
                if (!abort) {
                    setRates(rates)
                }
            })
        return () => {
            abort = true
        }
    }, [authorization]);
    return (
        <Box sx={{textAlign: 'left', display: 'flex', flexDirection: 'column', lineHeight: 1.4}}>
            {rates.map(({currency, buying, selling}) => (
                <Typography
                    key={currency}
                    variant="caption"
                    lineHeight="inherit"
                >{currency} - A:{buying} S:{selling}</Typography>
            ))}
        </Box>
    )
}

export default RateList