import Box from "@mui/material/Box";
import RefreshSortOrderButton from "./RefreshSortOrderButton";
import Pagination from "./Pagination";

const Footer = function () {
    return (
        <Box sx={{display: 'flex'}}>
            <RefreshSortOrderButton />
            <Pagination/>
        </Box>
    )
}

export default Footer;
