import {useState, useEffect} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useContext} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import {ListPageContext} from "../list-page-hoc/context";
import {listPageActionTypes} from "../list-page-hoc/constants";
import CustomerApi from "../../api/customer";
import CreateOrderButton from "./CreateOrderButton";
import ListSearch from "../ListSearch";
import ListAutocompleteFilter from "../ListAutocompleteFilter";
import StatusFilter from "./StatusFilter";

const ListActionButtons = function () {
    const authorization = useContext(AuthContext);
    const {dispatch} = useContext(ListPageContext);
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        CustomerApi.dropdown({authorization}).then((customers) => {
            setCustomers(customers);
        })
    }, [authorization]);

    return (
        <Box sx={{width: '100%', display: 'flex'}}>
            <Box sx={{flexGrow: 1}}/>
            <Box sx={{display: 'flex'}}>
                <Button
                    sx={{textTransform: 'none'}}
                    onClick={() => {
                        dispatch({type: listPageActionTypes.SET_FILTERS, filters: {status: []}});
                        dispatch({type: listPageActionTypes.SET_SEARCH_TEXT, searchText: ''});
                        dispatch({type: listPageActionTypes.SET_INPUT_VALUE, inputValues: {}});
                    }}
                >
                    Temizle
                </Button>
                <ListSearch/>
                <ListAutocompleteFilter
                    label="Müşteri"
                    filterField="customer"
                    inputValueField="customerInput"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={customers.map(customer => ({label: customer.name, id: customer.id}))}
                />
                <StatusFilter sx={{ml: 1, width: 200}}/>
                <CreateOrderButton
                    customers={customers}
                    sx={{ml: 1}}
                />
            </Box>
        </Box>
    )
}

export default ListActionButtons;
