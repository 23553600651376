import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {hasPermission} from "../../utils/permissions";

const FinancialNotes = function ({financialNotes}) {
    return (
        <>
            <Typography
                variant={"subtitle2"}
                sx={{marginTop: 2}}
            >Finansal Notlar
            </Typography>
            <Divider/>
            <Typography
                variant={financialNotes ? 'subtitle2' : 'body2'}
                sx={{marginTop: 2, color: financialNotes ?  'red' : undefined}}
            >
                {financialNotes
                    ? financialNotes
                    : 'Müşterinin bu ürün/hizmet için tanımlı bir finansal notu bulunmamaktadır.'
                }
            </Typography>
        </>
    )
}

export default hasPermission(FinancialNotes, {permissions: ['can_view_financial_notes']})
