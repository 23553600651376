import {useField} from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const CheckboxField = function (
    {
        label,
        fullWidth = true,
        nullable = true,
        selected,
        valueField = 'id',
        itemLabelField,
        itemLabelFormat,
        values = [],
        size = 'medium',
        disabled = false,
        ...props
    }
) {
    const [field, meta, helpers] = useField(props);
    return (
        <FormControl
            fullWidth={fullWidth}
            error={Boolean(meta.error) && meta.touched}
            size={size}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        checked={field.value}
                        name={field.name}
                        onChange={event => helpers.setValue(event.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        size={size}
                        disabled={disabled}
                    />
                }
                label={label}
            />
            {Boolean(meta.error) && meta.touched && (
                <FormHelperText>{meta.error}</FormHelperText>
            )}
        </FormControl>
    )
}

export default CheckboxField;
