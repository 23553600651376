import WorkOrderApi from "../../api/work-order";
import {statusTranslate} from "../../helpers/work-order/status";
import StatusUpdateButton from "./StatusUpdateButton";
import {hasPermission} from "../../utils/permissions";

const ApproveButton = function ({workOrder, setWorkOrder}) {
    return (
        <StatusUpdateButton
            workOrder={workOrder}
            setWorkOrder={setWorkOrder}
            api={WorkOrderApi}
            process={'approve'}
            label={statusTranslate.WAITING_GENERAL_DIRECTOR_APPROVE.statusUpdateButtonLabel}
        />
    )
}

export default hasPermission(ApproveButton, {permissions: ['can_approve_work_order']})
