import {useContext} from "react";
import {
    Routes as BrowserRoutes,
    Route,
    Navigate,
    useLocation,
} from "react-router-dom";
import {
    SignIn,
    Unauthorized,
    RefreshAuth,
    pages
} from './pages';
import {AuthContext} from "./contexts/AuthContext";
import {LicenseInfo} from '@mui/x-license-pro';

function Routes() {
    return (
        <BrowserRoutes>
            <Route path="/giris-yap" element={<SignIn/>}/>
            <Route path="/oturumu-guncelle" element={<RefreshAuth/>}/>
            {pages.map(({page: Page, pathname, permissions}) => {
                return (
                    <Route key={pathname} path={pathname} element={
                        <RequireAuth permissions={permissions}>
                            <Page/>
                        </RequireAuth>
                    }/>
                )
            })}
            <Route
                path="*"
                element={<Navigate to="/is-emirleri"/>}
            />
        </BrowserRoutes>
    )
}

function RequireAuth({children, permissions}) {
    let location = useLocation();
    const {isAuthenticated, hasPermission} = useContext(AuthContext);

    if (!isAuthenticated) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/giris-yap" state={{from: location}}/>;
    }
    if (!hasPermission({permissions})) {
        return <Unauthorized/>
    }

    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENCE_KEY);
    return children;
}

export default Routes;
