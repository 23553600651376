import {forwardRef} from "react";
import NumberFormat from "react-number-format";

const TimeFieldFormat = forwardRef(function (
    props,
    ref
) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.formattedValue
                    }
                });
            }}
            format={'##:##'}
            allowEmptyFormatting
            mask={'_'}
        />
    );
});

export default TimeFieldFormat;
