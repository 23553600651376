import {hasPermission} from "../../utils/permissions";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {ContactType} from "../../helpers/customer/enums";
import Box from "@mui/material/Box";

const Contacts = function ({contacts}) {
    return (
        <>
            <Typography
                variant={"subtitle2"}
                sx={{marginTop: 2}}
            >Teknik iletişim
            </Typography>
            <Divider/>
            {
                contacts
                    .filter(({contactType}) => contactType === ContactType.TECHNICAL)
                    .map(contact => {
                        return (
                            <Box key={contact.id}>
                                <Typography
                                    variant={"body2"}
                                    sx={{marginTop: 2}}
                                >{contact.name}</Typography>
                                <Typography
                                    variant={"body2"}
                                >{contact.email}</Typography>
                                <Typography
                                    variant={"body2"}
                                >{contact.phone}</Typography>
                            </Box>
                        )
                    })
            }
            <Typography
                variant={"subtitle2"}
                sx={{marginTop: 2}}
            >Finansal iletişim
            </Typography>
            <Divider/>
            {
                contacts
                    .filter(({contactType}) => contactType === ContactType.FINANCIAL)
                    .map(contact => {
                        return (
                            <Box key={contact.id}>
                                <Typography
                                    variant={"body2"}
                                    sx={{marginTop: 2}}
                                >{contact.name}</Typography>
                                <Typography
                                    variant={"body2"}
                                >{contact.email}</Typography>
                                <Typography
                                    variant={"body2"}
                                >{contact.phone}</Typography>
                            </Box>
                        )
                    })
            }
        </>
    )
}

export default hasPermission(Contacts, {permissions: ['can_view_customer_contact']})
