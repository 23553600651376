import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import {useSnackbar} from "notistack";
import CustomerApi from '../../api/customer';
import {detailPageActionTypes} from "../detail-page-hoc/constants";
import ContactFormDialog from "./ContactFormDialog";
import {AuthContext} from "../../contexts/AuthContext";
import {hasPermission} from "../../utils/permissions";

const ContactCreateDialog = function ({sx}) {
    const {enqueueSnackbar} = useSnackbar();
    const {state, dispatch} = useContext(DetailPageContext);
    const authorization = useContext(AuthContext);

    const create = (values) => {
        return CustomerApi.addContact({authorization}, state.data.id, values).then(() => {
            dispatch({type: detailPageActionTypes.UPDATE_VERSION})
        }).catch(err => {
            if (err?.response?.status === 400) {
                enqueueSnackbar('Hatalı işlem', {
                    variant: "error"
                });
            }
        });
    }

    return (
        <ContactFormDialog
            onSubmit={create}
            title={'İletişim Kişisi Ekle'}
            label={'Ekle'}
            sx={sx}
        />
    );
}

export default hasPermission(ContactCreateDialog, {permissions: ['can_add_customer_contact']});
