import {get, post, put} from "./axios";
import {
    transformObjectKeysToCamelCase,
    transformObjectKeysToSnakeCase,
    transformSortModelToQueryString,
    transformFiltersToQueryString
} from "../utils/transform";

class Api {
    constructor({baseUrl}) {
        this.baseUrl = baseUrl;
        this.client = {
            get,
            post,
            put
        }
    }

    async errorMiddleware(context, request) {
        try {
            const response = await request;
            return response;
        } catch (err) {
            if (err?.response?.status === 400) {
                err.response.data = transformObjectKeysToCamelCase(err.response.data);
            }
            throw err;
        }
    }

    get(context, id) {
        return this.errorMiddleware(
            context,
            this.client.get(`${this.baseUrl}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            })
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    async getAll(context, sortModel = [], filters = {}, pagination) {
        let url = `${this.baseUrl}/?${transformSortModelToQueryString(sortModel)}&${transformFiltersToQueryString(filters)}`
        if (pagination) {
            url += `&page=${pagination.page + 1}&page_size=${pagination.pageSize}`
        }
        return this.errorMiddleware(
            context,
            this.client.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            })
        ).then(response => {
            if (pagination) {
                return transformObjectKeysToCamelCase(response.data)
            }
            return response.data.map(item => transformObjectKeysToCamelCase(item))
        })
    }

    async save(context, data) {
        return this.errorMiddleware(
            context,
            this.client.post(`${this.baseUrl}/`, transformObjectKeysToSnakeCase(data), {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            })
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    async update(context, id, data) {
        return this.errorMiddleware(
            context,
            this.client.put(`${this.baseUrl}/${id}/`, transformObjectKeysToSnakeCase(data), {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            })
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    async hasPermission(context, data) {
        return this.errorMiddleware(
            context,
            this.client.post(`${this.baseUrl}/has_permission/`, transformObjectKeysToSnakeCase(data), {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            })
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    async dropdown(context) {
        return this.errorMiddleware(
            context,
            this.client.get(`${this.baseUrl}/dropdown/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            })
        ).then(response => {
            return response.data.map(item => transformObjectKeysToCamelCase(item))
        })
    }
}

export default Api;
