import {useContext, useState} from "react";
import Dialog from "@mui/material/Dialog";
import {Formik} from "formik";
import * as yup from "yup";
import Stack from "@mui/material/Stack";
import TextField from "../forms/TextField";
import OrderItemApi from "../../api/order-item";
import {AuthContext} from "../../contexts/AuthContext";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import {currencies} from "../../utils/currency";
import SelectField from "../forms/SelectField";
import PriceFieldFormat from "../PriceFieldFormat";
import {DetailPageContext} from "../detail-page-hoc/context";
import {detailPageActionTypes} from "../detail-page-hoc/constants";


const OrderItemFormDialog = function (
    {
        title,
        open,
        handleClose,
        products,
        initialValues = {
            product: '',
            companyProductCode: '',
            customerProductCode: '',
            quantity: '',
            unitPrice: '',
            currency: '',
            description: '',
        },
    }
) {
    const authorization = useContext(AuthContext);
    const {state, dispatch} = useContext(DetailPageContext);
    const [loading, setLoading] = useState(false);

    return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    // actions.setSubmitting(false);
                    setLoading(true);
                    const payload = {
                        order: state.data.id,
                        ...values,
                    }
                    Object.entries(payload).forEach(([key, value]) => {
                        if (!value) delete payload[key]
                    });
                    OrderItemApi.save({authorization}, payload).then(response => {
                        setLoading(false);
                        dispatch({type: detailPageActionTypes.UPDATE_VERSION})
                        handleClose();
                    });
                }}
            >
                {formik => (
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContent>
                            <Stack sx={{py: 1}} spacing={1}>
                                <SelectField
                                    name={'product'}
                                    label={'Ürün / Hizmet / Satış'}
                                    values={products}
                                    itemLabelField={'name'}
                                    size={'small'}
                                    onChange={event => {
                                        if (event.target.value === 18) {
                                            formik.setFieldValue(
                                                'companyProductCode',
                                                'KESTAŞ YÜKSEK PERFORMANSLI BROŞLAMA YAĞI'
                                            );
                                        } else {
                                            if (
                                                formik.values.companyProductCode ===
                                                'KESTAŞ YÜKSEK PERFORMANSLI BROŞLAMA YAĞI'
                                            ) {
                                                formik.setFieldValue(
                                                    'companyProductCode',
                                                    ''
                                                );
                                            }
                                        }
                                    }}
                                />
                                <Stack spacing={2} sx={{mt: 2}}>
                                    <TextField
                                        name={'companyProductCode'}
                                        label={'Kestaş Ürün Kodu'}
                                        size={'small'}
                                    />
                                    <TextField
                                        name={'customerProductCode'}
                                        label={'Müşteri Ürün Kodu'}
                                        size={'small'}/>
                                </Stack>
                                <TextField
                                    name={'quantity'}
                                    label={'Adet veya Kg'}
                                    inputMode={'numeric'}
                                    size={'small'}
                                />
                                <Stack direction={"row"} spacing={1}>
                                    <TextField
                                        name={'unitPrice'}
                                        label={'Birim Fiyat'}
                                        inputMode={'decimal'}
                                        InputProps={{
                                            inputComponent: PriceFieldFormat
                                        }}
                                        size={'small'}
                                    />
                                    <SelectField
                                        name={'currency'}
                                        label={'Para Birimi'}
                                        values={currencies}
                                        nullable={false}
                                        valueField={'key'}
                                        size={'small'}
                                    />
                                </Stack>
                                <TextField
                                    name={'description'}
                                    label={'Ürün Ebat/Cins Açıklaması'}
                                    multiline
                                    size={'small'}
                                />
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                onClick={() => formik.submitForm()}
                            >Kaydet</Button>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                color={"error"}
                                onClick={handleClose}
                            >İptal</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
    )
}

const validationSchema = yup.object({
    product: yup
        .string()
        .required('Lütfen bir ürün/hizmet/satış tipi seçin'),
    companyProductCode: yup
        .string(),
    // .required('Kestaş ürün kodunu giriniz'),
    customerProductCode: yup.string(),
    description: yup
        .string(),
    // .required('Açıklama giriniz'),
    quantity: yup
        .number()
        .required('Miktar giriniz')
        .moreThan(0, 'Miktar sıfırdan büyük olmalı'),
    unitPrice: yup
        .number()
        .min(0, 'Birim fiyat negatif olamaz'),
    currency: yup
        .string()
        .oneOf(currencies.map(currency => currency.value)),
});

export default OrderItemFormDialog;
