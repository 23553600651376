import {useContext, useState} from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import {AuthContext} from "../../contexts/AuthContext";
import {useSnackbar} from 'notistack';

const StatusUpdateButton = function ({workOrder, setWorkOrder, api, process, label, color}) {
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);
    const authorization = useContext(AuthContext);
    const statusUpdate = () => {
        setLoading(true);
        api.updateStatus({authorization}, workOrder.id, {process}).then(workOrder => {
            setWorkOrder(workOrder);
            setLoading(false);
        }).catch(err => {
            if (err?.response?.status === 400) {
                enqueueSnackbar('Hatalı işlem', {
                    variant: "error"
                });
            }
            setLoading(false);
        });
    }
    return (
        <LoadingButton
            variant="contained"
            color={color || 'primary'}
            sx={{ml: 1, textTransform: 'none', displayPrint: 'none'}}
            onClick={() => statusUpdate()}
            loading={loading}
        >{label}
        </LoadingButton>
    )
}

export default StatusUpdateButton;
