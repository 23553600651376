import ArrowBack from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

const BackButton = function () {
    const navigate = useNavigate();

    return (
        <Button
            startIcon={<ArrowBack/>}
            sx={{textTransform: 'none'}}
            onClick={() => navigate(-1)}
            variant="outlined"
        >
            Geri
        </Button>
    )
}

export default BackButton;
