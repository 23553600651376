export const revenueReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_VERSION':
            return {
                ...state,
                version: state.version + 1
            }
        case 'SET_DATA':
            return {
                ...state,
                data: action.data,
                lastUpdate: (new Date()).toISOString(),
            }
        case 'SET_EXCHANGE_RATES':
            return {
                ...state,
                exchangeRates: action.exchangeRates,
            }
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.loading
            }
        case 'SET_CATEGORY':
            return {
                ...state,
                category: action.category
            }
        case 'SET_CURRENCY':
            return {
                ...state,
                currency: action.currency
            }
        default:
            return state;
    }
}
