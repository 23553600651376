import addMonths from "date-fns/addMonths";
import Chip from "@mui/material/Chip";
import {transformDateToLocalString} from "../utils/transform";

const DeadlineChip = function ({date, isCompletedOrFailed}) {
    if (!date) return (<></>);
    const deadline = new Date(date);
    const currentDate = new Date();
    const oneMonthLaterDate = addMonths(currentDate, 1);
    return (
        <Chip
            label={transformDateToLocalString(date)}
            color={
                isCompletedOrFailed
                    ? 'statusCompleted'
                    : deadline < currentDate
                        ? 'error'
                        : oneMonthLaterDate > deadline
                            ? 'warning' : 'success'
            }
        />
    );
}

export default DeadlineChip;
