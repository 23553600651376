import OrderApi from "../api/order";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import DetailPageHOC from "../components/detail-page-hoc";
import DetailActionButtons from "../components/order/DetailActionButtons";
import DetailTable from "../components/order/DetailTable";
import StatusHistoryHOC from "../components/order/StatusHistoryHOC";
import CustomerTableHOC from "../components/order/CustomerTableHOC";
import OrderItemTable from "../components/order/OrderItemTable";
import WorkOrderTable from "../components/order/WorkOrderTable";
import InvoiceOrderTable from "../components/order/InvoiceOrderTable";

const OrderDetail = function () {
    return (
        <DetailPageHOC api={OrderApi}>
            <DetailActionButtons/>
            <Grid container spacing={2} marginTop={3}>
                <Grid item md={8} xs={12}>
                    <Stack spacing={2} marginBottom={3}>
                        <DetailTable showCreator/>
                        <OrderItemTable/>
                        <WorkOrderTable/>
                        <InvoiceOrderTable/>
                    </Stack>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Stack spacing={2}>
                        <CustomerTableHOC/>
                        <StatusHistoryHOC/>
                    </Stack>
                </Grid>
            </Grid>
        </DetailPageHOC>
    )
}

export default OrderDetail;
