import {useCallback, useContext} from "react";
import BaseDataTable from "../datatable";
import ProductPoolApi from "../../api/product-pool";
import {ListPageContext} from "../list-page-hoc/context";
import {listPageActionTypes} from "../list-page-hoc/constants";
import {useListApi} from "../list-page-hoc/hooks";
import ProductStatusChip from "../customers/ProductStatusChip";
import {transformDateToLocalString} from "../../utils/transform";
import CopyToClipboardButton from "../CopyToClipboardButton";
import ProductPoolUpdateDialog from "./ProductPoolUpdateDialog";

function DataTable() {
    const {state, dispatch} = useContext(ListPageContext);
    const {data: products} = useListApi(ProductPoolApi);

    const handleSortModelChange = sortModel => {
        dispatch({type: listPageActionTypes.SET_SORT_MODEL, sortModel})
    };

    const handleFilterModelChange = useCallback(filterModel => {
    }, []);

    const onPageChange = (page) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                page: page,
            }
        })
    }

    const onPageSizeChange = (pageSize) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                pageSize,
            }
        })
    }


    return (
        <BaseDataTable
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            sortModel={state.sortModel}
            loading={state.loading}
            rowCount={products.count}
            page={state.pagination.page}
            pageSize={state.pagination.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={products.results}
            columns={[
                {
                    field: 'customer',
                    filterable: false,
                    headerName: 'Müşteri',
                    sortable: true,
                    valueFormatter: params => params.value.name
                },
                {
                    field: 'companyProductCode',
                    filterable: false,
                    headerName: 'Kestaş Ürün Kodu',
                    sortable: true,
                },
                {
                    field: 'description',
                    filterable: false,
                    headerName: 'Ebatları',
                    sortable: false,
                },
                {
                    field: 'productType',
                    filterable: false,
                    headerName: 'Cinsi',
                    sortable: false,
                },
                {
                    field: 'customerProductCode',
                    filterable: false,
                    headerName: 'Müşteri Ürün Kodu',
                    sortable: false,
                },
                {
                    field: 'customerBroachCode',
                    filterable: false,
                    headerName: 'Müşteri Broş Kodu',
                    sortable: false,
                },
                {
                    field: 'createdAt',
                    filterable: false,
                    headerName: 'İlk Teknik Resim Tarihi',
                    sortable: true,
                    valueFormatter: (params) => {
                        return params.value ? transformDateToLocalString(params.value, true) : '-';
                    }
                },
                {
                    field: 'updatedAt',
                    filterable: false,
                    headerName: 'Güncel Teknik Resim Tarihi',
                    sortable: true,
                    valueFormatter: (params) => {
                        return params.value ? transformDateToLocalString(params.value, true) : '-';
                    }
                },
                {
                    field: 'version',
                    filterable: false,
                    headerName: 'Revizyon No',
                    sortable: false,
                    valueFormatter: (params) =>  params.value ?? '-',
                },
                {
                    field: 'note',
                    filterable: false,
                    headerName: 'Özel Notlar',
                    sortable: false,
                },
                {
                    field: 'status',
                    filterable: false,
                    headerName: 'Durum',
                    sortable: false,
                    width: 200,
                    renderCell: (params) => <ProductStatusChip status={params.value}/>
                },
                {
                    field: 'actions',
                    type: 'actions',
                    filterable: false,
                    headerName: 'İşlemler',
                    sortable: false,
                    getActions: params => {
                        return [
                            <CopyToClipboardButton
                                sx={{
                                    px: 1,
                                    opacity: 0.1,
                                    transition: 'opacity ease-out 300ms',
                                    '&:hover': {
                                        opacity: 1
                                    }
                                }}
                                copyText={params.row.archivePath}
                                title="Dosya yolunu kopyala"
                                message={'Dosya yolu kopyalandı'}
                            />,
                            <ProductPoolUpdateDialog item={params.row}/>
                        ]
                    },
                },
            ]}
        />
    )
}

export default DataTable;
