import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {useContext} from "react";
import {AnalyzeContainerContext} from "./analyze-container-hoc/context";

const ActionButtons = function () {
    const {state, dispatch} = useContext(AnalyzeContainerContext);

    return (
        <Box sx={{width: '100%', display: 'flex', pb: 3}}>
            <Box sx={{flexGrow: 1}}/>
            <Box sx={{display: 'flex'}}>
                <LoadingButton
                    variant="outlined"
                    loading={state.loading}
                    sx={{textTransform: 'none'}}
                    onClick={() => {
                        dispatch({type: 'UPDATE_VERSION'});
                    }}
                >
                    Tümünü Güncelle
                </LoadingButton>
            </Box>
        </Box>
    )
}

export default ActionButtons;
