import {ContactType, PriceType, ProductStatus, Unit} from "./enums";

export const contactTypeTranslate = {
    [ContactType.TECHNICAL]: 'Teknik',
    [ContactType.FINANCIAL]: 'Finans'
}

export const priceTypeTranslate = {
    [PriceType.END_USER]: 'Son Kullanıcı',
    [PriceType.DISTRIBUTOR]: 'Bayi'
}

export const unitTranslate = {
    [Unit.KG]: 'KG',
    [Unit.PIECE]: 'ADET',
}

export const productStatusTranslate = {
    [ProductStatus.CREATED]: 'Aktif',
    [ProductStatus.VERSION_UPDATED]: 'Revizyona Uğradı',
    [ProductStatus.CANCELED]: 'İptal Edildi',
    [ProductStatus.NOT_FOUND]: 'Resim Yok',
}