import {createContext, useState, useEffect} from 'react';

const MessageContext = createContext(null);

function MessageProvider({sendMessage, getInitialMessages, messageFormatter, children}) {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        getInitialMessages().then(initialMessages => {
            if (messageFormatter) {
                initialMessages = initialMessages.map(message => messageFormatter(message))
            }
            setMessages(initialMessages)
        })
    }, [getInitialMessages, messageFormatter])

    const send = (message) => {
        console.log(message);
        return sendMessage(message).then(message => {
            if (messageFormatter) {
                message = messageFormatter(message)
            }
            setMessages(prevState => [...prevState, message])
        })
    };

    const value = {messages, send};

    return <MessageContext.Provider value={value}>{children}</MessageContext.Provider>;
}

export {MessageContext};
export default MessageProvider;
