import {useState} from "react";
import Button from "@mui/material/Button";
import WorkOrderFormDialog from "./WorkOrderFormDialog";
import {hasPermission} from "../../utils/permissions";

const CreateWorkOrderButton = function ({sx = {}, products, customers}) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button
                variant={'outlined'}
                sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
                onClick={() => setOpen(true)}
            >İş Emri Oluştur</Button>
            <WorkOrderFormDialog
                open={open}
                handleClose={() => setOpen(false)}
                products={products}
                customers={customers}
            />
        </>
    )
}

export default hasPermission(CreateWorkOrderButton, {permissions: ['add_workorder']});
