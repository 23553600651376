import Paper from "@mui/material/Paper";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const ProcessTable = function ({tableSize = 'medium'}) {
    const {state} = useContext(DetailPageContext);
    if (!state.data.product?.qualityControlEnabled) return null;

    const processes = state.data.operations.filter(operation => operation.category.qualityControlEnabled);
    return (
        <Paper sx={{padding: 2}}>
            <Typography variant={"subtitle1"}>Proses</Typography>
            <Divider/>
            <Stepper
                orientation="horizontal"
                sx={{marginTop: 2}}
                alternativeLabel
                nonLinear
            >
                {processes.map((process) => {
                    return (
                        <Step key={process.id} active={process.isCompleted}>
                            <StepLabel StepIconProps={{completed: process.isCompleted}}>
                                <Typography
                                    variant={"caption"}
                                >{process.category.name}
                                </Typography>
                            </StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </Paper>
    )
}

export default ProcessTable;
