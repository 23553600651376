import CustomerApi from "../api/customer";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import DetailPageHOC from "../components/detail-page-hoc";
import DetailActionButtons from "../components/customers/DetailActionButtons";
import DetailTable from "../components/customers/DetailTable";
import ContactsTable from "../components/customers/ContactsTable";
import DiscountsTable from "../components/customers/DiscountsTable";
import BroachingPriceTable from "../components/customers/BroachingPriceTable";
import BroochSharpeningPriceTable from "../components/customers/BroochSharpeningPriceTable";
import BroachingOilPriceTable from "../components/customers/BroachingOilPriceTable";
import ProductPoolTable from "../components/customers/ProductPoolTable";

const CustomerDetail = function () {

    return (
        <DetailPageHOC api={CustomerApi}>
            <DetailActionButtons/>
            <Grid container spacing={2} marginTop={3} paddingBottom={3}>
                <Grid item md={12}>
                    <Stack spacing={2}>
                        <DetailTable/>
                        <ContactsTable/>
                        <DiscountsTable/>
                        <BroachingPriceTable/>
                        <BroochSharpeningPriceTable/>
                        <BroachingOilPriceTable/>
                        <ProductPoolTable/>
                    </Stack>
                </Grid>
            </Grid>
        </DetailPageHOC>
    )
}

export default CustomerDetail;
