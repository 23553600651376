export const statusTranslate = {
    OFFER: {
        chipLabel: 'Teklif',
        statusUpdateButtons: [
            {
                label: 'Onayla',
                process: 'accepted',
            },
            {
                label: 'Reddedildi',
                process: 'rejected',
                color: 'warning',
            }
        ],
        status: 'Teklif oluşturuldu',
        enum: 'OFFER'
    },
    OFFER_REJECTED: {
        chipLabel: 'Reddedildi',
        status: 'Teklif reddedildi',
        enum: 'OFFER_REJECTED'
    },
    UPDATED: {
        status: 'Teklif güncellendi',
        enum: 'UPDATED'
    },
    ACTIVE: {
        chipLabel: 'Aktif',
        statusUpdateButtonLabel: '',
        statusUpdateButtons: [
            {
                label: 'Siparişi Tamamla',
                process: 'completed',
            },
            {
                label: 'İptal Et',
                process: 'canceled',
                color: 'warning',
            }
        ],
        status: 'Sipariş açıldı',
        enum: 'ACTIVE'
    },
    COMPLETED: {
        chipLabel: 'Tamamlandı',
        status: 'Sipariş kapandı',
        enum: 'COMPLETED'
    },
    CANCELED: {
        chipLabel: 'İptal',
        status: 'Sipariş iptal edildi',
        enum: 'CANCELED'
    },
}
