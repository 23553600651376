import {useContext} from "react";
import {ListPageContext} from "../list-page-hoc/context";
import {useSnackbar} from "notistack";
import OperationTimeTrackingApi from '../../api/operation-time-tracking';
import {listPageActionTypes} from "../list-page-hoc/constants";
import TimeTrackingFormDialog from "./TimeTrackingFormDialog";
import {AuthContext} from "../../contexts/AuthContext";
import {hasPermission} from "../../utils/permissions";

const TimeTrackingDataCreateDialog = function () {
    const {enqueueSnackbar} = useSnackbar();
    const {dispatch} = useContext(ListPageContext);
    const authorization = useContext(AuthContext);

    const create = (values) => {
        console.log(values);
        return OperationTimeTrackingApi.save({authorization}, values).then(() => {
            dispatch({type: listPageActionTypes.UPDATE_VERSION})
            return {status: true};
        }).catch(err => {
            if (err?.response?.status === 500) {
                enqueueSnackbar('Sunucuda bir hata oldu', {
                    variant: "error"
                });
                return {status: false};
            }
            enqueueSnackbar('Hatalı işlem', {
                variant: "error"
            });
            return {status: false, errors: err.response.data};
        });
    }

    return (
        <TimeTrackingFormDialog
            onSubmit={create}
            title={'Zaman Takip Kaydı Oluştur'}
            label={'Zaman Takip Kaydı Oluştur'}
            sx={{ml: 1}}
        />
    );
}

export default hasPermission(TimeTrackingDataCreateDialog, {permissions: ['add_operationtimetracking']});
