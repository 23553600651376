import Layout from "../layout";
import DetailPageProvider from "./context";
import Container from "@mui/material/Container";
import {useParams} from "react-router-dom";

const DetailPageHOC = function ({children, api}) {
    const {id} = useParams();

    return (
        <Layout>
            <Container maxWidth={"xl"}>
                <DetailPageProvider id={id} api={api}>
                    {children}
                </DetailPageProvider>
            </Container>
        </Layout>
    )
}

export default DetailPageHOC;
