import {useState, useContext} from "react";
import CustomerApi from "../../api/customer";
import LoadingButton from "@mui/lab/LoadingButton";
import {AuthContext} from "../../contexts/AuthContext";
import {DetailPageContext} from "../detail-page-hoc/context";
import {useSnackbar} from "notistack";
import {detailPageActionTypes} from "../detail-page-hoc/constants";
import {hasPermission} from "../../utils/permissions";

const RemoveBroochSharpeningPriceButton = function ({contactId}) {
    const authorization = useContext(AuthContext);
    const {state, dispatch} = useContext(DetailPageContext);
    const [loading, setLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    const removeBroochSharpeningPrice = () => {
        CustomerApi.removeBroochSharpeningPrice({authorization}, state.data.id, {id: contactId})
            .then(() => {
                dispatch({type: detailPageActionTypes.UPDATE_VERSION})
                setLoading(false);
            })
            .catch(err => {
                let message = 'Bir hata oldu'
                if (err?.response?.status === 400) {
                    message = 'Hatalı işlem'
                }
                enqueueSnackbar(message, {
                    variant: "error"
                });
                setLoading(false);
            });
    }

    return (
        <LoadingButton
            loading={loading}
            style={{textTransform: 'none'}}
            variant={"outlined"}
            color={"warning"}
            onClick={() => removeBroochSharpeningPrice()}
        >Sil</LoadingButton>
    )
}

export default hasPermission(RemoveBroochSharpeningPriceButton, {permissions: ['can_remove_customer_brooch_sharpening_price']});
