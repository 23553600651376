import Box from "@mui/material/Box";
import BackButton from "../BackButton";
import {useContext, useEffect, useState} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import Typography from "@mui/material/Typography";
import StatusChip from "./StatusChip";
import {AuthContext} from "../../contexts/AuthContext";
import ProductApi from "../../api/product";
import CreateOrderItemButton from "./CreateOrderItemButton";
import StatusUpdateButton from "./StatusUpdateButton";
import {statusTranslate} from "../../helpers/order/status";

const DetailActionButtons = function () {
    const authorization = useContext(AuthContext);
    const {state} = useContext(DetailPageContext);
    const [products, setProducts] = useState([]);
    const status = statusTranslate[state.data.status]

    useEffect(() => {
        ProductApi.getAll({authorization}).then(products => setProducts(products))
    }, [authorization]);

    return (
        <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
            <BackButton/>
            <Typography variant="h4" fontWeight="light" marginLeft={1}>
                # {state.data.companyOrderId || state.data.customerOrderId}
            </Typography>
            <StatusChip status={state.data.status} sx={{marginLeft: 1}}/>
            {[statusTranslate.OFFER.enum, statusTranslate.ACTIVE.enum].includes(status.enum) && (
                <CreateOrderItemButton products={products} sx={{marginLeft: 'auto'}}/>
            )}
            {status.statusUpdateButtons && status.statusUpdateButtons.map(
                ({label, process, color}) => <StatusUpdateButton
                    key={process} label={label} process={process} color={color}/>
            )}

        </Box>
    )
}

export default DetailActionButtons;
