import {DataGridPro, trTR} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import {useMemo} from "react";

const TotalOilSaleTable = function ({loading, rawData}) {
    const data = useMemo(() => {
        return rawData.reduce((result, oilSale) => {
            const row = result.find(row => row.year === String(oilSale.countingYear))
            if (row) {
                row.sum += Number(oilSale.amount)
                row.divider = oilSale.countingMonth > row.divider ? oilSale.countingMonth : row.divider;
                row.mean = parseInt(row.sum / row.divider)
            } else {
                result.push({
                    id: oilSale.countingYear,
                    year: String(oilSale.countingYear),
                    sum: Number(oilSale.amount),
                    mean: Number(oilSale.amount),
                    change1: oilSale.countingYear - 1,
                    change2: oilSale.countingYear - 2,
                    divider: oilSale.countingMonth,
                })
            }
            return result;
        }, []).sort((a, b) => Number(a.year) - Number(b.year))
    }, [rawData])

    return (
        <Box sx={{width: '100%'}}>
            <DataGridPro
                autoHeight
                loading={loading}
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                columns={[
                    {field: 'year', headerName: 'Yıl'},
                    {
                        field: `sum`,
                        flex: 1,
                        headerName: 'Toplam',
                        sortable: false,
                        filterable: false,
                        pinnable: false,
                        hideable: false,
                    },
                    {
                        field: 'change1',
                        flex: 1,
                        headerName: '1 Yıllık Değişim',
                        sortable: false,
                        filterable: false,
                        pinnable: false,
                        hideable: false,
                        valueFormatter: (params) => {
                            const compareYear = params.api.getRow(params.value);
                            const currentYear = params.api.getRow(params.id);
                            if (!compareYear) return '-'
                            return `${((currentYear.sum - compareYear.sum) * 100 / compareYear.sum).toFixed(1)}%`;
                        }
                    },
                    {
                        field: 'change2',
                        flex: 1,
                        headerName: '2 Yıllık Değişim',
                        sortable: false,
                        filterable: false,
                        pinnable: false,
                        hideable: false,
                        valueFormatter: (params) => {
                            const compareYear = params.api.getRow(params.value);
                            const currentYear = params.api.getRow(params.id);
                            if (!compareYear) return '-'
                            return `${((currentYear.sum - compareYear.sum) * 100 / compareYear.sum).toFixed(1)}%`;
                        }
                    },
                    {
                        field: 'mean',
                        flex: 1,
                        headerName: 'Aylık Ortalama',
                        sortable: false,
                        filterable: false,
                        pinnable: false,
                        hideable: false,
                    },
                ]}
                initialState={{
                    pinnedColumns: {left: ['year']}
                }}
                rows={data}
            />
        </Box>
    )
}

export default TotalOilSaleTable;
