import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import TableHead from "@mui/material/TableHead";
import ProductPoolCreateDialog from "./ProductPoolCreateDialog";
import ProductPoolUpdateDialog from "./ProductPoolUpdateDialog";
import {hasPermission} from "../../utils/permissions";
import {transformDateToLocalString} from "../../utils/transform";
import ProductStatusChip from "./ProductStatusChip";

const ProductPoolTable = function () {
    const {state} = useContext(DetailPageContext);
    const customer = state.data;

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Ürün Havuzu</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right">
                            <ProductPoolCreateDialog/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Kestaş Ürün Kodu</TableCell>
                        <TableCell>Ebatları</TableCell>
                        <TableCell>Cinsi</TableCell>
                        <TableCell>Müşteri Ürün Kodu</TableCell>
                        <TableCell>Müşteri Broş Kodu</TableCell>
                        <TableCell>İlk Teknik Resim Tarihi</TableCell>
                        <TableCell>Güncel Teknik Resim Tarihi</TableCell>
                        <TableCell>Revizyon No</TableCell>
                        <TableCell>Özel Notlar</TableCell>
                        <TableCell>Durum</TableCell>
                        <TableCell>Arşiv Dosya Konumu</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customer.productPool.map(
                        (item) => {
                            const {
                                id,
                                companyProductCode,
                                description,
                                productType,
                                customerProductCode,
                                customerBroachCode,
                                createdAt,
                                updatedAt,
                                version,
                                note,
                                status,
                                archivePath,
                            } = item
                            return (
                                <TableRow key={id}>
                                    <TableCell>{companyProductCode}</TableCell>
                                    <TableCell>{description}</TableCell>
                                    <TableCell>{productType}</TableCell>
                                    <TableCell>{customerProductCode}</TableCell>
                                    <TableCell>{customerBroachCode}</TableCell>
                                    <TableCell>{createdAt ? transformDateToLocalString(createdAt, true) : '-'}</TableCell>
                                    <TableCell>{updatedAt ? transformDateToLocalString(updatedAt, true) : '-'}</TableCell>
                                    <TableCell>{version ?? '-'}</TableCell>
                                    <TableCell>{note}</TableCell>
                                    <TableCell><ProductStatusChip status={status}/></TableCell>
                                    <TableCell>{archivePath}</TableCell>
                                    <TableCell align="right">
                                        <ProductPoolUpdateDialog item={item}/>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default hasPermission(ProductPoolTable, {permissions: ['view_productpool']});
