import {useContext} from "react";
import StatusHistory from "../StatusHistory";
import {DetailPageContext} from "../detail-page-hoc/context";
import {statusTranslate} from "../../helpers/work-order/status";

const StatusHistoryHOC = function () {
    const {state} = useContext(DetailPageContext);
    return (
        <StatusHistory
            statusHistory={state.data.statusHistory}
            statusFormat={({status}) => {
                if (
                    state.data.customer.name === process.env.REACT_APP_COMPANY_NAME &&
                    status === statusTranslate.WAITING_GENERAL_DIRECTOR_APPROVE.enum
                ) {
                    return 'İç sipariş tamamlandı'
                }
                return statusTranslate[status].status
            }}
        />
    )
}

export default StatusHistoryHOC;
