import {createContext, useMemo, useState} from 'react';
import sessionManager from '../utils/session';
import {createTheme, ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {yellow} from "@mui/material/colors";

const initialState = sessionManager.getSessionData('colorMode') || 'light'
const ColorModeContext = createContext({
    toggleColorMode: () => {
    }
});


function ThemeProvider({children}) {
    let [mode, setMode] = useState(initialState);

    let toggleColorMode = () => {
        setMode((prevMode) => {
            const newMode = prevMode === 'light' ? 'dark' : 'light';
            sessionManager.setSessionData('colorMode', newMode);
            return newMode;
        });
    };
    let value = {toggleColorMode};

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode,
                    statusCompleted: {
                        main: yellow[500],
                        contrastText: '#000000'
                    }
                },
                components: {
                    MuiTab: {
                        styleOverrides: {
                            root: {
                                textTransform: 'none',
                            }
                        }
                    },
                    MuiDataGrid: {
                        styleOverrides: {
                            cell: ({theme}) => ({
                                border: `1px solid ${
                                    theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                                }`,
                                fontSize: '0.8rem',
                            })
                        }
                    }
                }
            }),
        [mode],
    );

    return (
        <ColorModeContext.Provider value={value}>
            <MuiThemeProvider theme={theme}>
                {children}
            </MuiThemeProvider>
        </ColorModeContext.Provider>
    );
}

export {ColorModeContext};
export default ThemeProvider;
