import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import {AuthContext} from "../../contexts/AuthContext";

const DetailTable = function () {
    const {state} = useContext(DetailPageContext);
    const {hasPermission} = useContext(AuthContext);
    const customer = state.data;

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Firma Adı</TableCell>
                        <TableCell>{customer.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Telefon</TableCell>
                        <TableCell>{customer.phone}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Adres</TableCell>
                        <TableCell>{customer.address}</TableCell>
                    </TableRow>
                    {hasPermission({permissions: ['can_view_financial_notes']}) && (
                        <TableRow>
                            <TableCell>Finansal Notlar</TableCell>
                            <TableCell>{customer.financialNotes}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DetailTable;
