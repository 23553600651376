import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {useContext, useEffect} from "react";
import {AuthContext} from "../contexts/AuthContext";
import UserApi from "../api/user";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const RefreshAuth = function () {
    const authorization = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        let aborted = false;
        UserApi.me({authorization})
            .then(() => {
                !aborted && navigate(-1)
            })
            .catch(() => {
                !aborted && authorization.refreshToken()
                    .then(() => !aborted && navigate(-1))
                    .catch(
                        () => !aborted && authorization
                            .signOut({isForced: true, message: 'Oturum süresi dolmuş'})
                            .then(() => navigate('/giris-yap', {replace: false}))
                    );
            });
        return () => {
            aborted = true;
        }
    }, [authorization, navigate]);

    return (
        <Box sx={{width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Stack spacing={1}>
                <Box sx={{textAlign: 'center'}}>
                    <CircularProgress/>
                </Box>
                <Typography>Oturum Güncelleniyor</Typography>
            </Stack>
        </Box>
    )
}

export default RefreshAuth;
