import {useContext} from "react";
import StatusHistory from "../StatusHistory";
import {DetailPageContext} from "../detail-page-hoc/context";
import {statusTranslate} from "../../helpers/invoice-order/status";

const StatusHistoryHOC = function () {
    const {state} = useContext(DetailPageContext);
    return (
        <StatusHistory
            statusHistory={state.data.statusHistory}
            statusFormat={({status}) => statusTranslate[status].status}
        />
    )
}

export default StatusHistoryHOC;
