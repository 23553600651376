import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import {transformDateToLocalString} from "../../utils/transform";
import StatusChip from "../invoice-order/StatusChip";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";
import PriceTextFormat from "../PriceTextFormat";
import {hasPermission} from "../../utils/permissions";

const InvoicesTable = function ({tableSize = 'medium'}) {
    const {state} = useContext(DetailPageContext);
    const navigate = useNavigate();

    return (
        <TableContainer component={Paper}>
            <Table size={tableSize}>
                <TableHead>
                    <TableRow>
                        <TableCell>Kestaş Fatura No</TableCell>
                        <TableCell>Fatura Tarihi</TableCell>
                        <TableCell>Adet veya Kg</TableCell>
                        <TableCell>Birim Fiyat</TableCell>
                        <TableCell>Durum</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state.data.invoices.map(invoice => (
                        <TableRow key={invoice.id}>
                            <TableCell>{invoice.companyInvoiceNo}</TableCell>
                            <TableCell>{invoice.companyInvoiceDate && transformDateToLocalString(invoice.companyInvoiceDate)}</TableCell>
                            <TableCell>{invoice.quantity}</TableCell>
                            <TableCell>
                                <PriceTextFormat
                                    prefix={invoice.currency}
                                    value={invoice.unitPrice}
                                />
                            </TableCell>
                            <TableCell><StatusChip status={invoice.status}/></TableCell>
                            <TableCell>
                                <IconButton onClick={() => {
                                    navigate(`/fatura-emirleri/${invoice.id}`, {replace: false})
                                }}>
                                    <SearchIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default hasPermission(InvoicesTable, {permissions: ['can_view_invoices_table']});
