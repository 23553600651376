import Box from "@mui/material/Box";
import BackButton from "../BackButton";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import Typography from "@mui/material/Typography";
import {statusTranslate} from "../../helpers/work-order/status";
import PrintDialog from "./PrintDialog";
import WorkOrderApi from "../../api/work-order";
import ProductionCompleteDialog from "./ProductionCompleteDialog";
import {detailPageActionTypes} from "../detail-page-hoc/constants";
import StatusChip from "./StatusChip";
import RenewWorkOrderButton from "./RenewWorkOrderButton";
import MarkAsDamagedButton from "./MarkAsDamagedButton";
import CancelButton from "./CancelButton";
import ApproveButton from "./ApproveButton";
import ReopenButton from "./ReopenButton";
import ProductionCompleteButton from "./ProductionCompleteButton";

const DetailActionButtons = function () {
    const {state, dispatch} = useContext(DetailPageContext);

    const isStatusActive = state.data.status === statusTranslate.ACTIVE.enum;
    const isStatusWaitingForInvoice = state.data.status === statusTranslate.WAITING_FOR_INVOICE.enum;
    const isInternalOrder = state.data.customer.name === process.env.REACT_APP_COMPANY_NAME;

    const setWorkOrder = (workOrder) => {
        dispatch({type: detailPageActionTypes.UPDATE_DATA, data: workOrder})
    }

    return (
        <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
            <BackButton/>
            <Typography variant="h4" fontWeight="light" marginLeft={1}>
                # {state.data.workOrderId}
            </Typography>
            <StatusChip status={state.data.status} sx={{marginLeft: 1}}/>
            <PrintDialog sx={{ml: 'auto'}} />
            <RenewWorkOrderButton sx={{ml: 1}}/>
            {isStatusActive && (
                <MarkAsDamagedButton
                    workOrder={state.data}
                    setWorkOrder={setWorkOrder}
                />
            )}
            {isStatusActive && (
                <CancelButton
                    workOrder={state.data}
                    setWorkOrder={setWorkOrder}
                />
            )}
            {isStatusWaitingForInvoice && (
                <ReopenButton
                    workOrder={state.data}
                    setWorkOrder={setWorkOrder}
                />
            )}
            {state.data.status === statusTranslate.WAITING_GENERAL_DIRECTOR_APPROVE.enum && (
                <ApproveButton
                    workOrder={state.data}
                    setWorkOrder={setWorkOrder}
                />
            )}
            {isStatusActive && !isInternalOrder && (
                <ProductionCompleteDialog
                    workOrder={state.data}
                    setWorkOrder={setWorkOrder}
                    api={WorkOrderApi}
                />
            )}
            {isStatusActive && isInternalOrder && (
                <ProductionCompleteButton
                    workOrder={state.data}
                    setWorkOrder={setWorkOrder}
                />
            )}
        </Box>
    )
}

export default DetailActionButtons;
