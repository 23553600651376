import DataTable from "../components/order/DataTable";
import ListActionButtons from "../components/order/ListActionButtons";
import ListPageHOC from "../components/list-page-hoc";

const Orders = function () {
    return (
        <ListPageHOC
            storageKey={'order-page'}
            sortModel={[{field: "createdAt", sort: "desc"}]}
            filters={{status: []}}
        >
            <ListActionButtons/>
            <DataTable />
        </ListPageHOC>
    )
}

export default Orders;
