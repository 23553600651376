import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {listPageActionTypes} from "../list-page-hoc/constants";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {useContext} from "react";
import {ListPageContext} from "../list-page-hoc/context";
import snakeCase from 'lodash.snakecase';

const ProcessFilter = function ({sx, processFields}) {
    const {state, dispatch} = useContext(ListPageContext);

    return (
        <FormControl sx={sx} size="small">
            <InputLabel id="process-select-label">Proses</InputLabel>
            <Select
                labelId="process-select-label"
                label="Proses"
                value={state.filters.processes || ''}
                onChange={(event) => {
                    dispatch({
                        type: listPageActionTypes.SET_FILTERS,
                        filters: {
                            ...state.filters,
                            processes: event.target.value
                        }
                    })
                }}
            >
                {processFields.map((field) => {
                    return <MenuItem key={field.name} value={snakeCase(field.name)}>{field.label}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}

export default ProcessFilter;
