import {actionStatusFormat} from "../../helpers/action/format";
import {ActionStatusTypes} from "../../helpers/action/enums";
import Chip from "@mui/material/Chip";

const StatusChip = function ({status, sx={}}) {
    const label = actionStatusFormat(status);
    const isStatusCompleted = status === ActionStatusTypes.DONE;
    const isStatusCanceled = status === ActionStatusTypes.CANCELED;
    return (
        <Chip
            sx={sx}
            label={label}
            color={
                isStatusCompleted
                    ? `statusCompleted`
                    : isStatusCanceled
                        ? 'warning'
                        : 'primary'
            }
        />
    )
}

export default StatusChip;
