import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import PriceTextFormat from "../PriceTextFormat";
import {statusTranslate} from "../../helpers/work-order/status";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CreateWorkOrderButton from "./CreateWorkOrderButton";
import Divider from "@mui/material/Divider";
import {TableFooter} from "@mui/material";

const OrderItemTable = function () {
    const {state} = useContext(DetailPageContext);

    return (
        <>
            <Typography variant="h6">Ürünler</Typography>
            <Divider/>
            {state.data.items.length === 0 && (
                <Typography variant="body1">Henüz ürün eklenmemiş.</Typography>
            )}
            <Grid container spacing={1}>
                {
                    state.data.items.map(item => {
                        return (
                            <Grid key={item.id} item xs={12} lg={6} height="max-content">
                                <TableContainer component={Paper}>
                                    <Table size="medium">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Ürün/Hizmet</TableCell>
                                                <TableCell>{item.product.name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Kestaş Ürün Kodu</TableCell>
                                                <TableCell>{item.companyProductCode}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Müşteri Ürün Kodu</TableCell>
                                                <TableCell>{item.customerProductCode}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Adet veya Kg</TableCell>
                                                <TableCell>{item.quantity}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Birim Fiyat</TableCell>
                                                <TableCell>
                                                    <PriceTextFormat
                                                        prefix={item.currency}
                                                        value={item.unitPrice}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Ürün Ebat/Cins Açıklaması</TableCell>
                                                <TableCell>{item.description}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Durum</TableCell>
                                                <TableCell>
                                                    <Box sx={{
                                                        textAlign: 'left',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        lineHeight: 1.4
                                                    }}>{
                                                        item.workOrders.map(workOrder =>
                                                            <Typography
                                                                key={workOrder.status}
                                                                variant="caption"
                                                                lineHeight="inherit"
                                                            >{workOrder.count} {statusTranslate[workOrder.status].chipLabel}</Typography>
                                                        )
                                                    }
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell colSpan={12}>
                                                    <CreateWorkOrderButton item={item}/>
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}

export default OrderItemTable;