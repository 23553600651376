import ClusteredColumnChart from "../ClusteredColumnChart";
import {useContext, useMemo} from "react";
import {RevenueContext} from "./revenue-hoc/context";

const ColumnChartHOC = function () {
    const {state} = useContext(RevenueContext);

    const rawData = useMemo(() => {
        if (state.currency === 'TL') return state.data;

        return state.data.reduce((result, row) => {
            const exchangeRate = state.exchangeRates.find(
                ({
                     countingYear,
                     countingMonth,
                     currency
                 }) => {
                    return countingYear === row.countingYear &&
                        String(countingMonth) === String(row.countingMonth) &&
                        currency === state.currency
                })
            if (!exchangeRate) {
                return result
            }
            result.push({
                ...row,
                revenue: Number((row.revenue / parseFloat(exchangeRate.rate)).toFixed(2))
            })
            return result;
        }, [])

    }, [state.currency, state.data, state.exchangeRates])

    const data = useMemo(() => {
        return rawData.reduce((result, revenue) => {
            const row = result.find(row => row.year === String(revenue.countingYear))
            if (row) {
                row.sum += Number(revenue.revenue)
            } else {
                result.push({
                    year: String(revenue.countingYear),
                    sum: Number(revenue.revenue),
                })
            }
            return result;
        }, []).sort((a, b) => Number(a.year) - Number(b.year))
    }, [rawData])

    return (
        <ClusteredColumnChart
            id="revenue-sum-column-chart"
            height={600}
            data={data}
            categoryField="year"
            seriesMap={[{field: 'sum', name: 'Toplam'}]}
        />
    )
}

export default ColumnChartHOC
