import Box from "@mui/material/Box";
import TimeTrackingDataCreateDialog from "./TimeTrackingDataCreateDialog";
import Button from "@mui/material/Button";
import {listPageActionTypes} from "../list-page-hoc/constants";
import ListSearch from "../ListSearch";
import {useContext} from "react";
import {ListPageContext} from "../list-page-hoc/context";
import ListAutocompleteFilter from "../ListAutocompleteFilter";
import {userLabelFormat} from "../../helpers/user/format";

const ListActionButtons = function () {
    const {state, dispatch} = useContext(ListPageContext);

    return (
        <Box sx={{width: '100%', display: 'flex'}}>
            <Box sx={{flexGrow: 1}}/>
            <Box sx={{display: 'flex'}}>
                <Button
                    sx={{textTransform: 'none'}}
                    onClick={() => {
                        dispatch({type: listPageActionTypes.SET_FILTERS, filters: {}});
                        dispatch({type: listPageActionTypes.SET_SEARCH_TEXT, searchText: ''});
                        dispatch({type: listPageActionTypes.SET_INPUT_VALUE, inputValues: {}});
                    }}
                >
                    Temizle
                </Button>
                <ListSearch />
                <ListAutocompleteFilter
                    label="Operatör"
                    filterField="operator"
                    inputValueField="operatorInput"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={state.data.users.map(user => ({label: userLabelFormat({user}), id: user.id}))}
                />
                <ListAutocompleteFilter
                    label="Tezgah"
                    filterField="equipment"
                    inputValueField="equipmentInput"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={state.data.equipments.map(equipment => ({label: equipment.code, id: equipment.id}))}
                />
                <TimeTrackingDataCreateDialog/>
            </Box>
        </Box>
    )
}

export default ListActionButtons;
