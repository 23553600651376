import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import CustomerTable from "../customer-table";

const CustomerTableHOC = function () {
    const {state} = useContext(DetailPageContext);
    return (
        <CustomerTable
            customer={state.data.customer}
            product={state.data.workOrder.product}
        />
    )
}

export default CustomerTableHOC;
