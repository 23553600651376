import Api from "./index";
import {transformObjectKeysToCamelCase} from "../utils/transform";

class UserApi extends Api {
    constructor() {
        super({baseUrl: 'users'});
    }
    me(context) {
        return this.client.get(`${this.baseUrl}/me/`, {
            headers: {
                'Authorization': `Bearer ${context.authorization.access}`
            }
        }).then(response => transformObjectKeysToCamelCase(response.data))
    }
}

export default new UserApi();
