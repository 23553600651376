import {useState, useEffect, useCallback, useContext} from "react";
import BaseDataTable from "../datatable";
import CustomerApi from "../../api/customer";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {ContactType} from "../../helpers/customer/enums";
import {ListPageContext} from "../list-page-hoc/context";
import {listPageActionTypes} from "../list-page-hoc/constants";
import {useListApi} from "../list-page-hoc/hooks";

function DataTable() {
    const {state, dispatch} = useContext(ListPageContext);
    let navigate = useNavigate();
    const {data: customers} = useListApi(CustomerApi);

    const handleSortModelChange = sortModel => {
        dispatch({type: listPageActionTypes.SET_SORT_MODEL, sortModel})
    };

    const handleFilterModelChange = useCallback(filterModel => {
    }, []);

    const onPageChange = (page) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                page: page,
            }
        })
    }

    const onPageSizeChange = (pageSize) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                pageSize,
            }
        })
    }


    return (
        <BaseDataTable
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            sortModel={state.sortModel}
            loading={state.loading}
            rowCount={customers.count}
            page={state.pagination.page}
            pageSize={state.pagination.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={customers.results}

            columns={[
                {
                    field: 'name',
                    headerName: 'Firma Adı',
                    flex: 2,
                },
                {
                    field: 'technicalContact',
                    headerName: 'Teknik İletişim',
                    sortable: false,
                    filterable: false,
                    flex: 1,
                    renderCell: params => {
                        const contact = params.row.contacts.find(({contactType}) => contactType === ContactType.TECHNICAL)
                        if (!contact) {
                            return <Box/>
                        }
                        return (
                            <Box>
                                <Typography>{contact.name}</Typography>
                                <Typography>{contact.email}</Typography>
                            </Box>
                        )
                    }
                },
                {
                    field: 'financialContact',
                    headerName: 'Finansal İletişim',
                    sortable: false,
                    filterable: false,
                    flex: 1,
                    renderCell: params => {
                        const contact = params.row.contacts.find(({contactType}) => contactType === ContactType.FINANCIAL)
                        if (!contact) {
                            return <Box/>
                        }
                        return (
                            <Box>
                                <Typography>{contact.name}</Typography>
                                <Typography>{contact.email}</Typography>
                            </Box>
                        )
                    }
                },
                {
                    field: 'id',
                    headerName: 'İşlem',
                    sortable: false,
                    filterable: false,
                    hideable: false,
                    renderCell: (params) => {
                        return (
                            <IconButton onClick={() => {
                                navigate(`/musteriler/${params.value}`, {replace: false})
                            }}>
                                <SearchIcon/>
                            </IconButton>
                        )
                    }
                }
            ]}
        />
    )
}

export default DataTable;
