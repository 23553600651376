import {hasPermission} from "../../utils/permissions";
import WorkOrderApi from "../../api/work-order";
import {statusTranslate} from "../../helpers/work-order/status";
import StatusUpdateButton from "./StatusUpdateButton";

const CancelButton = function ({workOrder, setWorkOrder}) {
    return (
        <StatusUpdateButton
            workOrder={workOrder}
            setWorkOrder={setWorkOrder}
            api={WorkOrderApi}
            process={'canceled'}
            label={statusTranslate.CANCELED.statusUpdateButtonLabel}
            color={'warning'}
        />
    )
}

export default hasPermission(CancelButton, {permissions: ['can_cancel_work_order']})
