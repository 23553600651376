import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {listPageActionTypes} from "../list-page-hoc/constants";
import {statusTranslate} from "../../helpers/order/status";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {useContext} from "react";
import {ListPageContext} from "../list-page-hoc/context";

const StatusFilter = function ({sx}) {
    const {state, dispatch} = useContext(ListPageContext);

    return (
        <FormControl sx={sx} size="small">
            <InputLabel id="status-select-label">Durum</InputLabel>
            <Select
                multiple
                labelId="status-select-label"
                label="Durum"
                value={state.filters.status}
                onChange={(event) => {
                    dispatch({
                        type: listPageActionTypes.SET_FILTERS,
                        filters: {
                            ...state.filters,
                            status: event.target.value
                        }
                    })
                }}
            >
                {Object.entries(statusTranslate).filter(([, value]) => value.chipLabel).map(([key, value]) => {
                    return <MenuItem key={key} value={key}>{value.chipLabel}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}

export default StatusFilter;
