import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Formik} from "formik";
import * as yup from "yup";
import {useState, useEffect, useContext} from "react";
import TextField from "../forms/TextField";
import SelectField from "../forms/SelectField";
import ProductApi from '../../api/product';
import {AuthContext} from "../../contexts/AuthContext";

const DiscountFormDialog = function (
    {
        initialValues = {
            product: '',
            amount: ''
        },
        onSubmit = Promise.resolve(true),
        title,
        label,
        sx={}
    }
) {
    const authorization = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        ProductApi.getAll({authorization}).then(products => {
            setProducts(products);
        })
    }, [authorization]);

    const handleClose = () => !loading && setOpen(false);

    return (
        <>
            <Button
                variant={'outlined'}
                sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
                onClick={() => setOpen(true)}
            >{label}</Button>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    // actions.setSubmitting(false);
                    setLoading(true);
                    onSubmit(values).finally(() => {
                        setLoading(false);
                        setOpen(false);
                    });
                }}
            >
                {formik => (
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContent>
                            <Stack sx={{py: 1}} spacing={1}>
                                <SelectField
                                    name={'product'}
                                    label={'Alan'}
                                    values={products}
                                    itemLabelField={'name'}
                                />
                                <TextField name={'amount'} label={'Miktar'}/>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                onClick={() => formik.submitForm()}
                            >Kaydet</Button>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                color={"error"}
                                onClick={handleClose}
                            >İptal</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        </>
    )
}

const validationSchema = yup.object({
    product: yup.string().required('Lütfen Ürün/Hizmet Seçiniz'),
    amount: yup.number().moreThan(0),
});

export default DiscountFormDialog;
