import Box from "@mui/material/Box";
import ActionCreateDialog from "./ActionCreateDialog";
import StatusFilter from "./StatusFilter";
import ListSearch from "../ListSearch";
import {listPageActionTypes} from "../list-page-hoc/constants";
import Button from "@mui/material/Button";
import {useContext} from "react";
import {ListPageContext} from "../list-page-hoc/context";

const ListActionButtons = function () {
    const {dispatch} = useContext(ListPageContext);

    return (
        <Box sx={{width: '100%', display: 'flex'}}>
            <Box sx={{flexGrow: 1}}/>
            <Box sx={{display: 'flex'}}>
                <Button
                    sx={{textTransform: 'none'}}
                    onClick={() => {
                        dispatch({type: listPageActionTypes.SET_FILTERS, filters: {status: []}});
                        dispatch({type: listPageActionTypes.SET_SEARCH_TEXT, searchText: ''});
                    }}
                >
                    Temizle
                </Button>
                <ListSearch />
                <StatusFilter/>
                <ActionCreateDialog/>
            </Box>
        </Box>
    )
}

export default ListActionButtons;
