import {createContext, useState} from 'react';
import sessionManager from '../utils/session';
import * as api from "../api/auth";
import * as jose from 'jose';
import {useSnackbar} from "notistack";
import throtle from 'lodash.throttle';

const sessionKey = 'auth';

const initialState = sessionManager.getSessionData(sessionKey) || {
    isAuthenticated: false
}
const AuthContext = createContext(null);

function AuthProvider({children}) {
    let [auth, setAuth] = useState(initialState);
    const {enqueueSnackbar} = useSnackbar();

    let signIn = (credentials) => {
        return api.signIn(credentials).then((tokens) => {
            const {role, permissions} = jose.decodeJwt(tokens.access)
            const authorization = {
                isAuthenticated: true,
                ...tokens,
                role,
                permissions,
            }
            sessionManager.setSessionData(sessionKey, authorization);
            setAuth(authorization);
        });
    };

    const throwErrorInSnackBar = throtle((message) => {
        enqueueSnackbar(message || 'Yetkisiz erişim!', {
            variant: message ? "warning" : "error",
        });
    }, 10000, {trailing: false});

    let signOut = ({isForced, message} = {}) => {
        return api.signOut().then(() => {
            if (isForced) {
                throwErrorInSnackBar(message);
            }
            const authorization = {isAuthenticated: false}
            sessionManager.endSession();
            setAuth(authorization);
        })
    };

    let refreshToken = () => {
        return api.refreshToken({
            refresh: auth.refresh
        }).then((tokens) => {
            const {role, permissions} = jose.decodeJwt(tokens.access)
            const authorization = {
                isAuthenticated: true,
                ...tokens,
                role,
                permissions,
            }
            sessionManager.setSessionData(sessionKey, authorization);
            setAuth(authorization);
        })
    };

    const hasPermission = ({roles, permissions}) => {
        if (roles && !roles.includes(auth.role)) {
            return false;
        }
        if (permissions && permissions.some(permission => !auth.permissions.includes(permission))) {
            return false;
        }
        return true;
    };

    const hasPermissionAsync = async ({APIClient, roles, permissions, objectid}) => {
        const {hasPermission} = await APIClient.hasPermission(
            {authorization: auth},
            {
                roles: JSON.stringify(roles),
                permissions: JSON.stringify(permissions),
                objectid
            }
        );
        return hasPermission;
    };

    let value = {...auth, signIn, signOut, refreshToken, hasPermission, hasPermissionAsync};

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export {AuthContext};
export default AuthProvider;
