import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import DetailPageHOC from "../components/detail-page-hoc";
import DetailActionButtons from "../components/actions/DetailActionButtons";
import DetailTable from "../components/actions/DetailTable";
import StatusHistoryHOC from "../components/actions/StatusHistoryHOC";
import ActionApi from "../api/action";
import ActionComments from "../components/actions/ActionComments";

const ActionDetail = function () {

    return (
        <DetailPageHOC api={ActionApi}>
            <DetailActionButtons/>
            <Grid container spacing={2} marginTop={3}>
                <Grid item md={8}>
                    <Stack spacing={2}>
                        <DetailTable/>
                        <ActionComments/>
                    </Stack>
                </Grid>
                <Grid item md={4}>
                    <StatusHistoryHOC/>
                </Grid>
            </Grid>
        </DetailPageHOC>
    )
}

export default ActionDetail;
