import CssBaseline from '@mui/material/CssBaseline';
import Routes from "./Routes";
import AuthProvider from "./contexts/AuthContext";
import ThemeProvider from "./contexts/ThemeContext";
import {SnackbarProvider} from 'notistack';
import './App.css';

function App() {
    return (
        <SnackbarProvider>
            <ThemeProvider>
                <CssBaseline/>
                <AuthProvider>
                    <Routes/>
                </AuthProvider>
            </ThemeProvider>
        </SnackbarProvider>
    );
}

export default App;
