import {useContext} from "react";
import {DataGridPro, trTR} from "@mui/x-data-grid-pro";
import {monthNames} from "../../../utils/date";
import PriceTextFormat from "../../PriceTextFormat";
import Box from "@mui/material/Box";
import {RevenueContext} from "./revenue-hoc/context";

const MonthlyRevenue = function () {
    const {state} = useContext(RevenueContext);

    return (
        <Box sx={{width: '100%'}}>
            <DataGridPro
                autoHeight
                loading={state.loading}
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                columns={[
                    {field: 'year', headerName: 'Yıl'},
                    ...monthNames.map(
                        (monthName, index) => ({
                            field: `${index + 1}`,
                            flex: 1,
                            headerName: monthName,
                            sortable: false,
                            filterable: false,
                            pinnable: false,
                            hideable: false,
                            renderCell: (params) => {
                                if (!params.value) return '-'
                                let value = params.value;
                                if (state.currency !== 'TL') {
                                    const exchangeRate = state.exchangeRates.find(
                                        ({
                                             countingYear,
                                             countingMonth,
                                             currency
                                         }) => {
                                            return countingYear === params.row.year &&
                                                String(countingMonth) === params.field &&
                                                currency === state.currency
                                        })
                                    if (!exchangeRate) {
                                        return 'Parite Eksik'
                                    }
                                    value = value / parseFloat(exchangeRate.rate)
                                }
                                return (
                                    <PriceTextFormat
                                        prefix={state.currency}
                                        value={value.toFixed(2)}
                                    />
                                )
                            }
                        })
                    ),
                ]}
                initialState={{
                    pinnedColumns: {left: ['year']}
                }}
                rows={state.loading
                    ? []
                    : state.data.reduce((result, revenue) => {
                        const row = result.find(row => row.year === revenue.countingYear)
                        if (row) {
                            row[revenue.countingMonth] = (row[revenue.countingMonth] || 0) + Number(revenue.revenue)
                        } else {
                            result.push({
                                id: revenue.countingYear,
                                year: revenue.countingYear,
                                [revenue.countingMonth]: Number(revenue.revenue),
                            })
                        }
                        return result;
                    }, []).sort((a, b) => a.year - b.year)}
            />
        </Box>
    )
}

export default MonthlyRevenue;
