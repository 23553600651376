import {Card, CardHeader} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Skeleton} from "@mui/lab";
import CardContent from "@mui/material/CardContent";
import React, {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {ListPageContext} from "../../list-page-hoc/context";
import GenericRevenueApi from "../../../api/generic-revenue";
import {AuthContext} from "../../../contexts/AuthContext";
import {categoryTranslate} from "../../../helpers/revenue/category";
import PriceTextFormat from "../../PriceTextFormat";

const TotalRevenue = function () {
    const authorization = useContext(AuthContext);
    const {state} = useContext(ListPageContext);
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])

    useEffect(() => {
        let abort = false;
        GenericRevenueApi.sum({authorization}, state.filters).then((data) => {
            if (!abort) {
                setData(data)
                setLoading(false)
            }
        })
        return () => {
            abort = true
        }
    }, [authorization, state.filters]);

    return (
        <Grid container spacing={2} sx={{mt: 2}}>
            {Object.keys(categoryTranslate).map(category => {
                const total = category === 'ALL'
                    ? data.reduce((result, item) => result + Number(item.total), 0)
                    : data.find(item => item.category === category)?.total || 0
                return (
                    <Grid item xs={12} md={4} lg={3}>
                        <Card sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <CardHeader
                                title={
                                    <Typography component="div" variant={"subtitle2"}>
                                        {categoryTranslate[category].label}
                                    </Typography>
                                }
                                sx={{py: 1, px: 2}}
                            />
                            <CardContent sx={{flex: 1, display: 'flex', alignItems: 'end', p: 0, px: 2}}>
                                <Typography component="div" variant={"h4"} fontWeight={100}>
                                    {loading
                                        ? <Skeleton/>
                                        : <PriceTextFormat value={Number(total).toFixed(2)} prefix="₺"/>
                                    }
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default TotalRevenue;