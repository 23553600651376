import NumberFormat from "react-number-format";

const PriceTextFormat = function ({value='', prefix=''}) {
    return (
        <NumberFormat
            thousandSeparator={"."}
            isNumericString
            decimalSeparator={","}
            value={value}
            displayType={"text"}
            suffix={` ${prefix}`}
        />
    );
};

export default PriceTextFormat;
