import {useCallback, useContext} from "react";
import {ListPageContext} from "../list-page-hoc/context";
import {useNavigate} from "react-router-dom";
import WorkOrderApi from "../../api/work-order";
import {listPageActionTypes} from "../list-page-hoc/constants";
import BaseDataTable from "../datatable";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import CopyToClipboardButton from "../CopyToClipboardButton";
import {statusTranslate} from "../../helpers/work-order/status";
import Chip from "@mui/material/Chip";
import DoneIcon from '@mui/icons-material/Done';
import ProcessFormDialog from "./ProcessFormDialog";
import addMonths from "date-fns/addMonths";
import {transformDateToLocalString} from "../../utils/transform";
import Tooltip from "@mui/material/Tooltip";
import {userLabelFormat} from "../../helpers/user/format";
import {useListApi} from "../list-page-hoc/hooks";

const ProcessDataTable = function ({processFields}) {
    const {state, dispatch} = useContext(ListPageContext);
    let navigate = useNavigate();
    const {data: workOrders} = useListApi(WorkOrderApi);

    const handleSortModelChange = sortModel => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({type: listPageActionTypes.SET_SORT_MODEL, sortModel})
    };

    const handleFilterModelChange = useCallback(filterModel => {
        console.log(filterModel);
    }, []);

    const onPageChange = (page) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                page: page,
            }
        })
    }

    const onPageSizeChange = (pageSize) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                pageSize,
            }
        })
    }

    return (
        <BaseDataTable
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            sortModel={state.sortModel}
            loading={state.loading}
            rowCount={workOrders.count}
            page={state.pagination.page}
            pageSize={state.pagination.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={workOrders.results}
            columns={[
                {
                    field: 'workOrderId',
                    headerName: 'İş Emri No',
                    sortable: false,
                    filterable: false,
                    renderCell: (params) => {
                        return (
                            <Box>
                                <Link
                                    component={'button'}
                                    variant={'body2'}
                                    onClick={() => {
                                        navigate(`/is-emirleri/${params.id}`, {replace: false})
                                    }}
                                >{params.value}</Link>
                                <CopyToClipboardButton
                                    sx={{
                                        px: 1,
                                        opacity: 0.1,
                                        transition: 'opacity ease-out 300ms',
                                        '&:hover': {
                                            opacity: 1
                                        }
                                    }}
                                    copyText={params.value}
                                    message={'İş emri numarası kopyalandı'}
                                />
                            </Box>
                        )
                    }
                },
                {
                    field: 'product',
                    filterable: false,
                    headerName: 'Ürün/Hizmet',
                },
                {
                    field: 'companyProductCode',
                    headerName: 'Kestaş Ürün Kodu',
                    sortable: false,
                    filterable: false,
                },
                {
                    field: 'deadline',
                    headerName: 'Termin',
                    filterable: false,
                    sortable: true,
                    width: 120,
                    renderCell: (params) => {
                        if (!params.value) return (<></>);
                        const isCompletedOrFailed = (
                            params.row.status === statusTranslate.COMPLETED.enum
                            || params.row.status === statusTranslate.DAMAGED.enum
                            || params.row.status === statusTranslate.CANCELED.enum
                        )
                        const deadline = new Date(params.value);
                        const currentDate = new Date();
                        const oneMonthLaterDate = addMonths(currentDate, 1);
                        return (
                            <Chip
                                label={transformDateToLocalString(params.value)}
                                color={
                                    isCompletedOrFailed
                                        ? 'statusCompleted'
                                        : deadline < currentDate
                                            ? 'error'
                                            : oneMonthLaterDate > deadline
                                                ? 'warning' : 'success'
                                }
                            />
                        );
                    }
                },
                {
                    field: 'status',
                    headerName: 'Durum',
                    filterable: false,
                    sortable: false,
                    width: 120,
                    type: 'singleSelect',
                    valueOptions: Object.values(statusTranslate).map(({chipLabel}) => chipLabel),
                    valueFormatter: ({value}) => statusTranslate[value].chipLabel,
                    renderCell: (params) => {
                        const isStatusCompleted = params.value === statusTranslate.COMPLETED.enum;
                        const isStatusDamaged = params.value === statusTranslate.DAMAGED.enum;
                        const isStatusCanceled = params.value === statusTranslate.CANCELED.enum;
                        return (
                            <Chip
                                label={statusTranslate[params.value].chipLabel}
                                color={
                                    isStatusCompleted
                                        ? `statusCompleted`
                                        : isStatusCanceled
                                            ? 'warning'
                                            : isStatusDamaged
                                                ? 'error' : 'primary'
                                }
                            />
                        );
                    }
                },
                ...processFields.map(field => ({
                    field: `processes.${field.name}`,
                    headerName: field.label,
                    valueFormatter: (params) => {
                        const {operations} = params.api.getRow(params.id)
                        return operations.find(operation => operation.category.id === field.name)?.isCompleted ? 'X' : '';
                    },
                    renderCell: (params) => {
                        const {operations} = params.api.getRow(params.id)
                        const operation = operations.find(operation => operation.category.id === field.name)
                        if (!operation?.isCompleted) return
                        if (!operation.completedBy) {
                            return <DoneIcon/>
                        }
                        return (
                            <Tooltip title={`${userLabelFormat({
                                user: operation.completedBy,
                                type: 'fullName'
                            })} - ${transformDateToLocalString(operation.completedAt, false)}`}>
                                <DoneIcon/>
                            </Tooltip>
                        );
                    }
                })),
                {
                    field: 'actions',
                    headerName: '',
                    pinnable: true,
                    disableExport: true,
                    renderCell: (params) => {
                        return (
                            <ProcessFormDialog
                                row={params.row}
                                updateRows={params.api.updateRows}
                                processFields={processFields}
                            />
                        )
                    }
                }
            ]}
        />
    )
}

export default ProcessDataTable;
