import WorkOrderApi from "../api/work-order";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import WorkOrderNotes from "../components/work-order/WorkOrderNotes";
import DetailPageHOC from "../components/detail-page-hoc";
import DetailActionButtons from "../components/work-order/DetailActionButtons";
import DetailTable from "../components/work-order/DetailTable";
import OperationsTable from "../components/work-order/OperationsTable";
import StatusHistoryHOC from "../components/work-order/StatusHistoryHOC";
import CustomerTableHOC from "../components/work-order/CustomerTableHOC";
import InvoicesTable from "../components/work-order/InvoicesTable";
import ProcessTable from "../components/work-order/ProcessTable";

const WorkOrderDetail = function () {
    return (
        <DetailPageHOC api={WorkOrderApi}>
            <DetailActionButtons/>
            <Grid container spacing={2} marginTop={3}>
                <Grid item md={8} xs={12}>
                    <Stack spacing={2}>
                        <DetailTable/>
                        <ProcessTable/>
                        <OperationsTable/>
                        <InvoicesTable/>
                    </Stack>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Stack spacing={2}>
                        <CustomerTableHOC/>
                        <StatusHistoryHOC/>
                        <WorkOrderNotes/>
                    </Stack>
                </Grid>
            </Grid>
        </DetailPageHOC>
    )
}

export default WorkOrderDetail;
