import React, {useState, useEffect, useContext} from 'react';
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import NotificationsApi from '../../api/notifications';
import {AuthContext} from "../../contexts/AuthContext";
import {categoryTranslate} from "../../helpers/notification/category";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {useLocation, useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {transformDateToLocalString} from "../../utils/transform";
import usePushNotifications from './usePushNotifications';
import Divider from "@mui/material/Divider";

const PAGE_SIZE = 10
const NotificationButton = function () {
    const navigate = useNavigate();
    const location = useLocation();
    const authorization = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const open = Boolean(anchorEl);
    const {
        pushNotificationSupported,
        loading: pushNotificationLoading,
        subscribeToPushNotification,
        userSubscription,
    } = usePushNotifications(authorization);

    useEffect(() => {
        NotificationsApi
            .getAll({authorization}, [], {}, {page: 0, pageSize: PAGE_SIZE})
            .then(response => {
                setNotifications(response.results)
                setHasMore(Boolean(response.next));
            });
    }, [authorization, location.hash]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = async () => {
        NotificationsApi.updateReads({authorization}, PAGE_SIZE).then(response => {
            setNotifications(response.results)
            setHasMore(Boolean(response.next));
        });
        setAnchorEl(null);
    };

    const loadMore = (event) => {
        event.stopPropagation();
        NotificationsApi
            .getAll({authorization}, [], {}, {page, pageSize: 10})
            .then(response => {
                setNotifications([...notifications, ...response.results])
                setHasMore(Boolean(response.next));
                setPage(page + 1)
            });
    }

    const handleNotificationClick = async (notification) => {
        const {linkTemplate} = categoryTranslate[notification.category]
        await NotificationsApi.updateRead({authorization}, notification.id)
        setAnchorEl(null);
        navigate(linkTemplate(notification), {replace: false})
    }
    return (
        <React.Fragment>
            <Tooltip title="Bildirimler">
                <IconButton
                    onClick={handleClick}
                    size="large"
                    color="inherit"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Badge
                        badgeContent={notifications.filter(notification => !notification.wasRead).length}
                        color="error"
                    >
                        <NotificationsIcon/>
                    </Badge>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="notification-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    style: {
                        maxHeight: 250,
                        width: 300,
                    },
                    sx: {
                        overflow: 'auto',
                        maxHeight: 300,
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                {pushNotificationSupported && !pushNotificationLoading && !userSubscription && (
                    <Box sx={{textAlign: 'center', cursor: 'pointer', p: 1}}>
                        <Typography
                            variant="caption"
                            onClick={subscribeToPushNotification}
                        >Masaüstü bildirimlerini aç</Typography>
                    </Box>
                )}
                {pushNotificationSupported && !pushNotificationLoading && !userSubscription && (
                    <Divider/>
                )}
                {notifications.length === 0 && (
                    <Box sx={{textAlign: 'center', p: 3}}>
                        <Typography
                            variant="caption"
                        >Hiç bildiriminiz yok</Typography>
                    </Box>
                )}
                {notifications.map(notification => {
                    const {icon: Icon, notificationTemplate} = categoryTranslate[notification.category]
                    return (
                        <MenuItem
                            key={notification.id}
                            onClick={() => handleNotificationClick(notification)}
                            component={Paper}
                            sx={{
                                m: 1,
                                textWrap: 'wrap',
                                opacity: notification.wasRead ? 0.5 : 1
                            }}
                        >
                            <ListItemIcon>
                                <Icon fontSize="small"/>
                            </ListItemIcon>
                            <Stack spacing={1}>
                                <Typography
                                    variant="body1"
                                    paragraph
                                >{notificationTemplate(notification)}</Typography>
                                <Typography
                                    variant="caption"
                                >{transformDateToLocalString(notification.createdAt, false)}</Typography>
                            </Stack>
                        </MenuItem>
                    )
                })}
                {hasMore && (
                    <Box sx={{textAlign: 'center', cursor: 'pointer'}}>
                        <Typography
                            variant="caption"
                            onClick={loadMore}
                        >Daha fazla</Typography>
                    </Box>
                )}
            </Menu>
        </React.Fragment>
    );
}

export default NotificationButton;
