export const statusTranslate = {
    ACTIVE: {
        chipLabel: 'Fatura Kesilecek',
        statusUpdateButtonLabel: 'Fatura Kesildi',
        status: 'Fatura emri oluşturuldu',
        enum: 'ACTIVE'
    },
    INVOICED: {
        chipLabel: 'Fatura Kesildi',
        status: 'Fatura kesildi',
        enum: 'INVOICED'
    },
    CANCELED: {
        chipLabel: 'İptal Edildi',
        statusUpdateButtonLabel: 'İptal Et',
        status: 'İptal edildi',
        enum: 'CANCELED',
    },
}
