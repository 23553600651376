import {listPageActionTypes} from "./list-page-hoc/constants";
import {useContext} from "react";
import {ListPageContext} from "./list-page-hoc/context";
import Button from "@mui/material/Button";

const ListClearFiltersButton = function () {
    const {state, dispatch} = useContext(ListPageContext);
    console.log({state});
    return (
        <Button
            sx={{textTransform: 'none'}}
            onClick={() => {
                dispatch({type: listPageActionTypes.SET_FILTERS, filters: {status: []}});
                dispatch({type: listPageActionTypes.SET_SEARCH_TEXT, searchText: ''});
            }}
        >
            Temizle
        </Button>
    )
}

export default ListClearFiltersButton;
