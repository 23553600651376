import {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {AuthContext} from "../../contexts/AuthContext";
import {ListPageContext} from "./context";
import {listPageActionTypes} from "./constants";

const useListApi = function (api, method = 'getAll') {
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const authorization = useContext(AuthContext);
    const {state, dispatch} = useContext(ListPageContext);
    const [data, setData] = useState({count: 0, results: []});
    const [error, setError] = useState();

    useEffect(() => {
        let aborted = false;
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        api[method]({authorization}, state.sortModel, state.filters, state.pagination)
            .then(response => {
                if (!aborted) {
                    setData(response);
                    dispatch({type: listPageActionTypes.SET_LOADING, loading: false});
                }
            })
            .catch(err => {
                if (!aborted) {
                    if (err?.response?.status === 403) {
                        navigate('/oturumu-guncelle', {replace: false})
                        return
                    }
                    setError(err);
                    enqueueSnackbar('Hatalı işlem', {
                        variant: "error"
                    });
                }
            })

        return () => {
            aborted = true;
        }
    }, [api, authorization, dispatch, enqueueSnackbar, method, navigate, state.filters, state.pagination, state.sortModel, state.version]);

    return {data, error};
};

export {
    useListApi
};
