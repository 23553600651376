import Box from "@mui/material/Box";
import ListPageProvider from "../../list-page-hoc/context";
import DataTable from "./DataTable";
import ListActionButtons from "./ListActionButtons";

const OperationTimeTrackingAnalysis = function () {
    return (
        <ListPageProvider
            sortModel={[]}
            storageKey={'operation-time-tracking-analysis'}
            filters={{status: [], product: 1}}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    flexGrow: 1,
                    height: 'calc(100vh - 128px)',
                    pb: 3
                }}
            >
                <ListActionButtons/>
                <DataTable/>
            </Box>
        </ListPageProvider>
    )
}

export default OperationTimeTrackingAnalysis
