import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {categoryTranslate} from "../../../helpers/revenue/category";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {useContext} from "react";
import {RevenueContext} from "./revenue-hoc/context";
import {currencies} from "../../../utils/currency";

const RevenueFilters = function () {
    const {state, dispatch} = useContext(RevenueContext);

    return (
        <Box sx={{display: 'flex'}}>
            <FormControl sx={{ml: 'auto', width: 200}} size="small">
                <InputLabel id="revenue-category-select-label">Kategori</InputLabel>
                <Select
                    labelId="revenue-category-select-label"
                    label="Kategori"
                    value={state.category}
                    onChange={(event) => {
                        dispatch({
                            type: 'SET_CATEGORY',
                            category: event.target.value
                        })
                    }}
                >
                    {Object.entries(categoryTranslate).map(([key, value]) => {
                        return <MenuItem key={key} value={key}>{value.label}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <FormControl sx={{ml: 1, width: 200}} size="small">
                <InputLabel id="revenue-currency-select-label">Para Birimi</InputLabel>
                <Select
                    labelId="revenue-currency-select-label"
                    label="Para Birimi"
                    value={state.currency}
                    onChange={(event) => {
                        dispatch({
                            type: 'SET_CURRENCY',
                            currency: event.target.value
                        })
                    }}
                >
                    {currencies.filter(({key}) => key !== 'GBP').map(({key, value}) => {
                        return <MenuItem key={key} value={key}>{value}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </Box>
    )
}

export default RevenueFilters;
