import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {analyzeChartTypes} from "./analyze-hoc/constants";
import AnalyzeHOC from "./analyze-hoc";
import AnalyzeContainerProvider from "./analyze-container-hoc/context";
import ActionButtons from "./ActionButtons";

const MonthlyWorkAnalysis = function () {
    return (
        <AnalyzeContainerProvider>
            <Box sx={{mt: 3, flex: '1 1 auto'}}>
                <ActionButtons/>
                <Stack direction={"column"} spacing={3}>
                    <AnalyzeHOC
                        storageKey={'analyze-aylik-adet-is-line-chart'}
                        slug={'aylik-adet-is'}
                        chartType={analyzeChartTypes.LINE_CHART}
                        sx={{width: '100%'}}
                    />
                    <AnalyzeHOC
                        storageKey={'analyze-aylik-fason-broslama-isciligi'}
                        slug={'aylik-fason-broslama-isciligi'}
                        chartType={analyzeChartTypes.LINE_CHART}
                        sx={{width: '100%'}}
                    />
                    <AnalyzeHOC
                        storageKey={'analyze-isleme-gore-aylik-toplam-sure'}
                        slug={'isleme-gore-aylik-toplam-sure'}
                        chartType={analyzeChartTypes.LINE_CHART}
                        sx={{width: '100%'}}
                    />
                </Stack>
            </Box>
        </AnalyzeContainerProvider>
    )
}

export default MonthlyWorkAnalysis;
