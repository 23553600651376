import * as yup from "yup";

const operations = [
    {
        name: "Tornalama",
        shortCode: "A"
    },
    {
        name: "Kaba Çap Taşlama",
        shortCode: "B"
    },
    {
        name: "Finiş Çap Taşlama",
        shortCode: "C"
    },
    {
        name: "Kaba Profil Taşlama",
        shortCode: "D"
    },
    {
        name: "Finiş Profil Taşlama",
        shortCode: "E"
    },
    {
        name: "Freze/Yüzey Temizliği",
        shortCode: "F"
    },
    {
        name: "Kaba Satıh Taşlama",
        shortCode: "G"
    },
    {
        name: "Kademe Taşlama",
        shortCode: "H"
    },
    {
        name: "Profil/Finiş Satıh Taşlama",
        shortCode: "J"
    },
    {
        name: "Broş Bileme",
        shortCode: "K"
    },
    {
        name: "Fason Broşlama İşlemi",
        shortCode: "L"
    },
    {
        name: "Tezgah Parça İmalatı",
        shortCode: "M"
    },
    {
        name: "Tezgah Parça Montajı",
        shortCode: "N"
    },
    {
        name: "Parça, Fikstür, Aparat İmalatı",
        shortCode: "O"
    },
    {
        name: "Yuvarlak Broş Sap Tamir + Bileme",
        shortCode: "P"
    },
    {
        name: "Yuvarlak Broş Sap Tamir + Bileme",
        shortCode: "R"
    },
    {
        name: "Yuvarlak Broş Tasih/Revizyon",
        shortCode: "S"
    },
    {
        name: "Lama Broş Tasih/Revizyon",
        shortCode: "T"
    }
]

const {
    operationShortCodes,
    operationsYupValidationObject,
    operationsFormikInitialValues
} = operations.reduce((result, operation) => {
    return {
        operationShortCodes: [
            ...result.operationShortCodes,
            operation.shortCode
        ],
        operationsYupValidationObject: {
            ...result.operationsYupValidationObject,
            [operation.shortCode]: yup
                .string()
        },
        operationsFormikInitialValues: {
            ...result.operationsFormikInitialValues,
            [operation.shortCode]: '00:00'
        }
    }
}, {operationShortCodes: [], operationsYupValidationObject: {}, operationsFormikInitialValues: {}});

const removeOperationFields = values => {
    return Object.entries(values).reduce((result, [key, value]) => {
        if (!key.includes(operationShortCodes)) {
            result[key] = value;
        }
        return result;
    }, {});
}

export {
    operations,
    operationsYupValidationObject,
    operationsFormikInitialValues,
    removeOperationFields
}
