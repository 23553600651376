import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";
import MuiDatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import trLocale from "date-fns/locale/tr";
import {useField} from "formik";

const localeMap = {
    tr: trLocale,
};

const maskMap = {
    tr: '__.__.____',
};

const DatePicker = function ({label, size = 'medium', disabled, ...props}) {
    const [field, meta, helpers] = useField(props);
    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={localeMap.tr}
        >
            <MuiDatePicker
                mask={maskMap.tr}
                name={field.name}
                value={field.value}
                onChange={(value) => helpers.setValue(value || '')}
                label={label}
                disabled={disabled}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            fullWidth
                            error={meta.error && meta.touched}
                            helperText={meta.error}
                            size={size}
                        />
                    )
                }}
            />
        </LocalizationProvider>
    )
}

export default DatePicker;
