import RevenueProvider from "./revenue-hoc/context";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MonthlyRevenue from "./MonthlyRevenue";
import RevenueFilters from "./RevenueFilters";
import ClusteredColumnChartHOC from "./ClusteredColumnChartHOC";
import ColumnChartHOC from "./ColumnChartHOC";
import TotalRevenueTable from "./TotalRevenueTable";

const Revenue = function () {
    return (
        <RevenueProvider>
            <Box sx={{mt: 3, flex: '1 1 auto'}}>
                <Stack direction={"column"} spacing={3}>
                    <RevenueFilters/>
                    <MonthlyRevenue/>
                    <RevenueFilters/>
                    <ClusteredColumnChartHOC/>
                    <RevenueFilters/>
                    <Stack direction="row" spacing={3}>
                        <ColumnChartHOC/>
                        <TotalRevenueTable/>
                    </Stack>
                </Stack>
            </Box>
        </RevenueProvider>
    )
}

export default Revenue;
