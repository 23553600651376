import Api from "./index";
import {transformObjectKeysToCamelCase, transformObjectKeysToSnakeCase} from "../utils/transform";

class CustomerApi extends Api {
    constructor() {
        super({baseUrl: 'customers'});
    }

    addContact(context, id, data) {
        return this.client.post(
            `${this.baseUrl}/${id}/add_contact/`,
            transformObjectKeysToSnakeCase(data),
            {
                headers: {
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            }
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    removeContact(context, id, data) {
        return this.client.post(
            `${this.baseUrl}/${id}/remove_contact/`,
            transformObjectKeysToSnakeCase(data),
            {
                headers: {
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            }
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    addDiscount(context, id, data) {
        return this.client.post(
            `${this.baseUrl}/${id}/add_discount/`,
            transformObjectKeysToSnakeCase(data),
            {
                headers: {
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            }
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    removeDiscount(context, id, data) {
        return this.client.post(
            `${this.baseUrl}/${id}/remove_discount/`,
            transformObjectKeysToSnakeCase(data),
            {
                headers: {
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            }
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    addBroachingPrice(context, id, data) {
        return this.client.post(
            `${this.baseUrl}/${id}/add_broaching_price/`,
            transformObjectKeysToSnakeCase(data),
            {
                headers: {
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            }
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    removeBroachingPrice(context, id, data) {
        return this.client.post(
            `${this.baseUrl}/${id}/remove_broaching_price/`,
            transformObjectKeysToSnakeCase(data),
            {
                headers: {
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            }
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    addBroochSharpeningPrice(context, id, data) {
        return this.client.post(
            `${this.baseUrl}/${id}/add_brooch_sharpening_price/`,
            transformObjectKeysToSnakeCase(data),
            {
                headers: {
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            }
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    removeBroochSharpeningPrice(context, id, data) {
        return this.client.post(
            `${this.baseUrl}/${id}/remove_brooch_sharpening_price/`,
            transformObjectKeysToSnakeCase(data),
            {
                headers: {
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            }
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    addBroachingOilPrice(context, id, data) {
        return this.client.post(
            `${this.baseUrl}/${id}/add_broaching_oil_price/`,
            transformObjectKeysToSnakeCase(data),
            {
                headers: {
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            }
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    removeBroachingOilPrice(context, id, data) {
        return this.client.post(
            `${this.baseUrl}/${id}/remove_broaching_oil_price/`,
            transformObjectKeysToSnakeCase(data),
            {
                headers: {
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            }
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    addProductPoolItem(context, id, data) {
        return this.client.post(
            `${this.baseUrl}/${id}/add_product_pool_item/`,
            transformObjectKeysToSnakeCase(data),
            {
                headers: {
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            }
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }

    updateProductPoolItem(context, id, data) {
        return this.client.post(
            `${this.baseUrl}/${id}/update_product_pool_item/`,
            transformObjectKeysToSnakeCase(data),
            {
                headers: {
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            }
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }
}

export default new CustomerApi();
