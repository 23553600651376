import Api from "./index";
import {transformObjectKeysToCamelCase, transformObjectKeysToSnakeCase} from "../utils/transform";

class OrderApi extends Api {
    constructor() {
        super({baseUrl: 'orders'});
    }

    updateStatus(context, id, data) {
        return this.client.put(`${this.baseUrl}/${id}/update_status/`, transformObjectKeysToSnakeCase(data), {
            headers: {
                'Authorization': `Bearer ${context.authorization.access}`
            }
        }).then(response => transformObjectKeysToCamelCase(response.data))
    }
}

export default new OrderApi();
