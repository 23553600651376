import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import {transformMinutesToTimeFormat} from "../../utils/transform";

const OperationsTable = function ({tableSize = 'medium'}) {
    const {state} = useContext(DetailPageContext);
    if (!state.data.product?.timeTrackingEnabled) return null;

    return (
        <TableContainer component={Paper}>
            <Table size={tableSize}>
                <TableHead>
                    <TableRow>
                        <TableCell>Operasyon</TableCell>
                        <TableCell>Tahmini Süre</TableCell>
                        <TableCell>Gerçekleşen Süre</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state.data.operations.filter(operation => operation.category.timeTrackingEnabled).map(operation => (
                        <TableRow key={operation.id}>
                            <TableCell>{operation.category.name} ({operation.category.shortCode})</TableCell>
                            <TableCell>{transformMinutesToTimeFormat(operation.estimatedOperationTime)}</TableCell>
                            <TableCell>{transformMinutesToTimeFormat(operation.actualOperationTime)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default OperationsTable;
