import Box from "@mui/material/Box";

function Logo({sx}) {
    return (
        <Box
            sx={{
                ...sx,
                textAlign: 'center', fontSize: 'h5.fontSize',
                fontWeight: '600',
                letterSpacing: 1
            }}
        >KESİS PORTAL</Box>
    )
}

export default Logo;
