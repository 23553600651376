import Grid from "@mui/material/Grid";
import DetailTable from "./DetailTable";
import OperationsTable from "./OperationsTable";
import Logo from '../../renkli_logo.png';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {QRCodeSVG} from "qrcode.react";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import {transformDateToLocalString} from "../../utils/transform";
import {useUser} from "../../hooks/useUser";

const PrintableDesign = function ({refToPrint}) {
    const {state} = useContext(DetailPageContext);
    const user = useUser();
    const data = state.data

    return (
        <Grid spacing={1} container ref={refToPrint} sx={{p: 1}}>
            <Grid item xs={12}>
                <Box sx={{display: 'flex', alignItems: 'center', px: 3, mt: 3}}>
                    <img src={Logo} alt={'Kestaş Logo'} height={50}/>
                    <Typography variant="h4" fontWeight="light" marginLeft={1}>
                        # {data.workOrderId}
                    </Typography>
                    <Typography sx={{mx: 'auto'}} variant={'h6'}>
                        İŞ EMRİ KAĞIDI
                    </Typography>
                    <Box>
                        <Typography variant={'subtitle1'}>
                            {data.customer.name}
                        </Typography>
                        <Typography variant={'subtitle1'}>
                            {data.product.name}
                        </Typography>
                    </Box>
                    <Box sx={{ml: 3}}>
                        <QRCodeSVG value={`${process.env.REACT_APP_BASE_URL}/is-emirleri/${state.id}`} size={64}/>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={6} md={8}>
                <DetailTable tableSize={'small'} showFinancialPart={false} showCreator/>
            </Grid>
            <Grid item xs={6} md={4}>
                <OperationsTable tableSize={'small'}/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={'body2'} align={'center'}>
                    Yazdırma tarihi: {transformDateToLocalString(new Date(), false, true)} Yazdıran: {user.firstName} {user.lastName}
                </Typography>
            </Grid>
        </Grid>
    )
}


export default PrintableDesign;
