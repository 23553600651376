import {useState, useEffect} from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import PriceFieldFormat from "./PriceFieldFormat";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import CopyToClipboardButton from "./CopyToClipboardButton";

const UnitPriceCalculation = function ({sx = {}}) {
    const [operationPrice, setOperationPrice] = useState(0.62);
    const [calibrationPrice, setCalibrationPrice] = useState(55);
    const [count, setCount] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [result, setResult] = useState(0.0);

    useEffect(() => {
        setResult(
            Math.round(
                (((operationPrice * count + calibrationPrice) / count) * (100 + discount) / 100) * 10000
            ) / 10000
        )
    }, [operationPrice, calibrationPrice, count, discount])
    return (
        <Stack spacing={2} sx={sx} flexGrow={1}>
            <Typography variant={"body2"}>Fason Broşlama Birim Fiyat Hesaplama Formülü</Typography>
            <Stack spacing={1}>
                <Stack spacing={1} direction={"row"}>
                    <TextField
                        fullWidth
                        size={"small"}
                        label={"Operasyon Ücreti"}
                        inputMode={"decimal"}
                        InputProps={{
                            inputComponent: PriceFieldFormat
                        }}
                        value={operationPrice}
                        onChange={event => setOperationPrice(Number(event.target.value))}
                        helperText={`${(new Date()).getFullYear()} Standart Operasyon Fiyatı 0,62€'dur`}
                    />
                    <TextField
                        fullWidth
                        size={"small"}
                        label={"Ayar Ücreti"}
                        inputMode={"decimal"}
                        InputProps={{
                            inputComponent: PriceFieldFormat
                        }}
                        value={calibrationPrice}
                        onChange={event => setCalibrationPrice(Number(event.target.value))}
                        helperText={`${(new Date()).getFullYear()} Standart Ayar Fiyatı 55€'dur`}
                    />
                </Stack>
                <TextField
                    fullWidth
                    size={"small"}
                    label={"İskonto/İlave"}
                    inputMode={"numeric"}
                    value={discount}
                    onChange={event => {
                        setDiscount(
                            event.target.value === '0-' || event.target.value === '-'
                                ? '-'
                                : event.target.value.indexOf('Na') > -1
                                    ? 0
                                    : Number(event.target.value)
                        )
                    }}
                    helperText={'İlave için pozitif, iskonto için negatif değer giriniz. Örn %20 ilave için 20 yazınız.'}
                />
                <Stack spacing={1} direction={"row"}>
                    <TextField
                        fullWidth
                        size={"small"}
                        label={"Adet"}
                        inputMode={"numeric"}
                        value={count}
                        onChange={event => setCount(Number(event.target.value))}
                    />
                    <TextField
                        fullWidth
                        size={"small"}
                        disabled
                        label={"Parça Başına Fiyat"}
                        inputMode={"decimal"}
                        value={result.toFixed(2)}
                        InputProps={{
                            inputComponent: PriceFieldFormat,
                            endAdornment: <InputAdornment position="end">
                                <CopyToClipboardButton
                                    sx={{
                                        px: 1,
                                        opacity: 0.1,
                                        transition: 'opacity ease-out 300ms',
                                        '&:hover': {
                                            opacity: 1
                                        }
                                    }}
                                    copyText={String(result.toFixed(2)).replace('.', ',')}
                                    message={'Parça başına fiyat kopyalandı'}
                                />
                            </InputAdornment>
                        }}
                    />
                </Stack>
                <Box>
                    <Typography variant={"body2"}>Boyu 500 mm'den az olan broşlar için %20 iskonto
                        uygulanır.</Typography>
                    <Typography variant={"body2"}>Ø(40-60) arası broşlar için %20 ilave edilir.</Typography>
                    <Typography variant={"body2"}>Ø(61-80) arası broşlar için %40 ilave edilir.</Typography>
                    <Typography variant={"body2"}>Ø(81-100) arası broşlar için %60 ilave edilir.</Typography>
                </Box>
            </Stack>
        </Stack>
    )
}

export default UnitPriceCalculation;
