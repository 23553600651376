export const ContactType = {
    TECHNICAL: 'TECHNICAL',
    FINANCIAL: 'FINANCIAL'
}

export const PriceType = {
    END_USER: 'END_USER',
    DISTRIBUTOR: 'DISTRIBUTOR'
}

export const Unit = {
    KG: 'KG',
    PIECE: 'PIECE'
}

export const ProductStatus = {
    CREATED: 'CREATED',
    CANCELED: 'CANCELED',
    VERSION_UPDATED: 'VERSION_UPDATED',
    NOT_FOUND: 'NOT_FOUND',
}