import {useState, useEffect} from "react";
import SharpeningDataTable from "../components/price-list/SharpeningDataTable";
import BroachingDataTable from '../components/price-list/BroachingDataTable';
import BroachingOilDataTable from '../components/price-list/BroachingOilDataTable';
import Layout from "../components/layout";
import ListPageProvider from "../components/list-page-hoc/context";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../components/TabPanel";
import {useLocation, useNavigate} from "react-router-dom";
import ListActionButtons from "../components/price-list/ListActionButtons";
import Box from "@mui/material/Box";

const priceLists = [
    {
        label: 'Bileme Fiyat Listesi',
        slug: '#bileme-fiyat-listesi',
        storageKey: 'broaching-price-list',
        sortModel: [{field: "customer", sort: "desc"}],
        DataTable: SharpeningDataTable
    },
    {
        label: 'Fason Broşlama Fiyat Listesi',
        slug: '#fason-broslama-fiyat-listesi',
        storageKey: 'brooch-sharpening-price-list',
        sortModel: [{field: "customer", sort: "desc"}],
        DataTable: BroachingDataTable
    },
    {
        label: 'Broşlama Yağı Fiyat Listesi',
        slug: '#broslama-yagi-fiyat-listesi',
        storageKey: 'broaching-oil-price-list',
        sortModel: [{field: "customer", sort: "desc"}],
        DataTable: BroachingOilDataTable
    },
]

const PriceList = function () {
    const navigate = useNavigate();
    const location = useLocation();
    const [tab, setTab] = useState(0);
    const changeTab = (event, value) => {
        navigate(`/fiyat-listeleri${priceLists[value].slug}`, {replace: false})
        setTab(value)
    }
    useEffect(() => {
        const initialTab = priceLists.findIndex(({slug}) => location.hash === slug);
        if (initialTab !== -1) setTab(initialTab);
    }, [location.hash]);
    return (
        <Layout>
            <Tabs
                value={tab}
                onChange={changeTab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="price list tabs"
            >
                {priceLists.map(({label}) => {
                    return (
                        <Tab label={label} key={`label-${label}`}/>
                    )
                })}
            </Tabs>
            {priceLists.map(({label, storageKey, sortModel, DataTable}, index) => {
                return (
                    <TabPanel value={tab} index={index} key={label}>
                        <ListPageProvider
                            sortModel={sortModel}
                            storageKey={storageKey}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexFlow: 'column',
                                    flexGrow: 1,
                                    height: 'calc(100vh - 128px)',
                                    pb: 3
                                }}
                            >
                                <ListActionButtons/>
                                <DataTable/>
                            </Box>
                        </ListPageProvider>
                    </TabPanel>
                )
            })}
        </Layout>
    )
}

export default PriceList;
