import Box from "@mui/material/Box";
import {useLayoutEffect} from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5locales_tr_TR from "@amcharts/amcharts5/locales/tr_TR";
import * as am5xy from "@amcharts/amcharts5/xy";
import {useTheme} from "@mui/material/styles";

const ClusteredColumnChart = function ({id, height, data, categoryField, seriesMap}) {
    const theme = useTheme()

    useLayoutEffect(() => {
        let root = am5.Root.new(id);

        if (theme.palette.mode === 'dark') {
            root.setThemes([
                am5themes_Dark.new(root),
            ]);
        } else {
            root.setThemes([
                am5themes_Animated.new(root),
            ]);
        }

        root.locale = am5locales_tr_TR

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout,
        }));

        let legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50
            })
        );

        let xRenderer = am5xy.AxisRendererX.new(root, {
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
        })

        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: categoryField,
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {}),
        }));

        xRenderer.grid.template.setAll({
            location: 1
        })

        xAxis.data.setAll(data);

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0.1
            }),
            min: 0,
        }));

        seriesMap.forEach(({name, field}) => {
            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: field,
                categoryXField: categoryField,
            }));

            series.columns.template.setAll({
                tooltipText: "{name}, {categoryX}:{valueY}",
                width: am5.percent(90),
                tooltipY: 0,
                strokeOpacity: 0
            });

            series.data.setAll(data);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear();

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 0,
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: 0,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });

            legend.data.push(series);
        })

        return () => {
            root.dispose();
        };
    }, [categoryField, data, id, seriesMap, theme.palette.mode])
    return <Box id={id} sx={{width: '100%', height: height}}/>
}

export default ClusteredColumnChart;
