import {useContext} from "react";
import {useSnackbar} from "notistack";
import CustomerApi from '../../api/customer';
import {listPageActionTypes} from "../list-page-hoc/constants";
import CustomerFormDialog from "./CustomerFormDialog";
import {AuthContext} from "../../contexts/AuthContext";
import {ListPageContext} from "../list-page-hoc/context";
import {hasPermission} from "../../utils/permissions";

const CustomerUpdateDialog = function ({sx}) {
    const {enqueueSnackbar} = useSnackbar();
    const {dispatch} = useContext(ListPageContext);
    const authorization = useContext(AuthContext);

    const create = (values) => {
        return CustomerApi.save({authorization}, values).then(() => {
            dispatch({type: listPageActionTypes.UPDATE_VERSION})
        }).catch(err => {
            if (err?.response?.status === 400) {
                enqueueSnackbar('Hatalı işlem', {
                    variant: "error"
                });
            }
        });
    }

    return (
        <CustomerFormDialog
            onSubmit={create}
            title={'Müşteri Oluştur'}
            label={'Müşteri Oluştur'}
            sx={sx}
        />
    );
}

export default hasPermission(CustomerUpdateDialog, {permissions: ['add_customer']});
