import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {transformDateToLocalString} from "../../utils/transform";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import PriceTextFormat from "../PriceTextFormat";
import {userLabelFormat} from "../../helpers/user/format";
import {AuthContext} from "../../contexts/AuthContext";

const DetailTable = function ({tableSize = 'medium', showFinancialPart = true, showCreator}) {
    const {hasPermission} = useContext(AuthContext);
    const {state} = useContext(DetailPageContext);

    return (
        <TableContainer component={Paper}>
            <Table size={tableSize}>
                <TableBody>
                    {showCreator && (
                        <>
                            <TableRow>
                                <TableCell>Oluşturan Kişi</TableCell>
                                <TableCell>{userLabelFormat({user: state.data.statusHistory[0].user})}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Oluşturma Tarihi</TableCell>
                                <TableCell>{transformDateToLocalString(state.data.statusHistory[0].createdAt, false)}</TableCell>
                            </TableRow>
                        </>
                    )}
                    <TableRow>
                        <TableCell>Ürün/İşlem</TableCell>
                        <TableCell>{state.data.product.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Kestaş Ürün Kodu</TableCell>
                        <TableCell>{state.data.companyProductCode || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Kestaş Sipariş No</TableCell>
                        <TableCell>{state.data.companyOrderId || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Müşteri Ürün Kodu</TableCell>
                        <TableCell>{state.data.customerProductCode || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Müşteri Sipariş No</TableCell>
                        <TableCell>{state.data.customerOrderId || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Adet veya Kg</TableCell>
                        <TableCell>{state.data.quantity || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Teklif Tarihi</TableCell>
                        <TableCell>
                            {state.data.offerDate ? transformDateToLocalString(state.data.offerDate) : '-'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Teklif Onay Tarihi</TableCell>
                        <TableCell>
                            {state.data.confirmationDate ? transformDateToLocalString(state.data.confirmationDate) : '-'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Termin Tarihi</TableCell>
                        <TableCell>
                            {state.data.deadline ? transformDateToLocalString(state.data.deadline) : '-'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Ürün Ebat/Cins Açıklaması</TableCell>
                        <TableCell>{state.data.description || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Notlar</TableCell>
                        <TableCell>{state.data.notes || '-'}</TableCell>
                    </TableRow>
                    {showFinancialPart && hasPermission({permissions: ['view_unit_price']}) && (
                        <TableRow>
                            <TableCell>Birim Fiyat</TableCell>
                            <TableCell>
                                <PriceTextFormat
                                    prefix={state.data.currency}
                                    value={state.data.unitPrice || '-'}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DetailTable;
