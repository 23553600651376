import {useContext, useState} from "react";
import {statusTranslate} from "../../helpers/invoice-order/status";
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import {AuthContext} from "../../contexts/AuthContext";
import {useSnackbar} from 'notistack';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as yup from "yup";
import {Formik} from "formik";
import InvoiceOrderApi from '../../api/invoice-order';
import format from "date-fns/format";
import {useNavigate} from "react-router-dom";
import {hasPermission} from "../../utils/permissions";
import TextField from "../forms/TextField";
import DatePicker from "../forms/DatePicker";
import PriceFieldFormat from "../PriceFieldFormat";
import SelectField from "../forms/SelectField";
import Stack from "@mui/material/Stack";


const StatusUpdateButton = function ({invoiceOrder, setInvoiceOrder}) {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const authorization = useContext(AuthContext);

    const initialValues = {
        companyInvoiceNo: '',
        companyInvoiceDate: new Date(),
        unitPrice: invoiceOrder.unitPrice || '',
        currency: invoiceOrder.currency || 'TL',
        dispatchType: invoiceOrder.dispatchType || '',
        tagCount: invoiceOrder.tagCount || '',
    }
    const statusUpdate = (data) => {
        setLoading(true);
        InvoiceOrderApi.updateStatus(
            {authorization},
            invoiceOrder.id,
            {...data, process: 'invoice'}
        ).then(workOrder => {
            navigate(-1)
            // setInvoiceOrder(workOrder);
            // setLoading(false);
        }).catch(err => {
            let message = 'Bir hata oldu'
            if (err?.response?.status === 400) {
                message = 'Hatalı işlem'
            }
            enqueueSnackbar(message, {
                variant: "error"
            });
            setLoading(false);
        });
    }
    return (
        <>
            <Modal
                open={open}
                onClose={() => !loading && setOpen(false)}
            >
                <Paper variant="outlined" sx={style}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, actions) => {
                            // actions.setSubmitting(false);
                            statusUpdate({
                                ...values,
                                companyInvoiceDate: format(values.companyInvoiceDate, 'yyyy-MM-dd')
                            });
                        }}
                    >
                        {formik => (
                            <Stack spacing={2}>
                                <TextField
                                    name={'companyInvoiceNo'}
                                    label={'Fatura No'}
                                    size={'small'}
                                />
                                <DatePicker
                                    label={"Fatura Tarihi"}
                                    name={'companyInvoiceDate'}
                                    size={'small'}
                                />
                                <Stack direction={"row"} spacing={1}>
                                    <TextField
                                        fullWidth
                                        name={"unitPrice"}
                                        label={"Birim Fiyat"}
                                        inputMode={"decimal"}
                                        InputProps={{
                                            inputComponent: PriceFieldFormat
                                        }}
                                        size={'small'}
                                    />
                                    <SelectField
                                        name={'currency'}
                                        label={'Para Birimi'}
                                        values={currencies.map(currency => ({id: currency}))}
                                        nullable={false}
                                        size={'small'}
                                    />
                                    <TextField
                                        fullWidth
                                        name={"rate"}
                                        label={"Kur"}
                                        inputMode={"decimal"}
                                        InputProps={{
                                            inputComponent: PriceFieldFormat
                                        }}
                                        size={'small'}
                                    />
                                </Stack>
                                <Stack direction={"row"} spacing={1}>
                                    <TextField
                                        name={"dispatchType"}
                                        label={"Sevk Şekli"}
                                        inputMode={"text"}
                                        size={'small'}
                                    />
                                    <TextField
                                        name={"tagCount"}
                                        label={"Etiket Sayısı"}
                                        inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                        size={'small'}
                                    />
                                </Stack>

                                <Box sx={{mb: 2}}>
                                    <LoadingButton
                                        variant="contained"
                                        onClick={() => formik.submitForm()}
                                        sx={{mt: 1, mr: 1, textTransform: 'none'}}
                                        loading={loading}
                                    >
                                        Kaydet
                                    </LoadingButton>
                                </Box>
                            </Stack>
                        )}
                    </Formik>
                </Paper>
            </Modal>
            <Button
                variant="contained"
                sx={{marginLeft: 'auto', textTransform: 'none'}}
                onClick={() => setOpen(true)}
            >{statusTranslate[invoiceOrder.status].statusUpdateButtonLabel}
            </Button>
        </>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75vw',
    maxWidth: 'md',
    maxHeight: '80vh',
    overflow: 'auto',
    p: 3
};

const currencies = ['TL', 'EUR', 'GBP', 'USD'];

const validationSchema = yup.object({
    companyInvoiceNo: yup
        .string()
        .max(16, 'Fatura no en fazla 16 karakter olabilir')
        .required('Lütfen fatura numarasını giriniz'),
    companyInvoiceDate: yup
        .date()
        .required('Fatura tarihini seçin'),
    unitPrice: yup
        .number()
        .min(0, 'Birim fiyat negatif olamaz')
        .required('Lütfen birim fiyat giriniz'),
    currency: yup
        .string()
        .oneOf(currencies),
    rate: yup
        .number(),
    dispatchType: yup.string().required('Zorunlu alan'),
    tagCount: yup.number()
        .typeError('Yalnızca sayı olarak giriniz')
        .min(0, 'Sayı negatif olamaz')
        .integer('Lütfen tamsayı giriniz')
        .required('Zorunlu alan'),
});

export default hasPermission(StatusUpdateButton, {permissions: ['can_complete_invoice_order']});
