import {DataGridPro, trTR} from "@mui/x-data-grid-pro";
import {monthNames} from "../../../utils/date";
import Box from "@mui/material/Box";
import React from "react";

const MonthlyOilSale = function ({loading, data}) {
    return (
        <Box sx={{width: '100%'}}>
            <DataGridPro
                autoHeight
                loading={loading}
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                columns={[
                    {field: 'year', headerName: 'Yıl'},
                    ...monthNames.map(
                        (monthName, index) => ({
                            field: `${index + 1}`,
                            flex: 1,
                            headerName: monthName,
                            sortable: false,
                            filterable: false,
                            pinnable: false,
                            hideable: false,
                            valueFormatter: (params) => {
                                if (!params.value) return '-'
                                return params.value;
                            }
                        })
                    ),
                ]}
                initialState={{
                    pinnedColumns: {left: ['year']}
                }}
                rows={data}
            />
        </Box>
    )
}

export default MonthlyOilSale;
