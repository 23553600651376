import {useState, useEffect} from "react";
import Layout from "../components/layout";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../components/TabPanel";
import {useLocation, useNavigate} from "react-router-dom";
import GeneralAnalysis from "../components/analysis/GeneralAnalysis";
import MonthlyWorkAnalysis from "../components/analysis/MonthlyWorkAnalysis";
// import BroachingAnalysis from "../components/analysis/BroachingAnalysis";
import OperationTimeTrackingAnalysis from "../components/analysis/operation-time-tracking-analysis/OperationTimeTrackingAnalysis";

const analyzeList = [
    {
        label: 'Genel Durum',
        slug: '#genel-durum',
        AnalyzeTab: GeneralAnalysis
    },
    {
        label: 'Aylık Adet-İş Analizi',
        slug: '#aylik-adet-is-analizi',
        AnalyzeTab: MonthlyWorkAnalysis
    },
    {
        label: 'Operasyon Zaman Takip Analizi',
        slug: '#operasyon-zaman-takip-analizi',
        AnalyzeTab: OperationTimeTrackingAnalysis
    },
    // {
    //     label: 'Fason Broşlama Analizi',
    //     slug: '#fason-broslama-analizi',
    //     AnalyzeTab: BroachingAnalysis
    // },
]

const ProductionAnalysis = function () {
    const navigate = useNavigate();
    const location = useLocation();
    const [tab, setTab] = useState(0);
    const changeTab = (event, value) => {
        navigate(`/uretim-analizleri${analyzeList[value].slug}`, {replace: false})
    }
    useEffect(() => {
        const initialTab = analyzeList.findIndex(({slug}) => location.hash === slug);
        if (initialTab !== -1) setTab(initialTab);
    }, [location.hash]);
    return (
        <Layout>
            <Tabs
                value={tab}
                onChange={changeTab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="analyze list tabs"
            >
                {analyzeList.map(({label}) => {
                    return (
                        <Tab label={label} key={`label-${label}`}/>
                    )
                })}
            </Tabs>
            {analyzeList.map(({label, AnalyzeTab}, index) => {
                return (
                    <TabPanel value={tab} index={index} key={label}>
                        <AnalyzeTab/>
                    </TabPanel>
                )
            })}
        </Layout>
    )
}

export default ProductionAnalysis;
