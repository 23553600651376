import {useCallback, useContext} from "react";
import BaseDataTable from "../datatable";
import {ListPageContext} from "../list-page-hoc/context";
import {listPageActionTypes} from "../list-page-hoc/constants";
import BroachingOilPricesApi from '../../api/broaching-oil-prices';
import PriceTextFormat from "../PriceTextFormat";
import {transformDateToLocalString} from "../../utils/transform";
import {priceTypeTranslate, unitTranslate} from "../../helpers/customer/translate";
import {useListApi} from "../list-page-hoc/hooks";

function BroachingOilDataTable() {
    const {state, dispatch} = useContext(ListPageContext);
    const {data: broachingOilPriceList} = useListApi(BroachingOilPricesApi);

    const handleSortModelChange = sortModel => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({type: listPageActionTypes.SET_SORT_MODEL, sortModel})
    };

    const handleFilterModelChange = useCallback(filterModel => {
        console.log(filterModel);
    }, []);

    const onPageChange = (page) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                page: page,
            }
        })
    }

    const onPageSizeChange = (pageSize) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                pageSize,
            }
        })
    }

    return (
        <BaseDataTable
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            sortModel={state.sortModel}
            loading={state.loading}
            rowCount={broachingOilPriceList.count}
            page={state.pagination.page}
            pageSize={state.pagination.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={broachingOilPriceList.results}
            columns={[
                {
                    field: 'customer',
                    filterable: false,
                    headerName: 'Müşteri',
                    sortable: true,
                },
                {
                    field: 'authorizedPerson',
                    filterable: false,
                    headerName: 'Yetkili İsmi',
                    sortable: false,
                },
                {
                    field: 'email',
                    filterable: false,
                    headerName: 'Email',
                    sortable: false,
                },
                {
                    field: 'priceType',
                    filterable: false,
                    headerName: 'Fiyat Tipi',
                    sortable: false,
                    valueFormatter: (params) => {
                        return priceTypeTranslate[params.value];
                    }
                },
                {
                    field: 'amount',
                    filterable: false,
                    headerName: 'Yağ Miktarı',
                    sortable: false,
                },
                {
                    field: 'unitPrice',
                    filterable: false,
                    headerName: 'Birim Fiyat',
                    sortable: false,
                    renderCell: (params) => <PriceTextFormat
                        prefix={`${params.row.currency}/${unitTranslate[params.row.unit]}`}
                        value={params.value}
                    />
                },
                {
                    field: 'confirmationDate',
                    filterable: false,
                    headerName: 'Fiyat Anlaşma Tarihi',
                    width: 135,
                    type: 'date',
                    valueFormatter: (params) => {
                        if (!params.value) return '-'
                        return transformDateToLocalString(params.value);
                    }
                },
                {
                    field: 'validityPeriodStart',
                    filterable: false,
                    headerName: 'Geçerlilik Başlangıç Tarihi',
                    width: 135,
                    type: 'date',
                    valueFormatter: (params) => {
                        if (!params.value) return '-'
                        return transformDateToLocalString(params.value);
                    }
                },
                {
                    field: 'validityPeriodEnd',
                    filterable: false,
                    headerName: 'Geçerlilik Bitiş Tarihi',
                    width: 135,
                    type: 'date',
                    valueFormatter: (params) => {
                        if (!params.value) return '-'
                        return transformDateToLocalString(params.value);
                    }
                },
                {
                    field: 'descriptionOne',
                    filterable: false,
                    headerName: 'Açıklama-1',
                    sortable: false,
                    flex: 1,
                },
                {
                    field: 'descriptionTwo',
                    filterable: false,
                    headerName: 'Açıklama-2',
                    sortable: false,
                    flex: 1,
                },
                // {
                //     field: 'actions',
                //     type: 'actions',
                //     pinnable: true,
                //     getActions: (params) => [
                //         <TimeTrackingDataUpdateDialog timeTrackingData={params.row}/>
                //     ]
                // }
            ]}
        />
    )
}

export default BroachingOilDataTable;
