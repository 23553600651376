import {useContext} from "react";
import MessageBox from "../message-box";
import ActionCommentApi from '../../api/action-comment';
import {AuthContext} from "../../contexts/AuthContext";
import {DetailPageContext} from "../detail-page-hoc/context";
import {transformDateToLocalString} from "../../utils/transform";
import {userLabelFormat} from "../../helpers/user/format";
import {hasPermission} from "../../utils/permissions";

const ActionComments = function ({sx, workOrderId}) {
    const authorization = useContext(AuthContext);
    const {state} = useContext(DetailPageContext);

    const getInitialMessages = () => {
        return ActionCommentApi.getAll({authorization}, [], {action: state.id})
    }

    const sendMessage = message => {
        return ActionCommentApi.save({authorization}, {action: state.id, comment: message})
    }

    return (
        <MessageBox
            sx={sx}
            sendMessage={sendMessage}
            getInitialMessages={getInitialMessages}
            messageFormatter={({user, comment, createdAt}) => {
                return {
                    sender: userLabelFormat({user}),
                    message: comment,
                    datetime: transformDateToLocalString(createdAt, false)
                }
            }}
            title={'Yorumlar Bölümü'}
        />
    )
}

export default hasPermission(ActionComments, {permissions: ['view_actioncomment']});
