import Box from "@mui/material/Box";
import AnalyzeHOC from "./analyze-hoc";
import {analyzeChartTypes} from "./analyze-hoc/constants";
import AnalyzeContainerProvider from "./analyze-container-hoc/context";
import ActionButtons from "./ActionButtons";
import Grid from "@mui/material/Grid";

const GeneralAnalysis = function () {
    return (
        <AnalyzeContainerProvider>
            <Box sx={{my: 3, flex: '1 1 auto'}}>
                <ActionButtons/>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={3}>
                        <AnalyzeHOC
                            storageKey={'analyze-aktif-is-emri'}
                            slug={'aktif-is-emri'}
                            chartType={analyzeChartTypes.COUNT}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <AnalyzeHOC
                            storageKey={'analyze-termini-gecen-aktif-is-emri'}
                            slug={'termini-gecen-aktif-is-emri'}
                            chartType={analyzeChartTypes.COUNT}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <AnalyzeHOC
                            storageKey={'analyze-aktif-yuvarlak-bros-imalati-is-emri'}
                            slug={'aktif-yuvarlak-bros-imalati-is-emri'}
                            chartType={analyzeChartTypes.COUNT}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <AnalyzeHOC
                            storageKey={'analyze-termini-gecen-yuvarlak-bros-imalati-is-emri'}
                            slug={'termini-gecen-yuvarlak-bros-imalati-is-emri'}
                            chartType={analyzeChartTypes.COUNT}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <AnalyzeHOC
                            storageKey={'analyze-aktif-lama-bros-imalati-is-emri'}
                            slug={'aktif-lama-bros-imalati-is-emri'}
                            chartType={analyzeChartTypes.COUNT}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <AnalyzeHOC
                            storageKey={'analyze-termini-gecen-lama-bros-imalati-is-emri'}
                            slug={'termini-gecen-lama-bros-imalati-is-emri'}
                            chartType={analyzeChartTypes.COUNT}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <AnalyzeHOC
                            storageKey={'analyze-aktif-zimba-imalati-is-emri'}
                            slug={'aktif-zimba-imalati-is-emri'}
                            chartType={analyzeChartTypes.COUNT}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <AnalyzeHOC
                            storageKey={'analyze-termini-gecen-zimba-imalati-is-emri'}
                            slug={'termini-gecen-zimba-imalati-is-emri'}
                            chartType={analyzeChartTypes.COUNT}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <AnalyzeHOC
                            storageKey={'analyze-aktif-yuvarlak-bros-bileme-is-emri'}
                            slug={'aktif-yuvarlak-bros-bileme-is-emri'}
                            chartType={analyzeChartTypes.COUNT}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <AnalyzeHOC
                            storageKey={'analyze-aktif-lama-bros-bileme-is-emri'}
                            slug={'aktif-lama-bros-bileme-is-emri'}
                            chartType={analyzeChartTypes.COUNT}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <AnalyzeHOC
                            storageKey={'analyze-aktif-fason-broslama-is-emri'}
                            slug={'aktif-fason-broslama-is-emri'}
                            chartType={analyzeChartTypes.COUNT}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <AnalyzeHOC
                            storageKey={'analyze-aktif-tezgah-imalati-is-emri'}
                            slug={'aktif-tezgah-imalati-is-emri'}
                            chartType={analyzeChartTypes.COUNT}
                        />
                    </Grid>
                </Grid>
            </Box>
        </AnalyzeContainerProvider>
    )
}

export default GeneralAnalysis;
