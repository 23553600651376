import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {transformDateToLocalString} from "../utils/transform";
import Paper from "@mui/material/Paper";
import {userLabelFormat} from "../helpers/user/format";

const StatusHistory = function ({statusHistory, statusFormat = ({status}) => status, title = 'İşlem Geçmişi'}) {
    return (
        <Paper sx={{padding: 2}}>
            <Typography variant={"subtitle1"}>
                {title}
            </Typography>
            <Divider/>
            <Stepper
                activeStep={statusHistory.length}
                orientation="vertical"
                sx={{marginTop: 2}}
            >
                {statusHistory.map((status) => {
                    return (
                        <Step key={status.id}>
                            <StepLabel>
                                <Typography
                                    variant={"body1"}
                                >{statusFormat(status)}
                                </Typography>
                                {status.note && (
                                    <Typography
                                        variant={"body2"}
                                        color={"red"}
                                    >{status.note}
                                    </Typography>
                                )}
                                <Typography
                                    variant={"body2"}
                                >{userLabelFormat({user: status.user})}
                                </Typography>
                                <Typography
                                    variant={"caption"}
                                >{transformDateToLocalString(status.createdAt, false)}
                                </Typography>
                            </StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </Paper>
    )
}

export default StatusHistory;
