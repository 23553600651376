import {DataGridPro, trTR} from '@mui/x-data-grid-pro';

function ClientDataTable(
    {
        rows,
        columns,
        initialState = {},
        rowCount,
        pageSizeOptions,
        sx = {},
    }
) {
    return (
        <DataGridPro
            rows={rows}
            columns={columns}
            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
            pagination
            rowCount={rowCount}
            initialState={initialState}
            pageSizeOptions={pageSizeOptions}
            sx={sx}
        />
    );
}

export default ClientDataTable;
