import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Logo from './Logo'


export function PermanentDrawer({drawerWidth, children}) {
    return (
        <Drawer
            variant="permanent"
            sx={{
                display: {xs: 'none', sm: 'block'},
                '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
            }}
            open
        >
            <Toolbar/>
            {children}
        </Drawer>
    );
}

export function TemporaryDrawer({drawerWidth, children, mobileOpen, handleDrawerToggle}) {
    return (
        <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: {sm: 'block', md: 'none'},
                '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
            }}
        >
            <Toolbar>
                <Logo/>
            </Toolbar>
            <Divider/>
            {children}
        </Drawer>
    )
}
