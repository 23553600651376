import Box from "@mui/material/Box";
import BackButton from "../BackButton";
import CustomerUpdateDialog from "./CustomerUpdateDialog";

const DetailActionButtons = function () {
    return (
        <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
            <BackButton/>
            <CustomerUpdateDialog sx={{ml: 'auto'}}/>
        </Box>
    )
}

export default DetailActionButtons;
