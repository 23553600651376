import {useState, useEffect} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useContext} from "react";
import {AuthContext} from "../../../contexts/AuthContext";
import {ListPageContext} from "../../list-page-hoc/context";
import {listPageActionTypes} from "../../list-page-hoc/constants";
import ProductApi from "../../../api/product";
import ListSearch from "../../ListSearch";
import StatusFilter from "../../work-order/StatusFilter";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

const ListActionButtons = function () {
    const authorization = useContext(AuthContext);
    const {state, dispatch} = useContext(ListPageContext);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        ProductApi.getAll({authorization}).then((products) => {
            setProducts(products);
        })
    }, [authorization]);

    return (<Box sx={{width: '100%', display: 'flex', mt: 3}}>
        <Box sx={{flexGrow: 1}}/>
        <Box sx={{display: 'flex'}}>
            <Button
                sx={{textTransform: 'none'}}
                onClick={() => {
                    dispatch({
                        type: listPageActionTypes.SET_FILTERS, filters: {status: [], product: state.filters.product}
                    });
                    dispatch({type: listPageActionTypes.SET_SEARCH_TEXT, searchText: ''});
                    dispatch({type: listPageActionTypes.SET_INPUT_VALUE, inputValues: {}});
                }}
            >
                Temizle
            </Button>
            <ListSearch/>
            <FormControl sx={{ml: 1, width: 200}} size="small">
                <InputLabel id="product-select-label">Ürün/Hizmet</InputLabel>
                <Select
                    labelId="product-select-label"
                    label="Ürün/Hizmet"
                    value={state.filters.product}
                    onChange={(event) => {
                        dispatch({
                            type: listPageActionTypes.SET_FILTERS,
                            filters: {...state.filters, product: event.target.value}
                        });
                    }}
                >
                    {products
                        .filter(product => product.operations.length > 0)
                        .map(product => {
                            return <MenuItem key={product.id} value={product.id}>{product.name}</MenuItem>
                        })}
                </Select>
            </FormControl>
            <StatusFilter sx={{ml: 1, width: 200}}/>
        </Box>
    </Box>)
}

export default ListActionButtons;
