import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import TableHead from "@mui/material/TableHead";
import RemoveBroachingPriceButton from "./RemoveBroachingPriceButton";
import PriceTextFormat from "../PriceTextFormat";
import BroachingPriceCreateDialog from "./BroachingPriceCreateDialog";
import {hasPermission} from "../../utils/permissions";

const BroachingPriceTable = function () {
    const {state} = useContext(DetailPageContext);
    const customer = state.data;

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Fason Broşlama Fiyatları</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right">
                            <BroachingPriceCreateDialog/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Yetkili İsim</TableCell>
                        <TableCell>Parça Adı</TableCell>
                        <TableCell>Birim Fiyat</TableCell>
                        <TableCell>Ayar Ücreti</TableCell>
                        <TableCell>Açıklama-1</TableCell>
                        <TableCell>Açıklama-2</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customer.broachingPrices.map(
                        ({
                             id,
                             authorizedPerson,
                             partName,
                             unitPrice,
                             calibrationPrice,
                             currency,
                             descriptionOne,
                             descriptionTwo
                         }) => {
                            return (
                                <TableRow key={id}>
                                    <TableCell>{authorizedPerson}</TableCell>
                                    <TableCell>{partName}</TableCell>
                                    <TableCell>
                                        <PriceTextFormat
                                            prefix={currency}
                                            value={unitPrice}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <PriceTextFormat
                                            prefix={currency}
                                            value={calibrationPrice}
                                        />
                                    </TableCell>
                                    <TableCell>{descriptionOne}</TableCell>
                                    <TableCell>{descriptionTwo}</TableCell>
                                    <TableCell align="right">
                                        <RemoveBroachingPriceButton
                                            customer={customer}
                                            contactId={id}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default hasPermission(BroachingPriceTable, {permissions: ['can_view_customer_broaching_price']});
