import DataTable from "../components/invoice-order/DataTable";
import ListPageHOC from "../components/list-page-hoc";
import ListActionButtons from "../components/invoice-order/ListActionButtons";

const InvoiceOrders = function () {

    return (
        <ListPageHOC
            storageKey={'invoice-order-page'}
            sortModel={[{field: "createdAt", sort: "desc"}]}
            filters={{status: []}}
        >
            <ListActionButtons/>
            <DataTable/>
        </ListPageHOC>
    )
}

export default InvoiceOrders;
