import {useState, useEffect} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useContext} from "react";
import {AuthContext} from "../../../contexts/AuthContext";
import {ListPageContext} from "../../list-page-hoc/context";
import {listPageActionTypes} from "../../list-page-hoc/constants";
import ProductApi from "../../../api/product";
import CustomerApi from "../../../api/customer";
import ListAutocompleteFilter from "../../ListAutocompleteFilter";
import ListDateFilter from "../../ListDateFilter";
import {getCurrentMonthDateRange} from "./utils";

const GenericRevenueTableFilters = function () {
    const authorization = useContext(AuthContext);
    const {dispatch} = useContext(ListPageContext);
    const [products, setProducts] = useState([]);
    const [customers, setCustomers] = useState([]);
    const dateRange = getCurrentMonthDateRange()

    useEffect(() => {
        Promise.all([
            ProductApi.getAll({authorization}),
            CustomerApi.dropdown({authorization}),
        ]).then(([products, customers]) => {
            setProducts(products);
            setCustomers(customers);
        })
    }, [authorization]);

    return (
        <Box sx={{width: '100%', display: 'flex', mt: 2}}>
            <Box sx={{flexGrow: 1}}/>
            <Box sx={{display: 'flex'}}>
                <Button
                    sx={{textTransform: 'none'}}
                    onClick={() => {
                        dispatch({
                            type: listPageActionTypes.SET_FILTERS, filters: {
                                'date_after': dateRange.firstDay,
                                'date_before': dateRange.currentDay,
                            }
                        });
                        dispatch({type: listPageActionTypes.SET_INPUT_VALUE, inputValues: {}});
                    }}
                >
                    Temizle
                </Button>
                <ListAutocompleteFilter
                    label="Ürün/Hizmet"
                    filterField="product"
                    inputValueField="productInput"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={products.map(product => ({label: product.name, id: product.id}))}
                />
                <ListAutocompleteFilter
                    label="Müşteri"
                    filterField="customer"
                    inputValueField="customerInput"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={customers.map(customer => ({label: customer.name, id: customer.id}))}
                />
                <ListDateFilter label="Başlangıç Tarihi" size="small" filterField="date_after"/>
                <ListDateFilter label="Bitiş Tarihi" size="small" filterField="date_before"/>
            </Box>
        </Box>
    )
}

export default GenericRevenueTableFilters;
