import DataTable from "../components/work-order/DataTable";
import ListActionButtons from "../components/work-order/ListActionButtons";
import ListPageHOC from "../components/list-page-hoc";

const WorkOrders = function () {
    return (
        <ListPageHOC
            storageKey={'work-order-page'}
            sortModel={[{field: "createdAt", sort: "desc"}]}
            filters={{status: []}}
        >
            <ListActionButtons/>
            <DataTable />
        </ListPageHOC>
    )
}

export default WorkOrders;
