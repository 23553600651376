import {useState, useEffect, useContext} from "react";
import {DataGridPro, trTR} from "@mui/x-data-grid-pro";
import PriceTextFormat from "../../PriceTextFormat";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import {transformDateToLocalString} from "../../../utils/transform";
import GenericRevenueApi from "../../../api/generic-revenue";
import {AuthContext} from "../../../contexts/AuthContext";
import {getCurrentYearDateRange} from "./utils";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";

const TopRevenue = function () {
    let navigate = useNavigate();
    const authorization = useContext(AuthContext);
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const dateRange = getCurrentYearDateRange()

    useEffect(() => {
        let abort = false;
        GenericRevenueApi.getAll(
            {authorization},
            [{field: 'total', sort: 'desc'}],
            {
                'date_after': dateRange.firstDay,
                'date_before': dateRange.currentDay,
            },
            {page: 0, pageSize: 5}
        ).then((data) => {
            if (!abort) {
                setData(data.results)
                setLoading(false)
            }
        })
        return () => {
            abort = true
        }
    }, [authorization, dateRange.currentDay, dateRange.firstDay]);

    return (
        <Box sx={{width: '100%', mt: 2}}>
            <DataGridPro
                autoHeight
                loading={loading}
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                columns={[
                    {
                        field: 'customer',
                        filterable: false,
                        headerName: 'Müşteri',
                        sortable: false,
                        flex: 1,
                        valueFormatter: ({value}) => {
                            return value.name
                        },
                    },
                    {
                        field: 'product',
                        filterable: false,
                        headerName: 'Ürün/Hizmet',
                        sortable: false,
                        flex: 1,
                    },
                    {
                        field: 'companyInvoiceNo',
                        filterable: false,
                        headerName: 'Fatura No',
                        sortable: false,
                        flex: 1,
                        renderCell: (params) => (<Link
                            component={'button'}
                            variant={'body2'}
                            onClick={() => {
                                navigate(`/fatura-emirleri/${params.id}`, {replace: false})
                            }}
                        >{params.value}</Link>)
                    },
                    {
                        field: 'companyInvoiceDate',
                        filterable: false,
                        headerName: 'Fatura Tarihi',
                        sortable: true,
                        width: 135,
                        type: 'date',
                        valueFormatter: (params) => {
                            return transformDateToLocalString(params.value, true);
                        }
                    },
                    {
                        field: 'total',
                        filterable: false,
                        headerName: 'Tutar (TL)',
                        sortable: true,
                        width: 150,
                        renderCell: (params) => <PriceTextFormat
                            value={params.value}
                        />
                    },
                    {
                        field: 'rate',
                        filterable: false,
                        headerName: 'Kur',
                        sortable: false,
                        width: 120,
                        renderCell: (params) => <PriceTextFormat
                            prefix={`TL-${params.row.currency}`}
                            value={params.value}
                        />
                    },
                ]}
                rows={data}
                slots={{toolbar: () => <Box paddingX="16px" paddingY="8px"><Typography variant="subtitle2">Tutara göre ilk 5 satış</Typography></Box>}}
            />
        </Box>
    )
}

export default TopRevenue;
