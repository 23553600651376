import {useContext, useState, useEffect} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import Button from "@mui/material/Button";
import WorkOrderFormDialog from "../work-order/WorkOrderFormDialog";
import {AuthContext} from "../../contexts/AuthContext";
import ProductApi from "../../api/product";
import CustomerApi from "../../api/customer";
import {hasPermission} from "../../utils/permissions";

const CreateWorkOrderButton = function ({sx = {}, item}) {
    const authorization = useContext(AuthContext);
    const {state} = useContext(DetailPageContext);
    const [open, setOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        Promise.all([
            ProductApi.getAll({authorization}),
            CustomerApi.dropdown({authorization}),
        ]).then(
            ([products, customers]) => {
                setProducts(products);
                setCustomers(customers);
            }
        )
    }, [authorization]);

    const operations = products.find(product => product.id === item.product.id)?.operations || [];
    const operationsInitialValues = operations.filter(operation => operation.timeTrackingEnabled);
    return (
        <>
            <Button
                variant={'outlined'}
                sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
                onClick={() => setOpen(true)}
            >İş Emri Oluştur</Button>
            <WorkOrderFormDialog
                open={open}
                handleClose={() => setOpen(false)}
                products={products}
                customers={customers}
                initialValues={{
                    product: item.product.id,
                    customer: state.data.customer.id,
                    companyProductCode: item.companyProductCode || '',
                    companyOrderId: state.data.companyOrderId || '',
                    quantity: '',
                    createAsMultiple: false,
                    offerDate: state.data.offerDate || '',
                    confirmationDate: state.data.confirmationDate || '',
                    deadline: state.data.deadline || '',
                    customerProductCode: item.customerProductCode || '',
                    customerOrderId: state.data.customerOrderId || '',
                    description: item.description || '',
                    notes: '',
                    unitPrice: item.unitPrice || '',
                    currency: item.currency,
                    dispatchType: '',
                    ...operationsInitialValues,
                }}
            />
        </>
    )
}

export default hasPermission(CreateWorkOrderButton, {permissions: ['add_workorder']});
