import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const TabPanel = function (props) {
    const {children, value, index} = props;
    return (
        <Box
            sx={{
                display: value === index ? 'block' : 'none',
                flex: '1 1 auto',
            }}
        >
            {value === index && (
                <Box sx={{display: 'flex', height: '100%'}}>
                    {children}
                </Box>
            )}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default TabPanel;
