import ClusteredColumnChart from "../ClusteredColumnChart";
import {useMemo} from "react";

const ColumnChartHOC = function ({rawData}) {
    const data = useMemo(() => {
        return rawData.reduce((result, oilSale) => {
            const row = result.find(row => row.year === String(oilSale.countingYear))
            if (row) {
                row.sum += Number(oilSale.amount)
            } else {
                result.push({
                    year: String(oilSale.countingYear),
                    sum: Number(oilSale.amount),
                })
            }
            return result;
        }, []).sort((a, b) => Number(a.year) - Number(b.year))
    }, [rawData])

    return (
        <ClusteredColumnChart
            id="oil-sale-sum-column-chart"
            height={600}
            data={data}
            categoryField="year"
            seriesMap={[{field: 'sum', name: 'Toplam'}]}
        />
    )
}

export default ColumnChartHOC
