import {useState, useContext} from 'react';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import {PermanentDrawer, TemporaryDrawer} from "./Drawer";
import MenuList from "./MenuList";
import LockIcon from '@mui/icons-material/Lock';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {ColorModeContext} from "../../contexts/ThemeContext";
import {AuthContext} from "../../contexts/AuthContext";
import {useNavigate} from 'react-router-dom';
import Logo from "./Logo";
import Typography from "@mui/material/Typography";
import {useUser} from "../../hooks/useUser";
import NotificationButton from "../notification/NotificationButton";
import RateList from "../RateList";

const drawerWidth = 56;

const Layout = function ({children}) {
    const navigate = useNavigate();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const colorMode = useContext(ColorModeContext);
    const auth = useContext(AuthContext);
    const user = useUser();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const handleSignOutToggle = () => {
        auth.signOut().then(() => navigate('/giris-yap', {replace: true}))
    }
    return (
        <Box sx={{display: 'flex'}}>
            <AppBar
                position="fixed"
                sx={{
                    [theme.breakpoints.up('md')]: {
                        zIndex: theme.zIndex.drawer + 1
                    }
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {md: 'none'}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Logo sx={{display: {xs: 'none', md: 'block'}}}/>
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <RateList/>
                        <Box sx={{textAlign: 'right', lineHeight: '1rem', ml: 3}}>
                            <Typography>{user?.firstName} {user?.lastName}</Typography>
                            <Typography variant="caption">{user?.employee?.title}</Typography>
                        </Box>
                        <IconButton
                            size="large"
                            aria-label="dark mode"
                            color="inherit"
                            onClick={colorMode.toggleColorMode}
                        >
                            {theme.palette.mode === 'dark' ? <Brightness7Icon/> : <Brightness4Icon/>}
                        </IconButton>
                        <NotificationButton/>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="sign-out"
                            color="inherit"
                            onClick={handleSignOutToggle}
                        >
                            <LockIcon/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{width: {md: drawerWidth}, flexShrink: {md: 0}}}
                aria-label="menu"
            >
                {
                    matches
                        ? (
                            <PermanentDrawer drawerWidth={drawerWidth}>
                                <MenuList drawer={'permanent'}/>
                            </PermanentDrawer>
                        )
                        : (
                            <TemporaryDrawer
                                drawerWidth={240}
                                mobileOpen={mobileOpen}
                                handleDrawerToggle={handleDrawerToggle}
                            >
                                <MenuList drawer={'temporary'}/>
                            </TemporaryDrawer>
                        )
                }
            </Box>
            <Box
                component="main"
                sx={{flexGrow: 1, px: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
            >
                <Toolbar/>
                <Box sx={{display: 'flex', flexFlow: 'column', height: 'calc(100vh - 64px)', py: 3}}>
                    {children}
                </Box>
            </Box>
        </Box>
    )
}

export default Layout;
