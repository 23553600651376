import {monthNames} from "../../../utils/date";
import ClusteredColumnChart from "../ClusteredColumnChart";
import {useMemo} from "react";

const ClusteredColumnChartHOC = function ({rawData}) {

    const {data, seriesMap} = useMemo(() => {
        const series = new Set();
        const data = rawData.reduce((result, oilSale) => {
            const row = result.find(row => row.monthId === oilSale.countingMonth)
            if (row) {
                row[oilSale.countingYear] = Number(oilSale.amount)
            } else {
                result.push({
                    month: monthNames[oilSale.countingMonth - 1],
                    monthId: oilSale.countingMonth,
                    [oilSale.countingYear]: Number(oilSale.amount),
                })
            }
            series.add(oilSale.countingYear);
            return result;
        }, [])
        const seriesMap = [...series].sort().map(year => ({
            field: year,
            name: String(year),
        }))
        return {data, seriesMap}
    }, [rawData])

    return (
        <ClusteredColumnChart
            id="oil-sale-clustered-column-chart"
            height={400}
            data={data}
            categoryField="month"
            seriesMap={seriesMap}
        />
    )
}

export default ClusteredColumnChartHOC
