import {useContext} from "react";
import BaseDataTable from "../datatable/ClientDataTable";
import {transformDateToLocalString} from "../../utils/transform";
import Chip from '@mui/material/Chip';
import {useNavigate} from "react-router-dom";
import {statusTranslate} from "../../helpers/work-order/status";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import {DetailPageContext} from "../detail-page-hoc/context";
import CopyToClipboardButton from "../CopyToClipboardButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

const PAGE_SIZE = 10

function DataTable() {
    const {state} = useContext(DetailPageContext);
    let navigate = useNavigate();
    const workOrders = state.data.workOrders;

    return (
        <Stack spacing={1}>
            <Typography variant="h6">Açılan iş emirleri</Typography>
            <Divider/>
            <BaseDataTable
                sx={{minHeight: 200}}
                rowCount={workOrders.length}
                rows={workOrders}
                columns={[
                    {
                        field: 'workOrderId',
                        headerName: 'İş Emri No',
                        sortable: false,
                        filterable: false,
                        renderCell: (params) => {
                            return (
                                <Box>
                                    <Link
                                        component={'button'}
                                        variant={'body2'}
                                        onClick={() => {
                                            navigate(`/is-emirleri/${params.id}`, {replace: false})
                                        }}
                                    >{params.value}</Link>
                                    <CopyToClipboardButton
                                        sx={{
                                            px: 1,
                                            opacity: 0.1,
                                            transition: 'opacity ease-out 300ms',
                                            '&:hover': {
                                                opacity: 1
                                            }
                                        }}
                                        copyText={params.value}
                                        message={'İş emri numarası kopyalandı'}
                                    />
                                </Box>
                            )
                        }
                    },
                    {
                        field: 'createdAt',
                        filterable: false,
                        headerName: 'Oluşturma Tarihi',
                        width: 135,
                        type: 'date',
                        valueFormatter: (params) => {
                            return transformDateToLocalString(params.value);
                        }
                    },
                    {
                        field: 'product',
                        filterable: false,
                        headerName: 'Ürün/Hizmet',
                        width: 300,
                        valueFormatter: params => params.value.name,
                    },
                    {
                        field: 'companyProductCode',
                        headerName: 'Kestaş Ürün Kodu',
                        sortable: false,
                        filterable: false
                    },
                    {
                        field: 'customerProductCode',
                        headerName: 'Müşteri Ürün Kodu',
                        sortable: false,
                        filterable: false
                    },
                    {
                        field: 'status',
                        headerName: 'Durum',
                        filterable: false,
                        sortable: false,
                        width: 120,
                        type: 'singleSelect',
                        valueOptions: Object.values(statusTranslate).map(({chipLabel}) => chipLabel),
                        valueFormatter: ({value}) => statusTranslate[value].chipLabel,
                        renderCell: (params) => {
                            const isStatusCompleted = params.value === statusTranslate.COMPLETED.enum;
                            const isStatusDamaged = params.value === statusTranslate.DAMAGED.enum;
                            const isStatusCanceled = params.value === statusTranslate.CANCELED.enum;
                            return (
                                <Chip
                                    label={statusTranslate[params.value].chipLabel}
                                    color={
                                        isStatusCompleted
                                            ? `statusCompleted`
                                            : isStatusCanceled
                                                ? 'warning'
                                                : isStatusDamaged
                                                    ? 'error' : 'primary'
                                    }
                                />
                            );
                        }
                    },
                    {
                        field: 'quantity',
                        headerName: 'Adet veya Kg',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'description',
                        headerName: 'Ürün Ebat/Cins Açıklaması',
                        filterable: false,
                        sortable: false,
                        width: 200,
                    },
                ]}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: PAGE_SIZE,
                        },
                    }
                }}
                pageSizeOptions={[PAGE_SIZE]}
            />
        </Stack>
    )
}

export default DataTable;
