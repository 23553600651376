// ACTION_ASSIGNED = 'ACTION_ASSIGNED', 'Aksiyon atandı'
// ACTION_STATUS_CHANGED = 'ACTION_STATUS_CHANGED', 'Aksiyon durumu güncellendi'
// ACTION_COMMENTED = 'ACTION_COMMENTED', 'Aksiyona yorum yapıldı'
// WORK_ORDER_COMMENTED = 'WORK_ORDER_COMMENTED', 'İş emrine yorum yapıldı'
// WORK_ORDER_STATUS_CHANGED = 'WORK_ORDER_STATUS_CHANGED', 'İş emrinin durumu güncellendi'
// INVOICE_ORDER_COMMENTED = 'INVOICE_ORDER_COMMENTED', 'Fatura emrine yorum yapıldı'
// INVOICE_ORDER_STATUS_CHANGED = 'INVOICE_ORDER_STATUS_CHANGED', 'Fatura emrinin durumu güncellendi'

import AssignmentIcon from "@mui/icons-material/Assignment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import EngineeringIcon from "@mui/icons-material/Engineering";

export const categoryTranslate = {
    ACTION_ASSIGNED: {
        label: 'Aksiyon atandı',
        notificationTemplate: ({triggeredBy}) => {
            return `${triggeredBy.firstName} ${triggeredBy.lastName} size bir aksiyon atadı.`;
        },
        enum: 'ACTION_ASSIGNED',
        icon: AssignmentIcon,
        linkTemplate: ({referenceId}) => {
            return `/aksiyonlar/${referenceId}`;
        },
    },
    INVOICE_ORDER_CREATED: {
        label: 'Fatura emri verildi',
        notificationTemplate: () => {
            return 'Yeni fatura emri oluşturuldu';
        },
        enum: 'INVOICE_ORDER_CREATED',
        icon: ReceiptIcon,
        linkTemplate: ({referenceId}) => {
            return `/fatura-emirleri/${referenceId}`;
        },
    },
    WORK_ORDER_COMMENTED: {
        label: 'İş emrine yorum yapıldı',
        notificationTemplate: ({triggeredBy}) => {
            return `${triggeredBy.firstName} ${triggeredBy.lastName} iş emrine yorum yaptı.`;
        },
        enum: 'WORK_ORDER_COMMENTED',
        icon: EngineeringIcon,
        linkTemplate: ({referenceId}) => {
            return `/is-emirleri/${referenceId}`;
        },
    },
}
