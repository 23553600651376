import DataTable from "../components/product-pool/DataTable";
import ListActionButtons from "../components/product-pool/ListActionButtons";
import ListPageHOC from "../components/list-page-hoc";

const ProductPool = function () {
    return (
        <ListPageHOC storageKey={'product-pool-page'}>
            <ListActionButtons/>
            <DataTable/>
        </ListPageHOC>
    )
}

export default ProductPool;
