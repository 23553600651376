import {post} from "./axios";

export function signIn(credentials) {
    return post('token/', credentials, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.data)
}

export function refreshToken(refreshToken) {
    return post('token/refresh/', refreshToken, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.data)
}

export function signOut() {
    return Promise.resolve()
}
