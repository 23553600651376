export const analyzeActionTypes = {
    UPDATE_VERSION: 'UPDATE_VERSION',
    SET_DATA: 'SET_DATA',
    SET_LOADING: 'SET_LOADING'
}

export const analyzeChartTypes = {
    COUNT: 'COUNT',
    LINE_CHART: 'LINE_CHART',
}
