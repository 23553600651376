export const detailPageReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_DATA':
            return {
                ...state,
                data: action.data,
            }
        case 'UPDATE_VERSION':
            return {
                ...state,
                version: state.version + 1
            }
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.loading
            }
        default:
            return state;
    }
}
