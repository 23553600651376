import {useContext, useState} from "react";
import Dialog from "@mui/material/Dialog";
import Typography from '@mui/material/Typography';
import {Formik} from "formik";
import * as yup from "yup";
import Stack from "@mui/material/Stack";
import TextField from "../forms/TextField";
import DatePicker from "../forms/DatePicker";
import Divider from "@mui/material/Divider";
import OrderApi from "../../api/order";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";
import format from "date-fns/format";
import AutocompleteField from "../forms/AutocompleteField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";


const OrderFormDialog = function (
    {
        title,
        open,
        handleClose,
        customers,
        initialValues = {
            customer: '',
            companyOrderId: '',
            quantity: '',
            offerDate: '',
            confirmationDate: '',
            deadline: '',
            customerOrderId: '',
            customerOrderDate: '',
            paymentTerms: '',
        },
    }
) {
    let navigate = useNavigate();
    const authorization = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    // actions.setSubmitting(false);
                    setLoading(true);
                    const payload = {...values}
                    Object.entries(payload).forEach(([key, value]) => {
                        if (!value) delete payload[key]
                        if (value && key.indexOf('Date') > 0) {
                            payload[key] = format(new Date(value), 'yyyy-MM-dd');
                        }
                    });
                    OrderApi.save({authorization}, payload).then(response => {
                        setLoading(false);
                        handleClose();
                        navigate(`/siparisler/${response.id}`);
                    });
                }}
            >
                {formik => (
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContent>
                            <Stack sx={{py: 1}} spacing={1}>
                                <Typography variant={"subtitle2"}>Firma bilgileri</Typography>
                                <Divider/>
                                <Stack spacing={2} sx={{mt: 2}}>
                                    <AutocompleteField
                                        name={'customer'}
                                        label={'Müşteri'}
                                        values={customers}
                                        itemLabelField={'name'}
                                        size={'small'}
                                    />
                                    <TextField
                                        name={'customerOrderId'}
                                        label={'Müşteri Sipariş No'}
                                        size={'small'}
                                    />
                                    <DatePicker
                                        label={"Müşteri Sipariş Tarihi"}
                                        name={'customerOrderDate'}
                                        size={'small'}
                                    />
                                </Stack>
                                <Typography
                                    variant={"subtitle2"}
                                    sx={{mt: 2}}
                                >Sipariş bilgileri</Typography>
                                <Divider/>
                                <Stack spacing={2} sx={{mt: 2}}>
                                    <TextField
                                        name={'companyOrderId'}
                                        label={'Kestaş Sipariş No'}
                                        size={'small'}
                                    />
                                    <Stack direction={"row"} spacing={1} alignItems={'center'}>
                                        <DatePicker
                                            label={"Teklif Tarihi"}
                                            name={'offerDate'}
                                            size={'small'}
                                        />
                                        <DatePicker
                                            label={"Teklif Onay Tarihi"}
                                            name={'confirmationDate'}
                                            size={'small'}
                                        />
                                    </Stack>
                                    <TextField
                                        name={'deadline'}
                                        label={'Termin Süresi (Hafta)'}
                                        inputMode={'numeric'}
                                        size={'small'}
                                    />
                                    <TextField
                                        name={'paymentTerms'}
                                        label={'Ödeme Şartları'}
                                        multiline
                                        size={'small'}
                                    />
                                </Stack>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                onClick={() => formik.submitForm()}
                            >Kaydet</Button>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                color={"error"}
                                onClick={handleClose}
                            >İptal</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
    )
}

const validationSchema = yup.object({
    customer: yup
        .string()
        .required('Lütfen bir müşteri seçin'),
    companyOrderId: yup
        .string()
        .matches(/(^[0-9]{3}\/[0-9]{2}$)/, {excludeEmptyString: true, message: 'Lütfen formata uyunuz'}),
    offerDate: yup
        .date(),
    // .required('Teklif tarihini seçin'),
    confirmationDate: yup
        .date(),
    // .required('Teklif tarihini seçin'),
    customerOrderId: yup.string(),
    customerOrderDate: yup
        .date(),
    deadline: yup
        .number()
        //.required('Hafta sayısı giriniz')
        .moreThan(0, 'Hafta sıfırdan büyük olmalı'),
    paymentTerms: yup
        .string(),
});

export default OrderFormDialog;
