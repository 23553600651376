import Box from "@mui/material/Box";
import React, {useContext, useEffect, useState} from "react";
import OilSaleApi from "../../../api/oil-sale";
import {AuthContext} from "../../../contexts/AuthContext";
import Stack from "@mui/material/Stack";
import ClusteredColumnChartHOC from "./ClusteredColumnChartHOC";
import ColumnChartHOC from "./ColumnChartHOC";
import MonthlyOilSale from "./MonthlyOilSale";
import TotalOilSaleTable from "./TotalOilSaleTable";

const OilSale = function () {
    const authorization = useContext(AuthContext)
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [rawData, setRawData] = useState([]);

    useEffect(() => {
        setLoading(true);
        OilSaleApi
            .getAll({authorization})
            .then((oilSales) => {
                setRawData(oilSales)
                const data = oilSales
                    .reduce((result, oilSale) => {
                        const row = result.find(row => row.year === oilSale.countingYear)
                        if (row) {
                            row[oilSale.countingMonth] = oilSale.amount
                        } else {
                            result.push({
                                id: oilSale.countingYear,
                                year: oilSale.countingYear,
                                [oilSale.countingMonth]: oilSale.amount,
                            })
                        }
                        return result;
                    }, [])
                    .sort((a, b) => a.year - b.year)
                setData(data)
                setLoading(false)
            })
    }, [authorization])

    return (
        <Box sx={{mt: 3, flex: '1 1 auto'}}>
            <Stack direction={"column"} spacing={3}>
                <MonthlyOilSale loading={loading} data={data}/>
                <ClusteredColumnChartHOC rawData={rawData}/>
                <Stack direction="row" spacing={3}>
                    <ColumnChartHOC rawData={rawData}/>
                    <TotalOilSaleTable loading={loading} rawData={rawData}/>
                </Stack>
            </Stack>
        </Box>
    )
}

export default OilSale;
