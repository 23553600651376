import React from 'react';
import ProcessDataTable from "./ProcessDataTable";
import ListActionButtons from "./ListActionButtons";

const ProcessTab = function ({operations}) {
    const processFields = operations
        .map(operation => ({
            name: operation.id,
            label: operation.name,
        }))
    return (
        <React.Fragment>
            <ListActionButtons processFields={processFields}/>
            <ProcessDataTable processFields={processFields}/>
        </React.Fragment>
    )
}

export default ProcessTab;
