import MuiTextField from "@mui/material/TextField";
import {useField} from "formik";

const TextField = function (
    {
        label,
        fullWidth = true,
        inputMode,
        inputProps,
        multiline = false,
        InputProps,
        size = 'medium',
        onChange,
        disabled,
        ...props
    }
) {
    const [field, meta] = useField(props);
    return (
        <MuiTextField
            multiline={multiline}
            fullWidth={fullWidth}
            inputMode={inputMode}
            inputProps={inputProps}
            InputProps={InputProps}
            name={field.name}
            label={label}
            value={field.value}
            onChange={(...args) => {
                if (onChange) onChange(...args);
                field.onChange(...args);
            }}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.error}
            size={size}
            disabled={disabled}
        />
    )
};

export default TextField;
