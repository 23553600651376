import {useContext} from "react";
import Stack from "@mui/material/Stack";
import {MessageContext} from "./context";
import Message from "./Message";
import Paper from "@mui/material/Paper";

const Messages = function () {
    const {messages} = useContext(MessageContext);
    return (
        <Paper variant={"outlined"} elevation={0}>
            <Stack spacing={1} sx={{height: 300, overflowY: 'auto', p: 1}}>
                {messages.map((message, index) => <Message key={index} {...message}/>)}
            </Stack>
        </Paper>
    )
}

export default Messages;
