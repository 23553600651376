import {useContext, useState} from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {statusTranslate} from "../../helpers/work-order/status";
import {Formik} from "formik";
import * as yup from "yup";
import Stack from "@mui/material/Stack";
import {AuthContext} from "../../contexts/AuthContext";
import {useSnackbar} from "notistack";
import PriceFieldFormat from "../PriceFieldFormat";
import TextField from "../forms/TextField";
import SelectField from "../forms/SelectField";
import SharpeningPriceCalculation from "../SharpeningPriceCalculation";
import Divider from "@mui/material/Divider";
import UnitPriceCalculation from "../UnitPriceCalculation";
import {hasPermission} from "../../utils/permissions";
import CheckboxField from "../forms/CheckboxField";
import FormHelperText from "@mui/material/FormHelperText";

const ProductionCompleteDialog = function ({api, workOrder, setWorkOrder}) {
    const {enqueueSnackbar} = useSnackbar();
    const authorization = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const initialValues = {
        unitPrice: workOrder.unitPrice || '',
        currency: workOrder.currency || 'TL',
        cuttingEdgeCount: '',
        averageDiameter: '',
        notes: '',
        dispatchType: '',
        tagCount: '',
        invoiceQuantity: workOrder.quantity - workOrder.invoicedQuantity,
        shouldUpdateQuantity: false,
    }

    const handleClose = () => !loading && setOpen(false);

    const statusUpdate = (values) => {
        setLoading(true);
        api.updateStatus({authorization}, workOrder.id, {...values, process: 'complete'}).then(workOrder => {
            setWorkOrder(workOrder);
            setLoading(false);
            setOpen(false);
        }).catch(err => {
            if (err?.response?.status === 400) {
                enqueueSnackbar('Hatalı işlem', {
                    variant: "error"
                });
            }
            setLoading(false);
        });
    }

    return (
        <>
            <Button
                variant={'contained'}
                sx={{textTransform: 'none', displayPrint: 'none', ml: 1}}
                onClick={() => setOpen(true)}
            >{statusTranslate.ACTIVE.statusUpdateButtonLabel}</Button>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    // actions.setSubmitting(false);
                    console.log(values);
                    if (
                        !values.shouldUpdateQuantity
                        && Number(values.invoiceQuantity) > workOrder.quantity - workOrder.invoicedQuantity
                    ) {
                        actions.setErrors({
                            shouldUpdateQuantity: 'Faturalanacak miktar iş emri "Adet veya Kg" değerinden büyük olamaz. Değeri güncellemek için lütfen işaretleyiniz.'
                        })
                        return;
                    }
                    const payload = {
                        ...values
                    };
                    delete payload.cuttingEdgeCount;
                    delete payload.averageDiameter;

                    if (values.cuttingEdgeCount && values.averageDiameter) {
                        if (workOrder.product.code === 12) {
                            payload.description = `KDS:${values.cuttingEdgeCount} Ø:${values.averageDiameter}`;
                        } else if (workOrder.product.code === 22) {
                            payload.description = `KDS:${values.cuttingEdgeCount} b:${values.averageDiameter}`;
                        }
                    }
                    statusUpdate(payload);
                }}

            >
                {formik => (
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
                        <DialogTitle>{statusTranslate.ACTIVE.statusUpdateButtonLabel}</DialogTitle>
                        <DialogContent>
                            <Stack sx={{pt: 1}} spacing={1}>
                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    divider={<Divider orientation="vertical" flexItem/>}
                                >
                                    <SharpeningPriceCalculation updateFormikState/>
                                    <UnitPriceCalculation/>
                                </Stack>
                                <Divider/>
                                <TextField
                                    name={"invoiceQuantity"}
                                    label={"Fatura edilecek miktar"}
                                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                />
                                {workOrder.invoicedQuantity > 0 && (
                                    <FormHelperText>Daha önce {workOrder.invoicedQuantity} adet
                                        faturalandı</FormHelperText>
                                )}
                                {Number(formik.values.invoiceQuantity) < workOrder.quantity - workOrder.invoicedQuantity && (
                                    <FormHelperText>Fatura edilecek miktar belirtilen kalan "Adet veya Kg"
                                        değerinden az olduğu için kısmi faturalandırma yapılacaktır. Ya da aşağıdaki
                                        kutucuğu işaretleyerek iş emri için değeri
                                        güncelleyebilirsiniz.</FormHelperText>
                                )}
                                {Number(formik.values.invoiceQuantity) > workOrder.quantity - workOrder.invoicedQuantity && (
                                    <FormHelperText error>
                                        Toplam faturalanacak miktar belirtilen "Adet veya Kg"
                                        değerinden fazla olamaz. Lütfen değeri kontrol edin
                                        ya da aşağıdaki kutucuğu işaretleyerek iş emri için
                                        değeri güncelleyebilirsiniz.
                                    </FormHelperText>
                                )}
                                <CheckboxField
                                    name={'shouldUpdateQuantity'}
                                    label={'İş emri için "Adet veya Kg" değerini güncelle'}
                                    size={'small'}
                                    disabled={Number(formik.values.invoiceQuantity) === workOrder.quantity - workOrder.invoicedQuantity}
                                />

                                <Stack direction={"row"} spacing={1}>
                                    <TextField
                                        fullWidth
                                        name={"unitPrice"}
                                        label={"Birim Fiyat"}
                                        inputMode={"decimal"}
                                        InputProps={{
                                            inputComponent: PriceFieldFormat
                                        }}
                                    />
                                    <SelectField
                                        name={'currency'}
                                        label={'Para Birimi'}
                                        values={currencies.map(currency => ({id: currency}))}
                                        nullable={false}
                                    />
                                </Stack>
                                {(workOrder.product.code === 12 || workOrder.product.code === 22) && (
                                    <Stack direction={"row"} spacing={1}>
                                        <TextField
                                            fullWidth
                                            name={"cuttingEdgeCount"}
                                            label={"KDS"}
                                            inputMode={"numeric"}
                                        />
                                        <TextField
                                            fullWidth
                                            name={"averageDiameter"}
                                            label={workOrder.product.code === 12 ? 'Ø' : 'b'}
                                            inputMode={"numeric"}
                                        />
                                    </Stack>
                                )}
                                <TextField
                                    fullWidth
                                    name={"notes"}
                                    label={"Notlar"}
                                    inputMode={"text"}
                                    multiline
                                />
                                <Stack direction={"row"} spacing={1}>
                                    <TextField
                                        name={"dispatchType"}
                                        label={"Sevk Şekli"}
                                        inputMode={"text"}
                                    />
                                    <TextField
                                        name={"tagCount"}
                                        label={"Etiket Sayısı"}
                                        inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                    />
                                </Stack>
                            </Stack>

                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                onClick={() => formik.submitForm()}
                            >Tamamla</Button>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                color={"error"}
                                onClick={handleClose}
                            >İptal</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        </>
    );
}

const currencies = ['TL', 'EUR', 'GBP', 'USD'];

const validationSchema = yup.object({
    invoiceQuantity: yup.number()
        .typeError('Yalnızca sayı olarak giriniz')
        .positive('Sayı negatif olamaz')
        .integer('Lütfen tamsayı giriniz'),
    shouldUpdateQuantity: yup.boolean(),
    unitPrice: yup
        .number()
        .min(0, 'Birim fiyat negatif olamaz')
        .required('Lütfen birim fiyat giriniz'),
    currency: yup
        .string()
        .oneOf(currencies),
    notes: yup.string(),
    dispatchType: yup.string().required('Zorunlu alan'),
    tagCount: yup.number()
        .typeError('Yalnızca sayı olarak giriniz')
        .min(0, 'Sayı negatif olamaz')
        .integer('Lütfen tamsayı giriniz')
        .required('Zorunlu alan'),
});

export default hasPermission(ProductionCompleteDialog, {permissions: ['can_complete_work_order']});
