import {useCallback, useContext} from "react";
import BaseDataTable from "../datatable";
import {ListPageContext} from "../list-page-hoc/context";
import {listPageActionTypes} from "../list-page-hoc/constants";
import BroochSharpeningPricesApi from '../../api/brooch-sharpening-prices';
import PriceTextFormat from "../PriceTextFormat";
import {useListApi} from "../list-page-hoc/hooks";

function SharpeningDataTable() {
    const {state, dispatch} = useContext(ListPageContext);
    const {data: operationTimeTrackingList} = useListApi(BroochSharpeningPricesApi);

    const handleSortModelChange = sortModel => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({type: listPageActionTypes.SET_SORT_MODEL, sortModel})
    };

    const handleFilterModelChange = useCallback(filterModel => {
        console.log(filterModel);
    }, []);

    const onPageChange = (page) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                page: page,
            }
        })
    }

    const onPageSizeChange = (pageSize) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                pageSize,
            }
        })
    }

    return (
        <BaseDataTable
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            sortModel={state.sortModel}
            loading={state.loading}
            rowCount={operationTimeTrackingList.count}
            page={state.pagination.page}
            pageSize={state.pagination.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={operationTimeTrackingList.results}
            columns={[
                {
                    field: 'customer',
                    filterable: false,
                    headerName: 'Müşteri',
                    sortable: true,
                },
                {
                    field: 'broochType',
                    filterable: false,
                    headerName: 'Broş Tipi',
                    sortable: false,
                },
                {
                    field: 'cuttingEdgeCount',
                    filterable: false,
                    headerName: 'KDS',
                    sortable: false,
                },
                {
                    field: 'averageDiameter',
                    filterable: false,
                    headerName: 'Ø / b',
                    sortable: false,
                    valueFormatter: ({value}) => {
                        return value && parseFloat(value) - parseInt(value) === 0
                            ? parseInt(value)
                            : value
                    },
                },
                {
                    field: 'price',
                    filterable: false,
                    headerName: 'Fiyat',
                    sortable: false,
                    renderCell: (params) => <PriceTextFormat
                        prefix={params.row.currency}
                        value={params.value}
                    />
                },
                {
                    field: 'companyProductCode',
                    filterable: false,
                    headerName: 'Kestaş Ürün Kodu',
                    sortable: false,
                },
                {
                    field: 'customerProductCode',
                    filterable: false,
                    headerName: 'Müşteri Ürün Kodu',
                    sortable: false,
                },
                {
                    field: 'descriptionOne',
                    filterable: false,
                    headerName: 'Açıklama-1',
                    sortable: false,
                    flex: 1,
                },
                {
                    field: 'descriptionTwo',
                    filterable: false,
                    headerName: 'Açıklama-2',
                    sortable: false,
                    flex: 1,
                },
                // {
                //     field: 'actions',
                //     type: 'actions',
                //     pinnable: true,
                //     getActions: (params) => [
                //         <TimeTrackingDataUpdateDialog timeTrackingData={params.row}/>
                //     ]
                // }
            ]}
        />
    )
}

export default SharpeningDataTable;
