import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Formik} from "formik";
import * as yup from "yup";
import {useContext, useState} from "react";
import TextField from "../forms/TextField";
import {AuthContext} from "../../contexts/AuthContext";

const CustomerFormDialog = function (
    {
        initialValues = {
            name: '',
            phone: '',
            address: '',
            financialNotes: '',
        },
        onSubmit = Promise.resolve(true),
        title,
        label,
        sx = {}
    }
) {
    const {hasPermission} = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const canEditFinancialNotes = hasPermission({permissions: ['can_edit_financial_notes']})
    const handleClose = () => !loading && setOpen(false);

    return (
        <>
            <Button
                variant={'outlined'}
                sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
                onClick={() => setOpen(true)}
            >{label}</Button>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    // actions.setSubmitting(false);
                    setLoading(true);
                    if (!canEditFinancialNotes) {
                        delete values.financialNotes
                    }
                    onSubmit(values).finally(() => {
                        setLoading(false);
                        setOpen(false);
                    });
                }}
            >
                {formik => (
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContent>
                            <Stack sx={{py: 1}} spacing={1}>
                                <TextField name={'name'} label={'Firma Adı'}/>
                                <TextField name={'phone'} label={'Telefon'}/>
                                <TextField name={'address'} label={'Adres'}/>
                                {canEditFinancialNotes &&
                                    <TextField name={'financialNotes'} label={'Finansal Notlar'}/>}
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                onClick={() => formik.submitForm()}
                            >Kaydet</Button>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                color={"error"}
                                onClick={handleClose}
                            >İptal</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        </>
    )
}

const validationSchema = yup.object({
    name: yup.string().required(),
    phone: yup.number(),
    address: yup.string(),
    financialNotes: yup.string(),
});

export default CustomerFormDialog;
