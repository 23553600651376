import {createContext, useContext, useEffect, useReducer} from 'react';
import {detailPageReducer} from "./reducer";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {detailPageActionTypes} from "./constants";
import {AuthContext} from "../../contexts/AuthContext";
import {useNavigate} from "react-router-dom";

const DetailPageContext = createContext(null);

function DetailPageProvider({children, api, id}) {
    const authorization = useContext(AuthContext);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(detailPageReducer, {
        data: null,
        version: 1,
        loading: true,
        id
    }, arg => arg);

    useEffect(() => {
        let aborted = false;
        api.get({authorization}, id)
            .then(data => {
                !aborted && dispatch({type: detailPageActionTypes.UPDATE_DATA, data})
            })
            .catch(err => {
                if (!aborted) {
                    if (err?.response?.status === 403) {
                        navigate('/oturumu-guncelle', {replace: false})
                    }
                }
            });
        return () => {
            aborted = true;
        }
    }, [authorization, id, dispatch, api, state.version, navigate]);

    return (
        <DetailPageContext.Provider value={{state, dispatch}}>
            {
                state.data
                    ? children
                    : (
                        <Box sx={{
                            display: "flex",
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <CircularProgress/>
                        </Box>
                    )
            }
        </DetailPageContext.Provider>
    );
}

export {DetailPageContext};
export default DetailPageProvider;
