import format from "date-fns/format";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {userLabelFormat} from "../../helpers/user/format";
import FormHelperText from "@mui/material/FormHelperText";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Formik} from "formik";
import * as yup from "yup";
import {useContext, useEffect, useState} from "react";
import UserApi from "../../api/user";
import ActionApi from "../../api/action";
import {AuthContext} from "../../contexts/AuthContext";
import trLocale from "date-fns/locale/tr";

const localeMap = {
    tr: trLocale,
};

const maskMap = {
    tr: '__.__.____',
};
const ActionFormDialog = function (
    {
        initialValues = {
            deadline: new Date(),
            description: '',
            // status: 'TODO',
            assignee: '',
            category: '',
        },
        onSubmit = Promise.resolve(true),
        title,
        label,
        sx={}
    }
) {
    const authorization = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        Promise.all([
            UserApi.getAll({authorization}),
            ActionApi.getCategories({authorization})
        ]).then(([users, categories]) => {
            setUsers(users);
            setCategories(categories);
        })
    }, [authorization])

    const handleClose = () => !loading && setOpen(false);

    return (
        <>
            <Button
                variant={'outlined'}
                sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
                onClick={() => setOpen(true)}
            >{label}</Button>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    // actions.setSubmitting(false);
                    setLoading(true);
                    if (typeof (values.deadline) === 'object') {
                        values.deadline = format(values.deadline, 'yyyy-MM-dd');
                    }
                    onSubmit(values).finally(() => {
                        setLoading(false);
                        setOpen(false);
                    });
                }}
            >
                {formik => (
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContent>
                            <Stack sx={{py: 1}} spacing={1}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(formik.errors.assignee) && formik.touched.assignee}
                                >
                                    <InputLabel id="action-assignee-select-label">Sorumlu</InputLabel>
                                    <Select
                                        labelId="action-assignee-select-label"
                                        label="Sorumlu"
                                        onChange={formik.handleChange}
                                        name="assignee"
                                        value={formik.values.assignee}
                                    >
                                        <MenuItem value="" disabled>Seçiniz</MenuItem>
                                        {users.map(user => (
                                            <MenuItem value={user.id}
                                                      key={user.id}>{userLabelFormat({user})}</MenuItem>))}
                                    </Select>
                                    {Boolean(formik.errors.assignee) && formik.touched.assignee && (
                                        <FormHelperText>{formik.errors.assignee}</FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    error={Boolean(formik.errors.category) && formik.touched.category}
                                >
                                    <InputLabel id="action-category-select-label">Kategori</InputLabel>
                                    <Select
                                        labelId="action-category-select-label"
                                        label="Kategori"
                                        onChange={formik.handleChange}
                                        name="category"
                                        value={formik.values.category}
                                    >
                                        <MenuItem value="" disabled>Seçiniz</MenuItem>
                                        {categories.map(category => (
                                            <MenuItem value={category.id}
                                                      key={category.id}>{category.name}</MenuItem>))}
                                    </Select>
                                    {Boolean(formik.errors.category) && formik.touched.category && (
                                        <FormHelperText>{formik.errors.category}</FormHelperText>
                                    )}
                                </FormControl>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={localeMap.tr}
                                >
                                    <DatePicker
                                        mask={maskMap.tr}
                                        value={formik.values.deadline}
                                        onChange={(value) => {
                                            formik.setFieldValue('deadline', value);
                                        }}
                                        label="Termin Tarihi"
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    sx={{my: 1}}
                                                    error={formik.touched.deadline && Boolean(formik.errors.deadline)}
                                                    helperText={formik.touched.deadline && formik.errors.deadline}
                                                />
                                            )
                                        }}
                                    />
                                </LocalizationProvider>
                                <TextField
                                    fullWidth
                                    multiline
                                    id="description"
                                    name="description"
                                    label="Açıklama"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                    helperText={formik.errors.description}
                                />
                            </Stack>

                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                onClick={() => formik.submitForm()}
                            >Kaydet</Button>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                color={"error"}
                                onClick={handleClose}
                            >İptal</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        </>
    )
}

const validationSchema = yup.object({
    assignee: yup.string().required('Lütfen bir sorumlu seçiniz'),
    deadline: yup.date(),
    description: yup.string(),
    // status: yup.string(),
    category: yup.string().required('Lütfen bir kategori seçiniz'),
});

export default ActionFormDialog;
