import Box from "@mui/material/Box";
import BackButton from "../BackButton";
import StatusChip from "./StatusChip";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import StatusChangeButton from "./StatusChangeButton";
import ActionUpdateDialog from "./ActionUpdateDialog";

const DetailActionButtons = function () {
    const {state} = useContext(DetailPageContext);

    return (
        <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
            <BackButton/>
            <StatusChip sx={{ml: 1}} status={state.data.status}/>
            <Box sx={{ml: 'auto'}}/>
            <ActionUpdateDialog sx={{ml: 1}}/>
            <StatusChangeButton sx={{ml: 1}}/>
        </Box>
    )
}

export default DetailActionButtons;
