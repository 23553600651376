import {createContext, useReducer, useEffect, useContext} from 'react';
import {revenueReducer} from "./reducer";
import ExchangeRateApi from "../../../../api/exchange-rate";
import RevenueApi from "../../../../api/revenue";
import {AuthContext} from "../../../../contexts/AuthContext";

const RevenueContext = createContext(null);
const storageKey = 'financial-analyze-revenue'

function RevenueProvider(
    {
        children,
    }
) {
    const authorization = useContext(AuthContext);
    const [state, dispatch] = useReducer(revenueReducer, {
        version: 1,
        loading: true,
        lastUpdate: new Date(),
        currency: 'TL',
        category: 'FB',
        data: [],
        exchangeRates: [],
    }, params => {
        const storedState = JSON.parse(localStorage.getItem(storageKey) || '{}');
        return {...params, ...storedState, loading: true};
    })

    useEffect(() => {
        ExchangeRateApi
            .getAll({authorization})
            .then((exchangeRates) => {
                dispatch({type: 'SET_EXCHANGE_RATES', exchangeRates})
            })
    }, [authorization])

    useEffect(() => {
        dispatch({type: 'SET_LOADING', loading: true})
        const filters = {category: state.category === 'ALL' ? '' : state.category}
        RevenueApi
            .getAll({authorization}, [], filters)
            .then((revenues) => {
                dispatch({type: 'SET_DATA', data: revenues})
                dispatch({type: 'SET_LOADING', loading: false})
            })
    }, [authorization, state.category])

    useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify({
            data: state.data,
            exchangeRates: state.exchangeRates,
            lastUpdate: state.lastUpdate,
            loading: state.loading,
            category: state.category,
            currency: state.currency,
        }));
    }, [state])

    return <RevenueContext.Provider value={{state, dispatch}}>{children}</RevenueContext.Provider>;
}

export {RevenueContext};
export default RevenueProvider;
