import {DataGridPro, trTR} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import {useContext, useMemo} from "react";
import {RevenueContext} from "./revenue-hoc/context";
import PriceTextFormat from "../../PriceTextFormat";

const TotalRevenueTable = function () {
    const {state} = useContext(RevenueContext);

    const rawData = useMemo(() => {
        if (state.currency === 'TL') return state.data;

        return state.data.reduce((result, row) => {
            const exchangeRate = state.exchangeRates.find(
                ({
                     countingYear,
                     countingMonth,
                     currency
                 }) => {
                    return countingYear === row.countingYear &&
                        String(countingMonth) === String(row.countingMonth) &&
                        currency === state.currency
                })
            if (!exchangeRate) {
                return result
            }
            result.push({
                ...row,
                revenue: Number((row.revenue / parseFloat(exchangeRate.rate)).toFixed(2))
            })
            return result;
        }, [])

    }, [state.currency, state.data, state.exchangeRates])

    const data = useMemo(() => {
        return rawData.reduce((result, revenue) => {
            const row = result.find(row => row.year === String(revenue.countingYear))
            if (row) {
                row.sum += Number(revenue.revenue)
                row.divider = revenue.countingMonth > row.divider ? revenue.countingMonth : row.divider;
                row.mean = parseFloat(row.sum / row.divider)
            } else {
                result.push({
                    id: revenue.countingYear,
                    year: String(revenue.countingYear),
                    sum: Number(revenue.revenue),
                    mean: Number(revenue.revenue),
                    change1: revenue.countingYear - 1,
                    change2: revenue.countingYear - 2,
                    divider: revenue.countingMonth,
                })
            }
            return result;
        }, []).sort((a, b) => Number(a.year) - Number(b.year))
    }, [rawData])

    return (
        <Box sx={{width: '100%'}}>
            <DataGridPro
                autoHeight
                loading={state.loading}
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                columns={[
                    {field: 'year', headerName: 'Yıl'},
                    {
                        field: `sum`,
                        flex: 1,
                        headerName: 'Toplam',
                        sortable: false,
                        filterable: false,
                        pinnable: false,
                        hideable: false,
                        renderCell: (params) => {
                            return (
                                <PriceTextFormat
                                    prefix={state.currency}
                                    value={params.value.toFixed(2)}
                                />
                            )
                        }
                    },
                    {
                        field: 'change1',
                        flex: 1,
                        headerName: '1 Yıllık Değişim',
                        sortable: false,
                        filterable: false,
                        pinnable: false,
                        hideable: false,
                        valueFormatter: (params) => {
                            const compareYear = params.api.getRow(params.value);
                            const currentYear = params.api.getRow(params.id);
                            if (!compareYear) return '-'
                            return `${((currentYear.sum - compareYear.sum) * 100 / compareYear.sum).toFixed(1)}%`;
                        }
                    },
                    {
                        field: 'change2',
                        flex: 1,
                        headerName: '2 Yıllık Değişim',
                        sortable: false,
                        filterable: false,
                        pinnable: false,
                        hideable: false,
                        valueFormatter: (params) => {
                            const compareYear = params.api.getRow(params.value);
                            const currentYear = params.api.getRow(params.id);
                            if (!compareYear) return '-'
                            return `${((currentYear.sum - compareYear.sum) * 100 / compareYear.sum).toFixed(1)}%`;
                        }
                    },
                    {
                        field: 'mean',
                        flex: 1,
                        headerName: 'Aylık Ortalama',
                        sortable: false,
                        filterable: false,
                        pinnable: false,
                        hideable: false,
                        renderCell: (params) => {
                            return (
                                <PriceTextFormat
                                    prefix={state.currency}
                                    value={params.value.toFixed(2)}
                                />
                            )
                        },
                    },
                ]}
                initialState={{
                    pinnedColumns: {left: ['year']}
                }}
                rows={data}
            />
        </Box>
    )
}

export default TotalRevenueTable;
