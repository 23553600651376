import Box from "@mui/material/Box";
import BackButton from "../BackButton";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import Typography from "@mui/material/Typography";
import StatusUpdateButton from "./StatusUpdateButton";
import {detailPageActionTypes} from "../detail-page-hoc/constants";
import StatusChip from "./StatusChip";
import {statusTranslate} from "../../helpers/invoice-order/status";

const DetailActionButtons = function () {
    const {state, dispatch} = useContext(DetailPageContext);

    const setInvoiceOrder = (invoiceOrder) => {
        dispatch({type: detailPageActionTypes.UPDATE_DATA, data: invoiceOrder})
    }

    return (
        <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
            <BackButton/>
            <Typography variant="h4" fontWeight="light" marginLeft={1}>
                # {state.data.companyInvoiceNo}
            </Typography>
            <StatusChip status={state.data.status} sx={{marginLeft: 1}}/>
            {state.data.status === statusTranslate.ACTIVE.enum && (
                <StatusUpdateButton invoiceOrder={state.data} setInvoiceOrder={setInvoiceOrder}/>
            )}
        </Box>
    )
}

export default DetailActionButtons;
