import {useState, useEffect, useCallback, useContext, useMemo} from "react";
import BaseDataTable from "../../datatable";
import WorkOrderOperationApi from "../../../api/work-order-operations";
import {transformDateToLocalString, transformMinutesToTimeFormat} from "../../../utils/transform";
import Chip from '@mui/material/Chip';
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../../contexts/AuthContext";
import {statusTranslate} from "../../../helpers/work-order/status";
import addMonths from "date-fns/addMonths";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import {ListPageContext} from "../../list-page-hoc/context";
import {listPageActionTypes} from "../../list-page-hoc/constants";
import CopyToClipboardButton from "../../CopyToClipboardButton";
import ProductApi from "../../../api/product";

function DataTable() {
    const authorization = useContext(AuthContext);
    const {state, dispatch} = useContext(ListPageContext);
    const [workOrders, setWorkOrders] = useState({count: 0, results: []});
    const [products, setProducts] = useState([]);
    let navigate = useNavigate();

    useEffect(() => {
        ProductApi.getAll({authorization}).then((products) => {
            setProducts(products);
        })
    }, [authorization]);

    const extraFields = useMemo(() => {
        const product = products.find(product => product.id === state.filters.product);
        if (!product) return [];
        return product.operations
            .filter(operation => operation.timeTrackingEnabled)
            .reduce((result, operation) => {
                result.push({
                    field: `operation.${operation.shortCode}.estimate`,
                    headerName: `${operation.name} tahmin (${operation.shortCode})`,
                    valueFormatter: (params) => {
                        const {operations: workOrderOperations} = params.api.getRow(params.id)
                        const workOrderOperation = workOrderOperations.find(
                            workOrderOperation => workOrderOperation.category === operation.id
                        )
                        if (!workOrderOperation) return '-'
                        return transformMinutesToTimeFormat(workOrderOperation.estimatedOperationTime);
                    },
                });
                result.push({
                    field: `operation.${operation.shortCode}.actual`,
                    headerName: `${operation.name} gerçek (${operation.shortCode})`,
                    renderCell: (params) => {
                        const {operations: workOrderOperations} = params.api.getRow(params.id)
                        const workOrderOperation = workOrderOperations.find(
                            workOrderOperation => workOrderOperation.category === operation.id
                        )
                        if (!workOrderOperation) return '-'
                        const diff = workOrderOperation.actualOperationTime / workOrderOperation.estimatedOperationTime;
                        const value = transformMinutesToTimeFormat(workOrderOperation.actualOperationTime);
                        if (diff === 0) return value;
                        return <Chip
                            label={value}
                            color={diff < 1 ? 'success' : diff < 2 ? 'warning' : 'error'}
                        />
                    }
                });
                return result
            }, [])
    }, [products, state.filters.product])

    useEffect(() => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true})
        WorkOrderOperationApi
            .getAll({authorization}, state.sortModel, state.filters, state.pagination)
            .then((workOrders) => {
                setWorkOrders(workOrders);
                dispatch({type: listPageActionTypes.SET_LOADING, loading: false});
            })
    }, [authorization, state.sortModel, state.filters, state.pagination, dispatch, state.version]);

    const handleSortModelChange = sortModel => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({type: listPageActionTypes.SET_SORT_MODEL, sortModel})
    };

    const handleFilterModelChange = useCallback(filterModel => {
        console.log(filterModel);
    }, []);

    const onPageChange = (page) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                page: page,
            }
        })
    }

    const onPageSizeChange = (pageSize) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                pageSize,
            }
        })
    }

    return (
        <BaseDataTable
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            sortModel={state.sortModel}
            loading={state.loading}
            rowCount={workOrders.count}
            page={state.pagination.page}
            pageSize={state.pagination.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={workOrders.results}
            columns={[
                {
                    field: 'workOrderId',
                    headerName: 'İş Emri No',
                    sortable: false,
                    filterable: false,
                    renderCell: (params) => {
                        return (
                            <Box>
                                <Link
                                    component={'button'}
                                    variant={'body2'}
                                    onClick={() => {
                                        navigate(`/is-emirleri/${params.id}`, {replace: false})
                                    }}
                                >{params.value}</Link>
                                <CopyToClipboardButton
                                    sx={{
                                        px: 1,
                                        opacity: 0.1,
                                        transition: 'opacity ease-out 300ms',
                                        '&:hover': {
                                            opacity: 1
                                        }
                                    }}
                                    copyText={params.value}
                                    message={'İş emri numarası kopyalandı'}
                                />
                            </Box>
                        )
                    }
                },
                {
                    field: 'companyProductCode',
                    headerName: 'Kestaş Ürün Kodu',
                    sortable: false,
                    filterable: false
                },
                {
                    field: 'deadline',
                    headerName: 'Termin',
                    filterable: false,
                    sortable: false,
                    width: 120,
                    renderCell: (params) => {
                        if (!params.value) return (<></>);
                        const isCompletedOrFailed = (
                            params.row.status === statusTranslate.COMPLETED.enum
                            || params.row.status === statusTranslate.DAMAGED.enum
                            || params.row.status === statusTranslate.CANCELED.enum
                        )
                        const deadline = new Date(params.value);
                        const currentDate = new Date();
                        const oneMonthLaterDate = addMonths(currentDate, 1);
                        return (
                            <Chip
                                label={transformDateToLocalString(params.value)}
                                color={
                                    isCompletedOrFailed
                                        ? 'statusCompleted'
                                        : deadline < currentDate
                                            ? 'error'
                                            : oneMonthLaterDate > deadline
                                                ? 'warning' : 'success'
                                }
                            />
                        );
                    }
                },
                ...extraFields,
            ]}
        />
    )
}

export default DataTable;
