import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {transformDateToLocalString} from "../../utils/transform";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import {userLabelFormat} from "../../helpers/user/format";

const DetailTable = function ({tableSize = 'medium', showFinancialPart = true, showCreator}) {
    const {state} = useContext(DetailPageContext);

    return (
        <TableContainer component={Paper}>
            <Table size={tableSize}>
                <TableBody>
                    {showCreator && (
                        <>
                            <TableRow>
                                <TableCell>Oluşturan Kişi</TableCell>
                                <TableCell>{userLabelFormat({user: state.data.user})}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Oluşturma Tarihi</TableCell>
                                <TableCell>{transformDateToLocalString(state.data.createdAt, false)}</TableCell>
                            </TableRow>
                        </>
                    )}
                    <TableRow>
                        <TableCell>Müşteri Sipariş No</TableCell>
                        <TableCell>{state.data.customerOrderId || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Müşteri Sipariş Tarihi</TableCell>
                        <TableCell>
                            {state.data.customerOrderDate ? transformDateToLocalString(state.data.customerOrderDate, true) : '-'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Teklif Tarihi</TableCell>
                        <TableCell>
                            {state.data.offerDate ? transformDateToLocalString(state.data.offerDate) : '-'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Teklif Onay Tarihi</TableCell>
                        <TableCell>
                            {state.data.confirmationDate ? transformDateToLocalString(state.data.confirmationDate) : '-'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Termin Tarihi</TableCell>
                        <TableCell>
                            {state.data.deadline ? state.data.deadline : '-'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Ödeme Şartları</TableCell>
                        <TableCell>{state.data.paymentTerms || '-'}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DetailTable;
