import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";
import MuiDatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import trLocale from "date-fns/locale/tr";
import {useContext} from "react";
import {ListPageContext} from "./list-page-hoc/context";
import {listPageActionTypes} from "./list-page-hoc/constants";
import Box from "@mui/material/Box";
import format from "date-fns/format";

const localeMap = {
    tr: trLocale,
};

const maskMap = {
    tr: '__.__.____',
};

const ListDateFilter = function ({label, size = 'medium', filterField}) {
    const {state, dispatch} = useContext(ListPageContext);
    return (
        <Box sx={{ml: 1, width: 200}}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={localeMap.tr}
            >
                <MuiDatePicker
                    mask={maskMap.tr}
                    value={new Date(state.filters[filterField])}
                    onChange={(value) => {
                        dispatch({
                            type: listPageActionTypes.SET_FILTERS,
                            filters: {
                                ...state.filters,
                                [filterField]: format(value, 'yyyy-MM-dd'),
                            }
                        })
                    }}
                    label={label}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                fullWidth
                                size={size}
                            />
                        )
                    }}
                />
            </LocalizationProvider>
        </Box>
    )
}

export default ListDateFilter;
