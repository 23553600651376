import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import {useSnackbar} from "notistack";
import CustomerApi from '../../api/customer';
import {detailPageActionTypes} from "../detail-page-hoc/constants";
import {AuthContext} from "../../contexts/AuthContext";
import ProductPoolFormDialog from "./ProductPoolFormDialog";
import {hasPermission} from "../../utils/permissions";

const ProductPoolUpdateDialog = function ({sx, item}) {
    const {enqueueSnackbar} = useSnackbar();
    const {state, dispatch} = useContext(DetailPageContext);
    const authorization = useContext(AuthContext);

    const update = (values) => {
        return CustomerApi.updateProductPoolItem({authorization}, state.data.id, values).then(() => {
            dispatch({type: detailPageActionTypes.UPDATE_VERSION})
        }).catch(err => {
            if (err?.response?.status === 400) {
                enqueueSnackbar('Hatalı işlem', {
                    variant: "error"
                });
            }
        });
    }

    return (
        <ProductPoolFormDialog
            initialValues={item}
            onSubmit={update}
            title={'Ürün Güncelle'}
            label={'Güncelle'}
            sx={sx}
            isUpdate
        />
    );
}

export default hasPermission(ProductPoolUpdateDialog, {permissions: ['change_productpool']});
