import {useCallback, useContext} from "react";
import BaseDataTable from "../datatable";
import OrderApi from "../../api/order";
import {transformDateToLocalString} from "../../utils/transform";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import {ListPageContext} from "../list-page-hoc/context";
import {listPageActionTypes} from "../list-page-hoc/constants";
import CopyToClipboardButton from "../CopyToClipboardButton";
import {useListApi} from "../list-page-hoc/hooks";
import StatusChip from "./StatusChip";
import Typography from "@mui/material/Typography";
import {statusTranslate} from "../../helpers/work-order/status";
import Chip from "@mui/material/Chip";

function DataTable() {
    const {state, dispatch} = useContext(ListPageContext);
    let navigate = useNavigate();
    const {data: orders} = useListApi(OrderApi)

    const handleSortModelChange = sortModel => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({type: listPageActionTypes.SET_SORT_MODEL, sortModel})
    };

    const handleFilterModelChange = useCallback(filterModel => {
        console.log(filterModel);
    }, []);

    const onPageChange = (page) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                page: page,
            }
        })
    }

    const onPageSizeChange = (pageSize) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                pageSize,
            }
        })
    }

    return (
        <BaseDataTable
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            sortModel={state.sortModel}
            loading={state.loading}
            rowCount={orders.count}
            getRowHeight={({model}) => model.workOrders.length > 3 ? 'auto' : null}
            page={state.pagination.page}
            pageSize={state.pagination.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={orders.results}
            columns={[
                {
                    field: 'companyOrderId',
                    headerName: 'Sipariş No',
                    sortable: false,
                    filterable: false,
                    width: 140,
                    renderCell: (params) => {
                        return (
                            <Box>
                                <Link
                                    component={'button'}
                                    variant={'body2'}
                                    onClick={() => {
                                        navigate(`/siparisler/${params.id}`, {replace: false})
                                    }}
                                >{params.value || params.row.customerOrderId}</Link>
                                <CopyToClipboardButton
                                    sx={{
                                        px: 1,
                                        opacity: 0.1,
                                        transition: 'opacity ease-out 300ms',
                                        '&:hover': {
                                            opacity: 1
                                        }
                                    }}
                                    copyText={params.value || params.row.customerOrderId}
                                    message={'Sipariş numarası kopyalandı'}
                                />
                            </Box>
                        )
                    }
                },
                {
                    field: 'items',
                    headerName: 'Ürünler',
                    filterable: false,
                    width: 200,
                    renderCell: ({value: items}) => {
                        return (
                            <Box sx={{
                                textAlign: 'left',
                                display: 'flex',
                                flexDirection: 'column',
                                lineHeight: 1.4
                            }}>{
                                items.map((item, index) =>
                                    <Typography
                                        key={index}
                                        variant="caption"
                                        lineHeight="inherit"
                                    >{item}</Typography>
                                )
                            }
                            </Box>
                        );
                    }
                },
                {
                    field: 'customer',
                    filterable: false,
                    headerName: 'Müşteri',
                    width: 300,
                    flex: 1,
                },
                {
                    field: 'totalQuantity',
                    headerName: 'Toplam Adet',
                    filterable: false,
                    width: 120,
                },
                {
                    headerName: 'Kalan Adet',
                    filterable: false,
                    width: 120,
                    renderCell: (params) => {
                        const row = params.api.getRow(params.id)
                        const completed = row.workOrders.find(workOrder => workOrder.status === statusTranslate.COMPLETED.enum)
                        const remainingQuantity = row.totalQuantity - (completed?.count || 0)
                        const remainingQuantityPercent = (remainingQuantity / row.totalQuantity) * 100
                        if (Number.isNaN(remainingQuantityPercent)) return ''
                        if (remainingQuantityPercent > 10) {
                            return remainingQuantity
                        }
                        return <Chip color="warning" label={remainingQuantity}/>
                    }
                },
                {
                    field: 'workOrders',
                    headerName: 'Ürün Durumları',
                    filterable: false,
                    width: 120,
                    renderCell: ({value: workOrders}) => {
                        return (
                            <Box sx={{
                                textAlign: 'left',
                                display: 'flex',
                                flexDirection: 'column',
                                lineHeight: 1.4
                            }}>{
                                workOrders.map(workOrder =>
                                    <Typography
                                        key={workOrder.status}
                                        variant="caption"
                                        lineHeight="inherit"
                                    >{workOrder.count} {statusTranslate[workOrder.status].chipLabel}</Typography>
                                )
                            }
                            </Box>
                        );
                    }
                },
                {
                    field: 'status',
                    headerName: 'Durum',
                    filterable: false,
                    sortable: false,
                    width: 120,
                    renderCell: ({value}) => {
                        return (
                            <StatusChip status={value}/>
                        );
                    }
                },
                {
                    field: 'createdAt',
                    filterable: false,
                    headerName: 'Oluşturma Tarihi',
                    width: 135,
                    type: 'date',
                    valueFormatter: (params) => {
                        return transformDateToLocalString(params.value);
                    }
                },
            ]}
        />
    )
}

export default DataTable;
