import {createContext, useReducer} from 'react';
import {analyzeContainerReducer} from "./reducer";

const AnalyzeContainerContext = createContext(null);

function AnalyzeContainerProvider({children}) {
    const [state, dispatch] = useReducer(analyzeContainerReducer, {
        version: 1,
        loading: true,
        analysis: {},
    }, params => {
        return params;
    })

    return <AnalyzeContainerContext.Provider value={{state, dispatch}}>{children}</AnalyzeContainerContext.Provider>;
}

export {AnalyzeContainerContext};
export default AnalyzeContainerProvider;
