import {useState, useEffect, useCallback, useContext} from "react";
import BaseDataTable from "../datatable";
import {transformDateToLocalString} from "../../utils/transform";
import SearchIcon from '@mui/icons-material/Search';
import {useNavigate} from "react-router-dom";
import {ListPageContext} from "../list-page-hoc/context";
import {listPageActionTypes} from "../list-page-hoc/constants";
import ActionApi from '../../api/action';
import {GridActionsCellItem} from "@mui/x-data-grid-pro";
import {userLabelFormat} from "../../helpers/user/format";
import {ActionStatusTypes} from '../../helpers/action/enums'
import StatusChip from "./StatusChip";
import DeadlineChip from "../DeadlineChip";
import {useListApi} from "../list-page-hoc/hooks";

function DataTable() {
    const {state, dispatch} = useContext(ListPageContext);
    let navigate = useNavigate();
    const {data: actions} = useListApi(ActionApi);

    const handleSortModelChange = sortModel => {
        dispatch({type: listPageActionTypes.SET_SORT_MODEL, sortModel})
    };

    const handleFilterModelChange = useCallback(filterModel => {
        console.log(filterModel);
    }, []);

    const onPageChange = (page) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                page: page,
            }
        })
    }

    const onPageSizeChange = (pageSize) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                pageSize,
            }
        })
    }

    return (
        <BaseDataTable
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            sortModel={state.sortModel}
            loading={state.loading}
            rowCount={actions.count}
            page={state.pagination.page}
            pageSize={state.pagination.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={actions.results}
            columns={[
                {
                    field: 'createdBy',
                    filterable: false,
                    headerName: 'Oluşturan',
                    sortable: false,
                    width: 150,
                    valueFormatter: ({value: user}) => {
                        return userLabelFormat({user});
                    },
                },
                {
                    field: 'assignee',
                    filterable: false,
                    headerName: 'Sorumlu',
                    sortable: false,
                    width: 150,
                    valueFormatter: ({value: user}) => {
                        return userLabelFormat({user});
                    },
                },
                {
                    field: 'status',
                    filterable: false,
                    headerName: 'Durum',
                    sortable: false,
                    type: 'singleSelect',
                    width: 120,
                    valueOptions: Object.keys(ActionStatusTypes),
                    renderCell: ({value}) => <StatusChip status={value}/>
                },
                {
                    field: 'createdAt',
                    filterable: false,
                    headerName: 'Oluşturma Tarihi',
                    width: 135,
                    type: 'date',
                    valueFormatter: (params) => {
                        return transformDateToLocalString(params.value);
                    }
                },
                {
                    field: 'deadline',
                    headerName: 'Termin',
                    filterable: false,
                    sortable: false,
                    width: 120,
                    renderCell: (params) => {
                        const isCompletedOrFailed = (
                            params.row.status === ActionStatusTypes.DONE
                            || params.row.status === ActionStatusTypes.CANCELED
                        )
                        return (<DeadlineChip isCompletedOrFailed={isCompletedOrFailed} date={params.value}/>)
                    }
                },
                {
                    field: 'category',
                    filterable: false,
                    headerName: 'Kategori',
                    sortable: false,
                    valueFormatter: (params) => {
                        return params.value.name;
                    }
                },
                {
                    field: 'description',
                    filterable: false,
                    headerName: 'Açıklama',
                    sortable: false,
                    flex: 1,
                },
                {
                    field: 'actions',
                    type: 'actions',
                    pinnable: true,
                    getActions: (params) => [
                        <GridActionsCellItem
                            icon={<SearchIcon/>}
                            onClick={() => {
                                navigate(`/aksiyonlar/${params.id}`, {replace: false})
                            }}
                            label="Detay"
                        />
                    ]
                }
            ]}
        />
    )
}

export default DataTable;
