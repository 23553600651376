import {useLocation, useNavigate} from "react-router-dom";
import {useContext, useEffect, useState, memo} from "react";
import Layout from "../components/layout";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../components/TabPanel";
import Box from "@mui/material/Box";
import ListPageProvider from "../components/list-page-hoc/context";
import ProcessTab from "../components/work-order-processes/ProcessTab";
import ProductApi from "../api/product";
import {AuthContext} from "../contexts/AuthContext";
import CircularProgress from "@mui/material/CircularProgress";


const WorkOrderProcesses = memo(function () {
    const authorization = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [tabs, setTabs] = useState([]);
    const [tab, setTab] = useState(0);
    const changeTab = (event, value) => {
        navigate(`/proses-takip${tabs[value].slug}`, {replace: false})
        setTab(value)
    }
    useEffect(() => {
        ProductApi
            .getAll({authorization}, [], {qualityControlEnabled: true})
            .then(products => {
                setTabs(products.map(product => ({
                    label: product.name,
                    slug: `#proses-${product.code}`,
                    storageKey: `quality-control-${product.code}`,
                    sortModel: [],
                    filters: {product: [product.id], status: []},
                    operations: product.operations.filter(operation => operation.qualityControlEnabled),
                })))
            })
    }, [authorization]);
    useEffect(() => {
        const initialTab = tabs.findIndex(({slug}) => location.hash === slug);
        if (initialTab !== -1) setTab(initialTab);
    }, [location.hash, tabs.length]);
    return (
        <Layout>
            {tabs.length > 0
                ? (
                    <>
                        <Tabs
                            value={tab}
                            onChange={changeTab}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="analyze list tabs"
                        >
                            {tabs.map(({label}) => {
                                return (
                                    <Tab label={label} key={`label-${label}`}/>
                                )
                            })}
                        </Tabs>
                        {
                            tabs.map(({label, storageKey, sortModel, filters, operations}, index) => {
                                return (
                                    <TabPanel value={tab} index={index} key={label}>
                                        <ListPageProvider
                                            sortModel={sortModel}
                                            filters={filters}
                                            storageKey={storageKey}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexFlow: 'column',
                                                    flexGrow: 1,
                                                    height: 'calc(100vh - 128px)',
                                                    width: '100%',
                                                    pb: 3
                                                }}
                                            >
                                                <ProcessTab operations={operations}/>
                                            </Box>
                                        </ListPageProvider>
                                    </TabPanel>
                                )
                            })
                        }
                    </>
                ) : <CircularProgress/>}
        </Layout>
    )
})

export default WorkOrderProcesses;
