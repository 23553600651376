import Api from "./index";
import {transformObjectKeysToCamelCase, transformObjectKeysToSnakeCase} from "../utils/transform";

class InvoiceOrderApi extends Api {
    constructor() {
        super({baseUrl: 'invoice-orders'});
    }

    updateStatus(context, id, data) {
        return this.client.put(
            `${this.baseUrl}/${id}/update_status/`,
            transformObjectKeysToSnakeCase(data),
            {
                headers: {
                    'Authorization': `Bearer ${context.authorization.access}`
                }
            }
        ).then(response => transformObjectKeysToCamelCase(response.data))
    }
}

export default new InvoiceOrderApi();
