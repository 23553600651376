import Layout from "../components/layout";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../components/TabPanel";
import GenericRevenue from "../components/financial-analysis/generic-revenue/GenericRevenue";
import Revenue from "../components/financial-analysis/revenue/Revenue";
import OilSale from "../components/financial-analysis/oil-sale/OilSale";

const analyzeList = [
    {
        label: 'Güncel Satışlar',
        slug: '#guncel-satislar',
        AnalyzeTab: GenericRevenue
    },
    {
        label: 'Ciro Takip',
        slug: '#ciro-takip',
        AnalyzeTab: Revenue
    },
    {
        label: 'Yağ Satışları (KG)',
        slug: '#yag-satis',
        AnalyzeTab: OilSale
    },
]

const FinancialAnalysis = function () {
    const navigate = useNavigate();
    const location = useLocation();
    const [tab, setTab] = useState(0);
    const changeTab = (event, value) => {
        navigate(`/finans-analizleri${analyzeList[value].slug}`, {replace: false})
        setTab(value)
    }
    useEffect(() => {
        const initialTab = analyzeList.findIndex(({slug}) => location.hash === slug);
        if (initialTab !== -1) setTab(initialTab);
    }, [location.hash]);
    return (
        <Layout>
            <Tabs
                value={tab}
                onChange={changeTab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="analyze list tabs"
            >
                {analyzeList.map(({label}) => {
                    return (
                        <Tab label={label} key={`label-${label}`}/>
                    )
                })}
            </Tabs>
            {analyzeList.map(({label, AnalyzeTab}, index) => {
                return (
                    <TabPanel value={tab} index={index} key={label}>
                        <AnalyzeTab/>
                    </TabPanel>
                )
            })}
        </Layout>
    )
}

export default FinancialAnalysis
