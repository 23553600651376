import Layout from "../components/layout";
import Divider from "@mui/material/Divider";
import SharpeningPriceCalculation from "../components/SharpeningPriceCalculation";
import UnitPriceCalculation from "../components/UnitPriceCalculation";
import Stack from "@mui/material/Stack";

const Formulas = function () {
    return (
        <Layout>
            <Stack
                direction={"row"}
                spacing={1}
                divider={<Divider orientation="vertical" flexItem/>}
            >
                <SharpeningPriceCalculation/>
                <UnitPriceCalculation/>
            </Stack>
        </Layout>
    )
}

export default Formulas;
