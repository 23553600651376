import {useField} from "formik";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

const SelectField = function (
    {
        label,
        fullWidth = true,
        nullable = true,
        selected,
        valueField = 'id',
        itemLabelField,
        itemLabelFormat,
        values = [],
        size = 'medium',
        onChange,
        ...props
    }
) {
    const [field, meta] = useField(props);
    return (
        <FormControl
            fullWidth={fullWidth}
            error={Boolean(meta.error) && meta.touched}
            size={size}
        >
            <InputLabel id={`${field.name}-select-label`}>{label}</InputLabel>
            <Select
                labelId={`${field.name}-select-label`}
                label={label}
                onChange={(...args) => {
                    if (onChange) onChange(...args);
                    field.onChange(...args);
                }}
                name={field.name}
                value={field.value}
            >
                {nullable && <MenuItem value="" disabled>Seçiniz</MenuItem>}
                {values.map(value => {
                    const label = itemLabelField
                        ? value[itemLabelField]
                        : itemLabelFormat
                            ? itemLabelFormat(value)
                            : value[valueField]
                    return (
                        <MenuItem
                            value={value[valueField]}
                            key={value[valueField]}
                            selected={value[valueField] === selected}
                        >{label}</MenuItem>
                    )
                })}
            </Select>
            {Boolean(meta.error) && meta.touched && (
                <FormHelperText>{meta.error}</FormHelperText>
            )}
        </FormControl>
    )
}

export default SelectField;
