import {listPageActionTypes} from "./list-page-hoc/constants";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {useContext} from "react";
import {ListPageContext} from "./list-page-hoc/context";

const ListSearch = function () {
    const {state, dispatch} = useContext(ListPageContext);

    return (
        <TextField
            label={"Ara"}
            size={'small'}
            sx={{ml: 1, width: 200}}
            onChange={event => {
                dispatch({
                    type: listPageActionTypes.SET_SEARCH_TEXT,
                    searchText: event.target.value
                })
            }}
            onKeyDown={event => {
                if (event.key === 'Enter') {
                    dispatch({
                        type: listPageActionTypes.SET_FILTERS,
                        filters: {
                            ...state.filters,
                            search: state.searchText
                        }
                    })
                }
            }}
            value={state.searchText}
            InputProps={{
                endAdornment: <InputAdornment position="end"><IconButton
                    onClick={() => {
                        dispatch({
                            type: listPageActionTypes.SET_FILTERS,
                            filters: {
                                ...state.filters,
                                search: state.searchText
                            }
                        })
                    }}
                    edge="end"
                >
                    <SearchIcon/>
                </IconButton></InputAdornment>
            }}
        />
    )
}

export default ListSearch;
