import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Formik} from "formik";
import * as yup from "yup";
import {useState} from "react";
import TextField from "../forms/TextField";
import SelectField from "../forms/SelectField";
import PriceFieldFormat from "../PriceFieldFormat";
import {currencies} from "../../utils/currency";
import {priceTypeTranslate, unitTranslate} from "../../helpers/customer/translate";
import DatePicker from "../forms/DatePicker";
import {PriceType, Unit} from "../../helpers/customer/enums";
import format from "date-fns/format";

const BroachingOilPriceFormDialog = function (
    {
        initialValues = {
            authorizedPerson: '',
            email: '',
            priceType: 'END_USER',
            amount: '',
            unit: 'KG',
            unitPrice: '',
            currency: 'TL',
            confirmationDate: '',
            validityPeriodStart: '',
            validityPeriodEnd: '',
            descriptionOne: '',
            descriptionTwo: '',
        },
        onSubmit = Promise.resolve(true),
        title,
        label,
        sx={}
    }
) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => !loading && setOpen(false);

    return (
        <>
            <Button
                variant={'outlined'}
                sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
                onClick={() => setOpen(true)}
            >{label}</Button>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    // actions.setSubmitting(false);
                    setLoading(true);
                    const payload = Object.entries(values).reduce((payload, [key, value]) => {
                        if (!value) return payload;
                        return {
                            ...payload,
                            [key]: value,
                        }
                    }, {});
                    if (typeof (values.confirmationDate) === 'object') {
                        payload.confirmationDate = format(values.confirmationDate, 'yyyy-MM-dd');
                    }
                    if (typeof (values.validityPeriodStart) === 'object') {
                        payload.validityPeriodStart = format(values.validityPeriodStart, 'yyyy-MM-dd');
                    }
                    if (typeof (values.validityPeriodEnd) === 'object') {
                        payload.validityPeriodEnd = format(values.validityPeriodEnd, 'yyyy-MM-dd');
                    }
                    onSubmit(payload).finally(() => {
                        setLoading(false);
                        setOpen(false);
                    });
                }}
            >
                {formik => (
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContent>
                            <Stack sx={{py: 1}} spacing={1}>
                                <TextField name={'authorizedPerson'} label={'Yetkili İsim'} size={'small'}/>
                                <TextField name={'email'} label={'Email'} size={'small'}/>
                                <SelectField
                                    name={'priceType'}
                                    label={'Fiyat Tipi'}
                                    values={Object.entries(priceTypeTranslate).map(
                                        ([key, value]) => ({key, value})
                                    )}
                                    nullable={false}
                                    valueField={'key'}
                                    itemLabelField={'value'}
                                    size={'small'}
                                />
                                <TextField name={'amount'} label={'Yağ Miktarı'} size={'small'}/>
                                <SelectField
                                    name={'unit'}
                                    label={'Birim'}
                                    values={Object.entries(unitTranslate).map(
                                        ([key, value]) => ({key, value})
                                    )}
                                    nullable={false}
                                    valueField={'key'}
                                    itemLabelField={'value'}
                                    size={'small'}
                                />
                                <TextField
                                    name={'unitPrice'}
                                    label={'Birim Fiyat'}
                                    inputMode={'decimal'}
                                    InputProps={{
                                        inputComponent: PriceFieldFormat
                                    }}
                                    size={'small'}
                                />
                                <SelectField
                                    name={'currency'}
                                    label={'Para Birimi'}
                                    values={currencies}
                                    nullable={false}
                                    valueField={'key'}
                                    size={'small'}
                                />
                                <DatePicker
                                    label={"Fiyat Anlaşma Tarihi"}
                                    name={'confirmationDate'}
                                />
                                <DatePicker
                                    label={"Geçerlilik Başlangıç Tarihi"}
                                    name={'validityPeriodStart'}
                                />
                                <DatePicker
                                    label={"Geçerlilik Bitiş Tarihi"}
                                    name={'validityPeriodEnd'}
                                />
                                <TextField name={'descriptionOne'} multiline label={'Açıklama-1'}/>
                                <TextField name={'descriptionTwo'} multiline label={'Açıklama-2'}/>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                onClick={() => formik.submitForm()}
                            >Kaydet</Button>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                color={"error"}
                                onClick={handleClose}
                            >İptal</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        </>
    )
}

const validationSchema = yup.object({
    authorizedPerson: yup
        .string(),
    email: yup
        .string()
        .email('Geçerli bir email giriniz'),
    priceType: yup
        .string()
        .oneOf(Object.keys(PriceType)),
    amount: yup
        .string()
        .required('Gerekli'),
    unitPrice: yup
        .number()
        .moreThan(0, 'Değer sıfırdan büyük olmalı')
        .required('Gerekli'),
    unit: yup
        .string()
        .oneOf(Object.keys(Unit)),
    currency: yup
        .string()
        .oneOf(currencies.map(currency => currency.value)),
    confirmationDate: yup
        .date(),
    validityPeriodStart: yup
        .date(),
    validityPeriodEnd: yup
        .date(),
    descriptionOne: yup.string(),
    descriptionTwo: yup.string(),
});

export default BroachingOilPriceFormDialog;
