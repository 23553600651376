import InvoiceOrderApi from "../api/invoice-order";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import DetailActionButtons from "../components/invoice-order/DetailActionButtons";
import DetailPageHOC from "../components/detail-page-hoc";
import DetailTable from "../components/invoice-order/DetailTable";
import CustomerTableHOC from "../components/invoice-order/CustomerTableHOC";
import StatusHistoryHOC from "../components/invoice-order/StatusHistoryHOC";
import WorkOrderNotes from "../components/work-order/WorkOrderNotes";

const InvoiceOrderDetail = function () {
    return (
        <DetailPageHOC api={InvoiceOrderApi}>
            <Container maxWidth={"xl"}>
                <DetailActionButtons/>
                <Grid container spacing={2} marginTop={3}>
                    <Grid item md={8}>
                        <DetailTable/>
                    </Grid>
                    <Grid item md={4}>
                        <Stack spacing={2}>
                            <CustomerTableHOC/>
                            <StatusHistoryHOC/>
                            <WorkOrderNotes isInInvoiceOrder/>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </DetailPageHOC>
    )
}

export default InvoiceOrderDetail;
