import {listPageActionTypes} from "./list-page-hoc/constants";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {useContext} from "react";
import {ListPageContext} from "./list-page-hoc/context";

const ListAutocompleteFilter = function ({label, options, isOptionEqualToValue, filterField, inputValueField}) {
    const {state, dispatch} = useContext(ListPageContext);

    return (
        <Autocomplete
            size={"small"}
            sx={{ml: 1, width: 200}}
            onChange={(_, newValue) => {
                dispatch({
                    type: listPageActionTypes.SET_FILTERS,
                    filters: {
                        ...state.filters,
                        [filterField]: newValue?.id,
                    }
                })
            }}
            loading={state.loading}
            loadingText="Yükleniyor"
            clearOnBlur={false}
            clearOnEscape={false}
            inputValue={state.inputValues[inputValueField] || ''}
            onInputChange={(event, newInputValue, reason) => {
                if (event) {
                    dispatch({
                        type: listPageActionTypes.SET_INPUT_VALUE,
                        inputValues: {
                            ...state.inputValues,
                            [inputValueField]: newInputValue,
                        }
                    })
                }
            }}
            isOptionEqualToValue={isOptionEqualToValue}
            options={options}
            renderInput={(params) => <TextField {...params} label={label}/>}
        />
    )
}

export default ListAutocompleteFilter;
