import Layout from "../layout";
import ListPageProvider from "./context";

const ListPageHOC = function ({children, storageKey, sortModel, filters, initialData, dataCallback}) {
    return (
        <Layout>
            <ListPageProvider
                sortModel={sortModel}
                filters={filters}
                storageKey={storageKey}
                initialData={initialData}
                dataCallback={dataCallback}
            >
                {children}
            </ListPageProvider>
        </Layout>
    )
}

export default ListPageHOC;
