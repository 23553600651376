import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import {useSnackbar} from "notistack";
import ActionApi from '../../api/action';
import {detailPageActionTypes} from "../detail-page-hoc/constants";
import ActionFormDialog from "./ActionFormDialog";
import {AuthContext} from "../../contexts/AuthContext";
import {hasPermission} from "../../utils/permissions";

const ActionUpdateDialog = function ({sx}) {
    const {enqueueSnackbar} = useSnackbar();
    const {state, dispatch} = useContext(DetailPageContext);
    const authorization = useContext(AuthContext);

    const update = (values) => {
        return ActionApi.update({authorization}, state.data.id, values).then(() => {
            dispatch({type: detailPageActionTypes.UPDATE_VERSION})
        }).catch(err => {
            if (err?.response?.status === 400) {
                enqueueSnackbar('Hatalı işlem', {
                    variant: "error"
                });
            }
        });
    }

    return (
        <ActionFormDialog
            onSubmit={update}
            title={'Aksiyonu Düzenle'}
            label={'Düzenle'}
            initialValues={{
                ...state.data,
                assignee: state.data.assignee.id,
                category: state.data.category.id
            }}
            sx={sx}
        />
    );
}

export default hasPermission(ActionUpdateDialog, {permissions: ['change_action']});
