import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Formik} from "formik";
import * as yup from "yup";
import {useState} from "react";
import TextField from "../forms/TextField";
import {ContactType} from "../../helpers/customer/enums";
import SelectField from "../forms/SelectField";
import {contactTypeTranslate} from "../../helpers/customer/translate";

const ContactFormDialog = function (
    {
        initialValues = {
            name: '',
            phone: '',
            email: '',
            contactType: ContactType.TECHNICAL
        },
        onSubmit = Promise.resolve(true),
        title,
        label,
        sx={}
    }
) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => !loading && setOpen(false);

    return (
        <>
            <Button
                variant={'outlined'}
                sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
                onClick={() => setOpen(true)}
            >{label}</Button>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    // actions.setSubmitting(false);
                    setLoading(true);
                    onSubmit(values).finally(() => {
                        setLoading(false);
                        setOpen(false);
                    });
                }}
            >
                {formik => (
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContent>
                            <Stack sx={{py: 1}} spacing={1}>
                                <TextField name={'name'} label={'İsim'}/>
                                <TextField name={'email'} label={'Email'}/>
                                <TextField name={'phone'} label={'Telefon'}/>
                                <SelectField
                                    name={'contactType'}
                                    label={'Alan'}
                                    values={[
                                        {id: ContactType.TECHNICAL},
                                        {id: ContactType.FINANCIAL}
                                    ]}
                                    itemLabelFormat={item => contactTypeTranslate[item.id]}
                                    selected={ContactType.TECHNICAL}
                                />
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                onClick={() => formik.submitForm()}
                            >Kaydet</Button>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                color={"error"}
                                onClick={handleClose}
                            >İptal</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        </>
    )
}

const validationSchema = yup.object({
    name: yup
        .string()
        .required('Lütfen isim giriniz'),
    phone: yup.number(),
    email: yup.string().email('Geçerli bir email giriniz'),
    contactType: yup.string().oneOf([ContactType.FINANCIAL, ContactType.TECHNICAL]).required()
});

export default ContactFormDialog;
