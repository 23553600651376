import {useContext, useCallback} from "react";
import BaseDataTable from "../../datatable";
import {transformDateToLocalString} from "../../../utils/transform";
import {ListPageContext} from "../../list-page-hoc/context";
import {listPageActionTypes} from "../../list-page-hoc/constants";
import GenericRevenueApi from '../../../api/generic-revenue';
import {useListApi} from "../../list-page-hoc/hooks";
import PriceTextFormat from "../../PriceTextFormat";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import {useNavigate} from "react-router-dom";

function GenericRevenueTable() {
    let navigate = useNavigate();
    const {state, dispatch} = useContext(ListPageContext);
    const {data: actions} = useListApi(GenericRevenueApi);

    const handleSortModelChange = sortModel => {
        dispatch({type: listPageActionTypes.SET_SORT_MODEL, sortModel})
    };

    const handleFilterModelChange = useCallback(filterModel => {
        console.log(filterModel);
    }, []);

    const onPageChange = (page) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                page: page,
            }
        })
    }

    const onPageSizeChange = (pageSize) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                pageSize,
            }
        })
    }

    return (
        <Box sx={{height: '600px', width: '100%', display: 'flex'}}>
            <BaseDataTable
                onSortModelChange={handleSortModelChange}
                onFilterModelChange={handleFilterModelChange}
                sortModel={state.sortModel}
                loading={state.loading}
                rowCount={actions.count}
                page={state.pagination.page}
                pageSize={state.pagination.pageSize}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                rows={actions.results}
                columns={[
                    {
                        field: 'customer',
                        filterable: false,
                        headerName: 'Müşteri',
                        sortable: false,
                        flex: 1,
                        valueFormatter: ({value}) => {
                            return value.name
                        },
                    },
                    {
                        field: 'product',
                        filterable: false,
                        headerName: 'Ürün/Hizmet',
                        sortable: false,
                        flex: 1,
                    },
                    {
                        field: 'companyInvoiceNo',
                        filterable: false,
                        headerName: 'Fatura No',
                        sortable: false,
                        flex: 1,
                        renderCell: (params) => (<Link
                            component={'button'}
                            variant={'body2'}
                            onClick={() => {
                                navigate(`/fatura-emirleri/${params.id}`, {replace: false})
                            }}
                        >{params.value}</Link>)
                    },
                    {
                        field: 'companyInvoiceDate',
                        filterable: false,
                        headerName: 'Fatura Tarihi',
                        sortable: true,
                        width: 135,
                        type: 'date',
                        valueFormatter: (params) => {
                            return transformDateToLocalString(params.value, true);
                        }
                    },
                    {
                        field: 'total',
                        filterable: false,
                        headerName: 'Tutar (TL)',
                        sortable: true,
                        width: 150,
                        renderCell: (params) => <PriceTextFormat
                            value={params.value}
                        />
                    },
                    {
                        field: 'rate',
                        filterable: false,
                        headerName: 'Kur',
                        sortable: false,
                        width: 120,
                        renderCell: (params) => <PriceTextFormat
                            prefix={`TL-${params.row.currency}`}
                            value={params.value}
                        />
                    },
                ]}
            />
        </Box>
    )
}

export default GenericRevenueTable;
