import {useContext} from "react";
import {useSnackbar} from "notistack";
import OperationTimeTrackingApi from '../../api/operation-time-tracking';
import {ListPageContext} from "../list-page-hoc/context";
import TimeTrackingFormDialog from "./TimeTrackingFormDialog";
import {AuthContext} from "../../contexts/AuthContext";
import {listPageActionTypes} from "../list-page-hoc/constants";
import {transformMinutesToTimeFormat} from "../../utils/transform";
import {hasPermission} from "../../utils/permissions";

const TimeTrackingDataUpdateDialog = function ({sx, timeTrackingData}) {
    const {enqueueSnackbar} = useSnackbar();
    const {dispatch} = useContext(ListPageContext);
    const authorization = useContext(AuthContext);

    const update = (values) => {
        return OperationTimeTrackingApi.update({authorization}, timeTrackingData.id, values).then(() => {
            dispatch({type: listPageActionTypes.UPDATE_VERSION})
            return {status: true};
        }).catch(err => {
            if (err?.response?.status === 500) {
                enqueueSnackbar('Sunucuda bir hata oldu', {
                    variant: "error"
                });
                return {status: false};
            }
            enqueueSnackbar('Hatalı işlem', {
                variant: "error"
            });
            return {status: false, errors: err.response.data};
        });
    }

    return (
        <TimeTrackingFormDialog
            onSubmit={update}
            title={'Zaman Takip Kaydını Düzenle'}
            label={'Düzenle'}
            initialValues={{
                ...timeTrackingData,
                workOrder: timeTrackingData.workOrder.workOrderId,
                operator: timeTrackingData.operator.id,
                category: timeTrackingData.category.id,
                equipment: timeTrackingData.equipment.id,
                totalMinutes: transformMinutesToTimeFormat(timeTrackingData.totalMinutes),
            }}
            sx={sx}
            dataTableAction={true}
        />
    );
}

export default hasPermission(TimeTrackingDataUpdateDialog, {permissions: ['change_operationtimetracking']});
