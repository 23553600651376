import Api from "./index";
import {transformObjectKeysToCamelCase, transformObjectKeysToSnakeCase} from "../utils/transform";

class WorkOrderApi extends Api {
    constructor() {
        super({baseUrl: 'work-orders'});
    }

    updateStatus(context, id, data) {
        return this.client.put(`${this.baseUrl}/${id}/update_status/`, transformObjectKeysToSnakeCase(data), {
            headers: {
                'Authorization': `Bearer ${context.authorization.access}`
            }
        }).then(response => transformObjectKeysToCamelCase(response.data))
    }

    updateProcesses(context, id, data) {
        return this.client.put(`${this.baseUrl}/${id}/update_processes/`, transformObjectKeysToSnakeCase(data), {
            headers: {
                'Authorization': `Bearer ${context.authorization.access}`
            }
        }).then(response => transformObjectKeysToCamelCase(response.data))
    }
}

export default new WorkOrderApi();
