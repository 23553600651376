import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import TableHead from "@mui/material/TableHead";
import DiscountCreateDialog from "./DiscountCreateDialog";
import RemoveDiscountButton from "./RemoveDiscountButton";
import {hasPermission} from "../../utils/permissions";

const DiscountsTable = function () {
    const {state} = useContext(DetailPageContext);
    const customer = state.data;

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>İskonto Bilgileri</TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right">
                            <DiscountCreateDialog/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Ürün/Hizmet Adı</TableCell>
                        <TableCell>Miktar</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customer.discounts.map(({id, product, amount}) => {
                        return (
                            <TableRow key={id}>
                                <TableCell>{product.name}</TableCell>
                                <TableCell>%{amount}</TableCell>
                                <TableCell align="right">
                                    <RemoveDiscountButton discountId={id}/>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default hasPermission(DiscountsTable, {permissions: ['can_view_customer_discount']});
