import {useContext} from "react";
import MessageBox from "../message-box";
import WorkOrderNoteApi from '../../api/work-order-note';
import {AuthContext} from "../../contexts/AuthContext";
import {transformDateToLocalString} from "../../utils/transform";
import {DetailPageContext} from "../detail-page-hoc/context";
import {userLabelFormat} from "../../helpers/user/format";
import {hasPermission} from "../../utils/permissions";

const WorkOrderNotes = function ({sx, isInInvoiceOrder = false}) {
    const authorization = useContext(AuthContext);
    const {state} = useContext(DetailPageContext);
    const workOrder = isInInvoiceOrder ? state.data.workOrder.id : state.data.id;

    const getInitialMessages = () => {
        return WorkOrderNoteApi.getAll({authorization}, [], {workOrder})
    }

    const sendMessage = message => {
        return WorkOrderNoteApi.save({authorization}, {workOrder, note: message})
    }

    return (
        <MessageBox
            sx={sx}
            sendMessage={sendMessage}
            getInitialMessages={getInitialMessages}
            messageFormatter={({user, note, createdAt}) => {
                return {
                    sender: userLabelFormat({user}),
                    message: note,
                    datetime: transformDateToLocalString(createdAt, false)
                }
            }}
            title={'Notlar Bölümü'}
        />
    )
}

export default hasPermission(WorkOrderNotes, {permissions: ['view_workordernote']});
