import {useState, useEffect, useCallback, useContext} from "react";
import BaseDataTable from "../datatable";
import WorkOrderApi from "../../api/work-order";
import {transformDateToLocalString} from "../../utils/transform";
import Chip from '@mui/material/Chip';
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";
import {statusTranslate} from "../../helpers/work-order/status";
import addMonths from "date-fns/addMonths";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import {ListPageContext} from "../list-page-hoc/context";
import {listPageActionTypes} from "../list-page-hoc/constants";
import ProductApi from "../../api/product";
import CustomerApi from "../../api/customer";
import CopyToClipboardButton from "../CopyToClipboardButton";
import {useListApi} from "../list-page-hoc/hooks";

function DataTable() {
    const authorization = useContext(AuthContext);
    const {state, dispatch} = useContext(ListPageContext);
    const [products, setProducts] = useState([]);
    const [customers, setCustomers] = useState([]);
    let navigate = useNavigate();
    const {data: workOrders} = useListApi(WorkOrderApi)

    useEffect(() => {
        Promise.all([
            ProductApi.getAll({authorization}),
            CustomerApi.dropdown({authorization}),
        ]).then(([products, customers]) => {
            setProducts(products);
            setCustomers(customers);
        })
    }, [authorization]);

    const handleSortModelChange = sortModel => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({type: listPageActionTypes.SET_SORT_MODEL, sortModel})
    };

    const handleFilterModelChange = useCallback(filterModel => {
        console.log(filterModel);
    }, []);

    const onPageChange = (page) => {
        console.log({page});
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                page: page,
            }
        })
    }

    const onPageSizeChange = (pageSize) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                pageSize,
            }
        })
    }

    return (
        <BaseDataTable
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            sortModel={state.sortModel}
            loading={state.loading}
            rowCount={workOrders.count}
            page={state.pagination.page}
            pageSize={state.pagination.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={workOrders.results}
            columns={[
                {
                    field: 'workOrderId',
                    headerName: 'İş Emri No',
                    sortable: false,
                    filterable: false,
                    renderCell: (params) => {
                        return (
                            <Box>
                                <Link
                                    component={'button'}
                                    variant={'body2'}
                                    onClick={() => {
                                        navigate(`/is-emirleri/${params.id}`, {replace: false})
                                    }}
                                >{params.value}</Link>
                                <CopyToClipboardButton
                                    sx={{
                                        px: 1,
                                        opacity: 0.1,
                                        transition: 'opacity ease-out 300ms',
                                        '&:hover': {
                                            opacity: 1
                                        }
                                    }}
                                    copyText={params.value}
                                    message={'İş emri numarası kopyalandı'}
                                />
                            </Box>
                        )
                    }
                },
                {
                    field: 'createdAt',
                    filterable: false,
                    headerName: 'Oluşturma Tarihi',
                    width: 135,
                    type: 'date',
                    valueFormatter: (params) => {
                        return transformDateToLocalString(params.value);
                    }
                },
                {
                    field: 'product',
                    filterable: false,
                    headerName: 'Ürün/Hizmet',
                    width: 300,
                    type: 'singleSelect',
                    valueOptions: products.map(product => product.name),
                },
                {
                    field: 'customer',
                    filterable: false,
                    headerName: 'Müşteri',
                    width: 300,
                    type: 'singleSelect',
                    valueOptions: customers.map(customer => customer.name),
                },
                {
                    field: 'companyProductCode',
                    headerName: 'Kestaş Ürün Kodu',
                    sortable: false,
                    filterable: false
                },
                {
                    field: 'companyOrderId',
                    headerName: 'Kestaş Sipariş No',
                    sortable: false,
                    filterable: false
                },
                {
                    field: 'customerProductCode',
                    headerName: 'Müşteri Ürün Kodu',
                    sortable: false,
                    filterable: false
                },
                {
                    field: 'customerOrderId',
                    headerName: 'Müşteri Sipariş No',
                    sortable: false,
                    filterable: false
                },
                {
                    field: 'status',
                    headerName: 'Durum',
                    filterable: false,
                    sortable: false,
                    width: 120,
                    type: 'singleSelect',
                    valueOptions: Object.values(statusTranslate).map(({chipLabel}) => chipLabel),
                    valueFormatter: ({value}) => statusTranslate[value].chipLabel,
                    renderCell: (params) => {
                        const isStatusCompleted = params.value === statusTranslate.COMPLETED.enum;
                        const isStatusDamaged = params.value === statusTranslate.DAMAGED.enum;
                        const isStatusCanceled = params.value === statusTranslate.CANCELED.enum;
                        return (
                            <Chip
                                label={statusTranslate[params.value].chipLabel}
                                color={
                                    isStatusCompleted
                                        ? `statusCompleted`
                                        : isStatusCanceled
                                            ? 'warning'
                                            : isStatusDamaged
                                                ? 'error' : 'primary'
                                }
                            />
                        );
                    }
                },
                {
                    field: 'deadline',
                    headerName: 'Termin',
                    filterable: false,
                    width: 120,
                    renderCell: (params) => {
                        if (!params.value) return (<></>);
                        const isCompletedOrFailed = (
                            params.row.status === statusTranslate.COMPLETED.enum
                            || params.row.status === statusTranslate.DAMAGED.enum
                            || params.row.status === statusTranslate.CANCELED.enum
                        )
                        const deadline = new Date(params.value);
                        const currentDate = new Date();
                        const oneMonthLaterDate = addMonths(currentDate, 1);
                        return (
                            <Chip
                                label={transformDateToLocalString(params.value)}
                                color={
                                    isCompletedOrFailed
                                        ? 'statusCompleted'
                                        : deadline < currentDate
                                            ? 'error'
                                            : oneMonthLaterDate > deadline
                                                ? 'warning' : 'success'
                                }
                            />
                        );
                    }
                },
                {
                    field: 'quantity',
                    headerName: 'Adet veya Kg',
                    filterable: false,
                    sortable: false,
                },
                {
                    field: 'description',
                    headerName: 'Ürün Ebat/Cins Açıklaması',
                    filterable: false,
                    sortable: false,
                    width: 200,
                },
                {
                    field: 'notes',
                    headerName: 'Notlar',
                    filterable: false,
                    sortable: false,
                    width: 200,
                }
            ]}
        />
    )
}

export default DataTable;
