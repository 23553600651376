import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Formik} from "formik";
import * as yup from "yup";
import {useState} from "react";
import TextField from "../forms/TextField";
import SelectField from "../forms/SelectField";
import {ProductStatus} from "../../helpers/customer/enums";
import DatePicker from "../forms/DatePicker";
import {productStatusTranslate} from "../../helpers/customer/translate";
import format from "date-fns/format";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

const ProductPoolFormDialog = function (
    {
        initialValues = {
            companyProductCode: '',
            description: '',
            productType: '',
            customerProductCode: '',
            customerBroachCode: '',
            createdAt: null,
            updatedAt: null,
            version: 0,
            note: '',
            status: ProductStatus.CREATED,
            archivePath: '',
        },
        isUpdate = false,
        onSubmit = Promise.resolve(true),
        title,
        label,
        sx = {},
        useIconButton = false,
    }
) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => !loading && setOpen(false);

    return (
        <>
            {useIconButton
                ? (
                    <IconButton
                        title={label}
                        size={"small"}
                        onClick={() => setOpen(true)}
                    ><EditIcon/>
                    </IconButton>
                )
                : (
                    <Button
                        variant={'outlined'}
                        sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
                        onClick={() => setOpen(true)}
                    >{label}</Button>
                )}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    // actions.setSubmitting(false);
                    setLoading(true);
                    if (values.createdAt && typeof (values.createdAt) === 'object') {
                        values.createdAt = format(values.createdAt, 'yyyy-MM-dd');
                    }
                    if (values.updatedAt && typeof (values.updatedAt) === 'object') {
                        values.updatedAt = format(values.updatedAt, 'yyyy-MM-dd');
                    }
                    onSubmit(values).finally(() => {
                        setLoading(false);
                        setOpen(false);
                    });
                }}
            >
                {formik => (
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContent>
                            <Stack sx={{py: 1}} spacing={1}>
                                <TextField name={'companyProductCode'} label={'Kestaş Ürün Kodu'}/>
                                <TextField name={'description'} label={'Ebatları'}/>
                                <TextField name={'productType'} label={'Cinsi'}/>
                                <TextField name={'customerProductCode'} label={'Müşteri Ürün Kodu'}/>
                                <TextField name={'customerBroachCode'} label={'Müşteri Broş Kodu'}/>
                                <DatePicker name={"createdAt"} label={'İlk Teknik Resim Tarihi'}/>
                                <DatePicker name={"updatedAt"} label={'Güncel Teknik Resim Tarihi'}/>
                                <TextField name={'version'} label={'Revizyon No'} inputMode={'numeric'}/>
                                <TextField name={'note'} label={'Özel Notlar'} multiline/>
                                <SelectField
                                    name={'status'}
                                    label={'Durum'}
                                    values={Object.entries(productStatusTranslate).map(([key, value]) => ({
                                        key,
                                        value
                                    }))}
                                    nullable={false}
                                    valueField={'key'}
                                    itemLabelField={'value'}
                                    size={'small'}
                                />
                                <TextField name={'archivePath'} label={'Arşiv Dosya Konumu'} multiline/>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                onClick={() => formik.submitForm()}
                            >Kaydet</Button>
                            <Button
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                                color={"error"}
                                onClick={handleClose}
                            >İptal</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        </>
    )
}

const validationSchema = yup.object({
    companyProductCode: yup.string(),
    description: yup.string(),
    productType: yup.string(),
    customerProductCode: yup.string().nullable(),
    customerBroachCode: yup.string().nullable(),
    createdAt: yup.date().nullable(),
    updatedAt: yup.date().nullable(),
    version: yup.number().nullable(),
    note: yup.string().nullable(),
    status: yup.string().oneOf(Object.keys(ProductStatus)),
    archivePath: yup.string(),
});

export default ProductPoolFormDialog;
