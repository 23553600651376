import {useContext} from "react";
import Box from "@mui/material/Box";
import StatusFilter from "../work-order/StatusFilter";
import ListSearch from "../ListSearch";
import {listPageActionTypes} from "../list-page-hoc/constants";
import Button from "@mui/material/Button";
import {ListPageContext} from "../list-page-hoc/context";
import ProcessFilter from "./ProcessFilter";

const ListActionButtons = function ({processFields}) {
    const {state, dispatch} = useContext(ListPageContext);
    return (
        <Box sx={{display: 'flex', mt:1}}>
            <Button
                sx={{textTransform: 'none', ml: 'auto'}}
                onClick={() => {
                    dispatch({
                        type: listPageActionTypes.SET_FILTERS,
                        filters: {status: [], product: state.filters.product}
                    });
                    dispatch({type: listPageActionTypes.SET_SEARCH_TEXT, searchText: ''});
                }}
            >
                Temizle
            </Button>
            <ListSearch/>
            <StatusFilter sx={{width: 200, ml: 1}}/>
            <ProcessFilter sx={{width: 200, ml: 1}} processFields={processFields}/>
        </Box>
    )
}

export default ListActionButtons
