import Layout from "../components/layout";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PanToolIcon from '@mui/icons-material/PanTool';
import {red} from "@mui/material/colors";

const Unauthorized = function () {
    return (
        <Layout>
            <Stack
                direction={"column"}
                spacing={1}
            >
                <Typography
                    textAlign={"center"}
                ><PanToolIcon sx={{fontSize: 80, color: red[500]}}/></Typography>
                <Typography
                    variant={"h4"}
                    textAlign={"center"}
                >Bu sayfayı görme yetkiniz yok!</Typography>
                <Typography
                    variant={"subtitle1"}
                    textAlign={"center"}
                >Eğer bir hata olduğunu düşünüyorsanız sistem yöneticisi ile görüşün.</Typography>
            </Stack>
        </Layout>
    )
}

export default Unauthorized;
