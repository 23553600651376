import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import TableHead from "@mui/material/TableHead";
import {contactTypeTranslate} from "../../helpers/customer/translate";
import RemoveContactButton from "./RemoveContactButton";
import ContactCreateDialog from "./ContactCreateDialog";
import {hasPermission} from "../../utils/permissions";

const ContactsTable = function () {
    const {state} = useContext(DetailPageContext);
    const customer = state.data;

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>İletişim Numaraları</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right">
                            <ContactCreateDialog/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>İsim</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Telefon</TableCell>
                        <TableCell>Alan</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customer.contacts.map(({id, name, email, contactType, phone}) => {
                        return (
                            <TableRow key={id}>
                                <TableCell>{name}</TableCell>
                                <TableCell>{email}</TableCell>
                                <TableCell>{phone}</TableCell>
                                <TableCell>{contactTypeTranslate[contactType]}</TableCell>
                                <TableCell align="right">
                                    <RemoveContactButton
                                        customer={customer}
                                        contactId={id}
                                    />
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default hasPermission(ContactsTable, {permissions: ['can_view_customer_contact']});
