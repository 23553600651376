export const listPageReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_VERSION':
            return {
                ...state,
                version: state.version + 1
            }
        case 'SET_FILTERS':
            return {
                ...state,
                filters: action.filters,
                pagination: {
                    ...state.pagination,
                    page: 0,
                }
            }
        case 'SET_SORT_MODEL':
            return {
                ...state,
                sortModel: action.sortModel
            }
        case 'SET_PAGINATION':
            return {
                ...state,
                pagination: action.pagination
            }
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.loading
            }
        case 'SET_SEARCH_TEXT':
            return {
                ...state,
                searchText: action.searchText
            }
        case 'SET_INPUT_VALUE':
            return {
                ...state,
                inputValues: action.inputValues
            }
        case 'SET_DATA':
            console.log(action)
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}
