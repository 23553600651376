import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import MessageInput from "./MessageInput";
import MessageProvider from "./context";
import Messages from "./Messages";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const MessageBox = function (
    {
        sx = {},
        initialMessages = [],
        sendMessage = message => Promise.resolve({
            sender: 'test',
            message,
            datetime: (new Date()).toLocaleDateString()
        }),
        getInitialMessages = () => Promise.resolve([]),
        messageFormatter,
        title = 'Messages'
    }
) {
    return (
        <MessageProvider
            initialMessages={initialMessages}
            sendMessage={sendMessage}
            messageFormatter={messageFormatter}
            getInitialMessages={getInitialMessages}
        >
            <Paper sx={{padding: 2, ...sx}}>
                <Stack spacing={1}>
                    <Typography>{title}</Typography>
                    <Divider/>
                    <Messages/>
                    <MessageInput/>
                </Stack>
            </Paper>
        </MessageProvider>
    )
}

export default MessageBox;
