import {useState, useEffect} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {statusTranslate} from "../../helpers/invoice-order/status";
import MenuItem from "@mui/material/MenuItem";
import {useContext} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import {ListPageContext} from "../list-page-hoc/context";
import {listPageActionTypes} from "../list-page-hoc/constants";
import ProductApi from "../../api/product";
import CustomerApi from "../../api/customer";
import ListSearch from "../ListSearch";
import ListAutocompleteFilter from "../ListAutocompleteFilter";

const ListActionButtons = function () {
    const authorization = useContext(AuthContext);
    const {state, dispatch} = useContext(ListPageContext);
    const [products, setProducts] = useState([]);
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        Promise.all([
            ProductApi.getAll({authorization}),
            CustomerApi.dropdown({authorization}),
        ]).then(([products, customers]) => {
            setProducts(products);
            setCustomers(customers);
        })
    }, [authorization]);

    return (
        <Box sx={{width: '100%', display: 'flex'}}>
            <Box sx={{flexGrow: 1}}/>
            <Box sx={{display: 'flex'}}>
                <Button
                    sx={{textTransform: 'none'}}
                    onClick={() => {
                        dispatch({type: listPageActionTypes.SET_FILTERS, filters: {status: []}});
                        dispatch({type: listPageActionTypes.SET_SEARCH_TEXT, searchText: ''});
                        dispatch({type: listPageActionTypes.SET_INPUT_VALUE, inputValues: {}});
                    }}
                >
                    Temizle
                </Button>
                <ListSearch />
                <ListAutocompleteFilter
                    label="Ürün/Hizmet"
                    filterField="product"
                    inputValueField="productInput"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={products.map(product => ({label: product.name, id: product.id}))}
                />
                <ListAutocompleteFilter
                    label="Müşteri"
                    filterField="customer"
                    inputValueField="customerInput"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={customers.map(customer => ({label: customer.name, id: customer.id}))}
                />
                <FormControl sx={{ml: 1, width: 200}} size="small">
                    <InputLabel id="status-select-label">Durum</InputLabel>
                    <Select
                        multiple
                        labelId="status-select-label"
                        label="Durum"
                        value={state.filters.status}
                        onChange={(event) => {
                            dispatch({
                                type: listPageActionTypes.SET_FILTERS,
                                filters: {
                                    ...state.filters,
                                    status: event.target.value
                                }
                            })
                        }}
                    >
                        {Object.entries(statusTranslate).map(([key, value]) => {
                            return <MenuItem key={key} value={key}>{value.chipLabel}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    )
}

export default ListActionButtons;
