import {monthNames} from "../../../utils/date";
import ClusteredColumnChart from "../ClusteredColumnChart";
import {useContext, useMemo} from "react";
import {RevenueContext} from "./revenue-hoc/context";

const ClusteredColumnChartHOC = function () {
    const {state} = useContext(RevenueContext);

    const rawData = useMemo(() => {
        if (state.currency === 'TL') return state.data;

        return state.data.reduce((result, row) => {
            const exchangeRate = state.exchangeRates.find(
                ({
                     countingYear,
                     countingMonth,
                     currency
                 }) => {
                    return countingYear === row.countingYear &&
                        String(countingMonth) === String(row.countingMonth) &&
                        currency === state.currency
                })
            if (!exchangeRate) {
                return result
            }
            result.push({
                ...row,
                revenue: Number((row.revenue / parseFloat(exchangeRate.rate)).toFixed(2))
            })
            return result;
        }, [])

    }, [state.currency, state.data, state.exchangeRates])

    const {data, seriesMap} = useMemo(() => {
        const series = new Set();
        const data = rawData.reduce((result, revenue) => {
            const row = result.find(row => row.monthId === revenue.countingMonth)
            if (row) {
                row[revenue.countingYear] = (row[revenue.countingYear] || 0) + Number(revenue.revenue)
            } else {
                result.push({
                    month: monthNames[revenue.countingMonth - 1],
                    monthId: revenue.countingMonth,
                    [revenue.countingYear]: Number(revenue.revenue),
                })
            }
            series.add(revenue.countingYear);
            return result;
        }, [])
        const seriesMap = [...series].sort().map(year => ({
            field: year,
            name: String(year),
        }))
        return {data, seriesMap}
    }, [rawData])

    return (
        <ClusteredColumnChart
            id="revenue-clustered-column-chart"
            height={400}
            data={data}
            categoryField="month"
            seriesMap={seriesMap}
        />
    )
}

export default ClusteredColumnChartHOC
