import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import TableHead from "@mui/material/TableHead";
import PriceTextFormat from "../PriceTextFormat";
import BroochSharpeningPriceCreateDialog from "./BroochSharpeningPriceCreateDialog";
import RemoveBroochSharpeningPriceButton from "./RemoveBroochSharpeningPriceButton";
import {hasPermission} from "../../utils/permissions";

const BroochSharpeningPriceTable = function () {
    const {state} = useContext(DetailPageContext);
    const customer = state.data;

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Broş Bileme Fiyatları</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right">
                            <BroochSharpeningPriceCreateDialog/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Broş Tipi</TableCell>
                        <TableCell>KDS</TableCell>
                        <TableCell>Ø / b</TableCell>
                        <TableCell>Fiyat</TableCell>
                        <TableCell>Kestaş Ürün Kodu</TableCell>
                        <TableCell>Müşteri Ürün Kodu</TableCell>
                        <TableCell>Açıklama-1</TableCell>
                        <TableCell>Açıklama-2</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customer.broochSharpeningPrices.map(
                        ({
                             id,
                             broochType,
                             cuttingEdgeCount,
                             averageDiameter,
                             price,
                             currency,
                             companyProductCode,
                             customerProductCode,
                             descriptionOne,
                             descriptionTwo
                         }) => {
                            return (
                                <TableRow key={id}>
                                    <TableCell>{broochType}</TableCell>
                                    <TableCell>{cuttingEdgeCount}</TableCell>
                                    <TableCell>{
                                        averageDiameter && parseFloat(averageDiameter) - parseInt(averageDiameter) === 0
                                            ? parseInt(averageDiameter)
                                            : averageDiameter
                                    }</TableCell>
                                    <TableCell>
                                        <PriceTextFormat
                                            prefix={currency}
                                            value={price}
                                        />
                                    </TableCell>
                                    <TableCell>{companyProductCode}</TableCell>
                                    <TableCell>{customerProductCode}</TableCell>
                                    <TableCell>{descriptionOne}</TableCell>
                                    <TableCell>{descriptionTwo}</TableCell>
                                    <TableCell align="right">
                                        <RemoveBroochSharpeningPriceButton
                                            customer={customer}
                                            contactId={id}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default hasPermission(BroochSharpeningPriceTable, {permissions: ['can_view_customer_brooch_sharpening_price']});
