import {useContext} from "react";
import StatusHistory from "../StatusHistory";
import {actionStatusFormat} from "../../helpers/action/format";
import {DetailPageContext} from "../detail-page-hoc/context";

const StatusHistoryHOC = function () {
    const {state} = useContext(DetailPageContext);
    return (
        <StatusHistory
            statusHistory={state.data.statusHistory}
            statusFormat={({status}) => actionStatusFormat(status)}
        />
    )
}

export default StatusHistoryHOC;
