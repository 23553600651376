import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const Message = function ({sender, datetime, message}) {
    return (
        <Paper sx={{p:1}}>
            <Stack spacing={1}>
                <Typography variant={"subtitle2"}>{sender}</Typography>
                <Typography variant={"body1"}>{message}</Typography>
                <Typography variant={"body2"} textAlign={"end"}>{datetime}</Typography>
            </Stack>
        </Paper>
    )
}

export default Message;
