import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../contexts/AuthContext";
import UserApi from "../api/user";
import sessionManager from "../utils/session";

const sessionKey = 'user';

export const useUser = () => {
    const [user, setUser] = useState(sessionManager.getSessionData(sessionKey) || {});
    const authorization = useContext(AuthContext);
    useEffect(() => {
        UserApi.me({authorization}).then(user => setUser(user));
    }, [authorization]);
    useEffect(() => {
        sessionManager.setSessionData(sessionKey, user);
    }, [user])
    return user;
}
