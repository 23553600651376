import DataTable from "../components/customers/DataTable";
import ListActionButtons from "../components/customers/ListActionButtons";
import ListPageHOC from "../components/list-page-hoc";

const Customers = function () {
    return (
        <ListPageHOC storageKey={'customers-page'} sortModel={[{field: "createdAt", sort: "desc"}]}>
            <ListActionButtons/>
            <DataTable/>
        </ListPageHOC>
    )
}

export default Customers;
