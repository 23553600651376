import {useState} from "react";
import Button from "@mui/material/Button";
import OrderFormDialog from "./OrderFormDialog";
import {hasPermission} from "../../utils/permissions";

const CreateOrderButton = function ({sx = {}, customers}) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button
                variant={'outlined'}
                sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
                onClick={() => setOpen(true)}
            >Sipariş Oluştur</Button>
            <OrderFormDialog
                title="Sipariş oluştur"
                open={open}
                handleClose={() => setOpen(false)}
                customers={customers}
            />
        </>
    )
}

export default hasPermission(CreateOrderButton, {permissions: ['add_order']});
