import {useContext, useLayoutEffect, useCallback} from "react";
import {AnalyzeContext} from "./analyze-hoc/context";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_tr_TR from "@amcharts/amcharts5/locales/tr_TR";
import Box from "@mui/material/Box";
import {isBefore, isAfter} from "date-fns";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import {useTheme} from "@mui/material/styles";


const LineChart = function () {
    const theme = useTheme();
    const {state} = useContext(AnalyzeContext);
    const grouped = useCallback(() => {
        let {firstDate, lastDate, data} = state.data.data.reduce((result, data) => {
            const period = new Date(data.period);
            period.setHours(0, 0, 0, 0);
            result.data[data[state.options.groupKey]] = [
                ...(result.data[data[state.options.groupKey]] || []),
                {
                    period: period.getTime(),
                    [state.options.valueYField]: data[state.options.valueYField],
                },
            ]
            if (!isBefore(period, result.lastDate)) {
                result.lastDate = period
            }
            if (!isAfter(period, result.firstDate)) {
                result.firstDate = period
            }
            return result;
        }, {firstDate: null, lastDate: null, data: {}});

        const result = {};
        while (!isAfter(firstDate, lastDate)) {
            for (const key of Object.keys(data)) {
                const item = data[key].find(({period}) => period === firstDate.getTime())
                result[key] = [
                    ...(result[key] || []),
                    {
                        period: firstDate.getTime(),
                        [state.options.valueYField]: item ? item[state.options.valueYField] : 0
                    }
                ]
            }
            am5.time.add(firstDate, "month", 1);
        }
        return result;
    }, [state])
    console.log(grouped())
    useLayoutEffect(() => {

        let root = am5.Root.new(state.slug);

        if (theme.palette.mode === 'dark') {
            root.setThemes([
                am5themes_Dark.new(root),
            ]);
        } else {
            root.setThemes([
                am5themes_Animated.new(root),
            ]);
        }

        root.locale = am5locales_tr_TR
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            maxTooltipDistance: 0,
            pinchZoomX: true
        }));

        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.2,
            baseInterval: {
                timeUnit: "month",
                count: 1
            },
            renderer: am5xy.AxisRendererX.new(root, {}),
            tooltip: am5.Tooltip.new(root, {})
        }));

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
        }));

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        for (const name of Object.keys(grouped())) {
            let series = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
                name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: state.options.valueYField,
                valueXField: state.options.valueXField,
                legendValueText: "{valueY}",
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "{valueY}"
                }),
            }));
            series.data.setAll(grouped()[name]);
            series.strokes.template.setAll({
                strokeOpacity: 1,
                strokeWidth: 2,
                stroke: series.get("fill")
            });

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear();
        }

        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none"
        }));
        cursor.lineY.set("visible", false);


        // Add scrollbar
        // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
        chart.set("scrollbarX", am5.Scrollbar.new(root, {
            orientation: "horizontal"
        }));
        chart.set("scrollbarY", am5.Scrollbar.new(root, {
            orientation: "vertical"
        }));

        if (state.options.legend) {


            let legend = chart.rightAxesContainer.children.push(am5.Legend.new(root, {
                width: state.options.legandWidth,
                paddingLeft: 15,
                height: am5.percent(100),
                scale: 0.6
            }));

            // When legend item container is hovered, dim all the series except the hovered one
            legend.itemContainers.template.events.on("pointerover", function (e) {
                let itemContainer = e.target;

                // As series list is data of a legend, dataContext is series
                let series = itemContainer.dataItem.dataContext;

                chart.series.each(function (chartSeries) {
                    if (chartSeries !== series) {
                        chartSeries.strokes.template.setAll({
                            strokeOpacity: 0.15,
                            stroke: am5.color(0x000000)
                        });
                    } else {
                        chartSeries.strokes.template.setAll({
                            strokeWidth: 3
                        });
                    }
                })
            })

            // When legend item container is unhovered, make all series as they are
            legend.itemContainers.template.events.on("pointerout", function () {
                chart.series.each(function (chartSeries) {
                    chartSeries.strokes.template.setAll({
                        strokeOpacity: 1,
                        strokeWidth: 2,
                        stroke: chartSeries.get("fill")
                    });
                });
            })

            legend.itemContainers.template.set("width", am5.p100);
            legend.valueLabels.template.setAll({
                width: am5.p100,
                textAlign: "right"
            });

            // It's is important to set legend data after all the events are set on template, otherwise events won't be copied
            legend.data.setAll(chart.series.values);

            let button = chart.rightAxesContainer.children.push(am5.Button.new(root, {
                x: 30,
                y: -35,
                height: 30,
                width: 96,
                layer: 40,
                label: am5.Label.new(root, {
                    text: "Tümünü Gizle",
                    fontSize: 11,
                    paddingTop: 0,
                    paddingLeft: 0,
                })
            }));
            button.events.on("click", function(ev) {
                chart.series.each(series => series.hide())
            });
        }

        return () => {
            root.dispose();
        };
    }, [state, grouped, theme.palette.mode]);

    return (
        <Box id={state.slug} sx={{width: '100%', height: state.options.chartHeight}}/>
    )
}

export default LineChart;
