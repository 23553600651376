export const categoryTranslate = {
    BI: {
        label: 'Broş İmalatı',
        enum: 'BI'
    },
    BB: {
        label: 'Broş Bileme',
        enum: 'BB'
    },
    YS: {
        label: 'Yağ Satış',
        enum: 'YS'
    },
    FB: {
        label: 'Fason Broşlama',
        enum: 'FB'
    },
    PI: {
        label: 'Parça İmalatı',
        enum: 'PI'
    },
    YG: {
        label: 'Yan Gelirler',
        enum: 'YG'
    },
    TI: {
        label: 'Tezgah Imalatı',
        enum: 'TI'
    },
    ALL: {
        label: 'Toplam',
        enum: 'ALL',
    }
}
