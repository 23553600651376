import AnalyzeProvider from "./context";
import AnalyzeLayout from "./layout";
import Analyze from "./Analyze";

const AnalyzeHOC = function ({storageKey, slug, chartType, sx}) {
    return (
        <AnalyzeProvider storageKey={storageKey} slug={slug} chartType={chartType}>
            <AnalyzeLayout sx={sx}>
                <Analyze/>
            </AnalyzeLayout>
        </AnalyzeProvider>
    )
}

export default AnalyzeHOC;
