import {useSnackbar} from "notistack";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const CopyToClipboardButton = function ({title = 'Kopyala', message = 'Kopyalandı', copyText, sx = {}}) {
    const {enqueueSnackbar} = useSnackbar();

    return (
        <CopyToClipboard
            text={copyText}
            onCopy={() => {
                enqueueSnackbar(message, {
                    variant: "info"
                });
            }}
        >
            <IconButton
                sx={sx}
                title={title}
                size={"small"}
            >
                <ContentCopyIcon fontSize="inherit"/>
            </IconButton>
        </CopyToClipboard>
    )
}

export default CopyToClipboardButton;
